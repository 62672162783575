import { useEffect, useState, useRef } from "react";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import MobileNavbar from "../../components/HomeNavbar/MobileNavbar";
import { useAppSelector } from "../../redux/hooks/hooks";
import axios from "axios";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { screenWidthSelector } from "../../redux/slices/screenSlice";
import HomeNavbar from "../../components/HomeNavbar/HomeNavbar";
import SmallHighlightWordComponent from "./SmallHighlightWordComponent";
import CustomLoaderComponent from "../../components/CustomLoaderComponent/CustomLoaderComponent";

const SaveAudio = () => {
  const [allSavedBoost, setAllSavedBoost] = useState<any>([]);
  const token = localStorage.getItem("accessToken");
  const screenWidth = useAppSelector(screenWidthSelector);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [loader, setLoader] = useState(false);

  const getAllsavedBoost = async () => {
    const savedBoost = await axios.get(apiEndPoints.getAllUserSavedBoost, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (savedBoost.data.responseStatus === 1) {
      setAllSavedBoost(savedBoost.data.response);
    } else {
      setAllSavedBoost([]);
    }
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    getAllsavedBoost();
  }, []);

  const handleRemoveSave = async (boostId: string) => {
    if (boostId) {
      const payload = {
        boostId: boostId,
      };
      const res = await axios.post(apiEndPoints.removeBoost, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.responseStatus === 1) {
        setAllSavedBoost(
          allSavedBoost.filter((boost: any) => boost._id !== boostId)
        );
      }
    }
  };

  return (
    <>
      {loader ? (
        <CustomLoaderComponent />
      ) : (
        <div className="flex flex-col text-black bg-[#ECECEC] min-h-dvh">
          <div className="flex flex-col pb-[30px]">
            <div className="fixed h-[93px] bg-[#ECECEC] z-10 w-[100%]">
              {screenWidth < 1025 && <MobileHeader />}
              {screenWidth > 1024 && <HomeNavbar />}
            </div>
            <div
              className={`flex flex-col relative gap-y-3 pb-[15%] 
           px-[2%] mt-2 z-0 pt-[93px]`}
            >
              {allSavedBoost.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-4 gap-y-2.5 lg:mt-4 pb-[50px] z-0">
                  {allSavedBoost.length > 0 &&
                    allSavedBoost.map((boost: any, index: any) => {
                      return (
                        <div key={boost?._id} className="my-4">
                          <SmallHighlightWordComponent
                            key={boost?._id}
                            index={index}
                            boost={boost}
                            setCurrentIndex={setCurrentIndex}
                            currentIndex={currentIndex}
                            handleRemoveSave={handleRemoveSave}
                          />
                        </div>
                      );
                    })}
                </div>
              ) : (
                <p className="text-black text-center mt-7 w-full">
                  Save motivational audios you want to listen to later, and
                  they’ll show up here.{" "}
                </p>
              )}
            </div>
          </div>

          <div className="flex bg-[#ECECEC] fixed w-[100%] bottom-0 justify-center z-25 rounded-t-[24px]">
            <div className=" bg-[#D8D6D0] px-[8px] py-[8px] rounded-[20px] flex  justify-center w-[96%]">
              {screenWidth < 1025 && <MobileNavbar />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SaveAudio;
