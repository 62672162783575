import AuthenticationHeader from "../../components/AuthenticationHeader/AuthenticationHeader";
import InputField from "../../components/InputField/InputField";
import { Formik, Form } from "formik";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { SignupData, SignupValues } from "../../interfaces/interfaces";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { signupSchema } from "../../validationSchema/validationSchema";
import signupSrc from "../../assets/images/signup.png";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import { toast } from "react-toastify";

const Signup = () => {
  const [condition, setCondition] = useState<any>(false);
  const [conditionError, setConditionError] = useState<String>("");
  // const [toggle, toggleSwitch] = useToggle();
  const [toggle, ] = useState<boolean>(false);
  const navigate = useNavigate();

  const classes = {
    active: "bg-web-secondary text-web-primary",
    inactive:
      "hover:bg-web-secondary hover:text-white bg-web-container text-black",
  };

  const initialValues: SignupValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    toggle: false,
  };
  // showErrorToaster(error);
  const handleSignup = async (values: SignupValues) => {
    if (condition) {
      setConditionError("");
      const data: SignupData = {
        email: values.email.toLowerCase(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        password: values.password,
      };
      // signupApiCall(navigate, setError, data, url, `/verifyOtp`, setToggle);

      const response = await axios.post(apiEndPoints.checkEmail, {
        email: values.email,
      });

      if (response.data.responseStatus === 1) {
        navigate("/signupDetails", { state: data });
      } else {
        toast.error(response.data.error);
      }
    } else {
      setConditionError("Please check the term and conditions");
    }
  };

  return (
    <div className="flex items-stretch h-screen overflow-auto">
      <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
        <div className="flex flex-col sm:pr-10 md:pr-20 lg:pr-28 xl:pr-32">
          <div className="flex justify-end mb-10">
            <img
              className="sm:w-44 md:w-52 lg:w-64"
              src={signupSrc}
              alt="hand holding globe"
            />
          </div>
          <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl text-right text-white pr-2">
            Meditation for
          </p>
          <div className="flex justify-end mb-8">
            <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl sm:mt-6 xl:mt-12 md:mt-6 text-white sm:h-7 md:h-8 lg:h-8 xl:h-10 w-9/12 text-right relative bg-[#746C68]">
              <span className="absolute sm:-top-7 lg:-top-5 xl:-top-8 end-3">
                Beginners
              </span>
            </p>
          </div>
          <p className="sm:text-2xl md:text-2xl lg:text-3xl xl:text-4.5xl text-white text-right font-light">
            Mindfulness practice for all
          </p>
        </div>
      </div>
      <div
        data-scroll-lock-scrollable
        className="overflow-y-scroll h-full w-screen sm:w-1/2 bg-web-primary"
      >
        <AuthenticationHeader heading="Sign up" />
        <div className="ml-[35px] mr-[35px] lg:ml-[91px] lg:mr-[36px] mb-3">
          <div className="grid grid-cols-2">
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <button className="h-[45px] w-full font-bold">LOG IN</button>
            </NavLink>
            <NavLink
              to={"/signup"}
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <button className="h-[45px] font-bold w-full">SIGN UP</button>
            </NavLink>
          </div>
        </div>
        <Formik
          onSubmit={handleSignup}
          initialValues={initialValues}
          validationSchema={signupSchema}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col items-center sm:block h-42 px-4 sm:pl-16.5 w-full ">
              <div className="w-full pl-5 pr-5 pb-10">
                <InputField
                  id="firstName"
                  label="First Name"
                  placeholder="Alex"
                >
                  {errors.firstName && touched.firstName ? (
                    <div className="text-red-500">{errors.firstName}</div>
                  ) : null}
                </InputField>
                <InputField
                  id="lastName"
                  label="Last Name"
                  placeholder="Williams"
                >
                  {errors.lastName && touched.lastName ? (
                    <div className="text-red-500">{errors.lastName}</div>
                  ) : null}
                </InputField>
                <InputField
                  type="email"
                  id="email"
                  label="Email Address"
                  placeholder="abc@gmail.com"
                >
                  {errors.email && touched.email ? (
                    <div className="text-red-500">{errors.email}</div>
                  ) : null}
                </InputField>
                <InputField
                  id="password"
                  label="Password"
                  placeholder="*********"
                >
                  {errors.password && touched.password ? (
                    <div className="text-red-500">{errors.password}</div>
                  ) : null}
                </InputField>
                <label className="flex items-center py-3 gap-x-3">
                  <input
                    id="conditions"
                    type="checkbox"
                    name="conditions"
                    className="checkbox bg-[#bfbfbf] checkbox-success border-none"
                    checked={condition}
                    onChange={() => setCondition(!condition)}
                  />
                  <span className="text-black">
                    I agree to the{" "}
                    <Link
                      to="/termConditions"
                      className="text-web-secondary underline"
                    >
                      Terms & Conditions
                    </Link>
                  </span>
                </label>
                {conditionError ? (
                  <div className="text-red-500 mt-[-10px]">
                    {conditionError}
                  </div>
                ) : null}
                {/* {error.length > 0 && <div className="text-red-500">{error}</div>} */}
                <button
                  type="submit"
                  disabled={toggle}
                  className="btn block mt-2 mb-12 w-full bg-web-secondary text-web-primary disabled:bg-web-containerLight disabled:text-white"
                >
                  {toggle ? <Loader /> : "Continue"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Signup;
