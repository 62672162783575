import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from "../store/store";

// Define the initial state
interface MyObject {
  typeId: string;
  type: string;
  name: string;
  plusTag: string;
}

interface MyState {
  data: MyObject[];
}

const initialState: MyState = {
  data: [],
};

// Create a slice
const mySlice = createSlice({
  name: 'healthTag',
  initialState,
  reducers: {
    addObject: (state, action: PayloadAction<MyObject>) => {
      state.data.push(action.payload);
    },
    updateArray: (state, action: PayloadAction<MyObject[]>) => {
      state.data = action.payload;
    },
  },
});

// Export the actions
export const { addObject, updateArray } = mySlice.actions;
export const healthTagSelector = (state: RootState) => state.healthTag.data;

// Export the reducer
export default mySlice.reducer;
