import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface ScreenState {
    value: number;
}

const initialState: ScreenState = {
    value: window.innerWidth,
}

export const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        updateScreenWidth: (state, action: PayloadAction<number>) => {
            state.value = action.payload;
          },
    },
})

export const screenWidthSelector = (state: RootState) => state.screen.value;
export const { updateScreenWidth } = screenSlice.actions;
export default screenSlice.reducer;
