import AuthenticationHeader from "../../components/AuthenticationHeader/AuthenticationHeader";
import { Formik, Form } from "formik";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { SignupOtp, SignupOtpData } from "../../interfaces/interfaces";
import { callApi } from "../../api/apiCalls/apiCalls";
import signupSrc from "../../assets/images/signup.png";
import Loader from "../../components/Loader/Loader";
import { useAppDispatch } from "../../redux/hooks/hooks";
import OtpInputComponent from "../UpdateForgotPassword/OtpInput";

const VerifyOtp = () => {
  const [, setError] = useState<string>("");
  // const [toggle, toggleSwitch] = useToggle();
  const [toggle, setToggle] = useState<boolean>(false);
  const [otp, setOtp] = useState<any>("");
  const [otpError, setOtpError] = useState<any>("");
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues: SignupOtp = {
    otp: "",
  };
  // showErrorToaster(error);
  const handleOtpVerification = (values: SignupOtp) => {
    setOtpError("");
    if (!otp) {
      setOtpError("This field is required");
    } else if (otp.length < 4) {
      setOtpError("Otp must have 4 digits");
    } else {
      const url = apiEndPoints.verifyOtp;
      const data: SignupOtpData = {
        otp: otp,
        userId: userId,
        timeDifference: 300,
      };
      callApi(navigate, dispatch, setError, data, url, "/about", setToggle);
    }
  };

  return (
    <div className="flex h-screen">
      <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
        <div className="flex flex-col sm:pr-10 md:pr-20 lg:pr-28 xl:pr-32">
          <div className="flex justify-end mb-10">
            <img
              className="sm:w-44 md:w-52 lg:w-64"
              src={signupSrc}
              alt="hand holding globe"
            />
          </div>
          <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl text-right text-white pr-2">
            Meditation for
          </p>
          <div className="flex justify-end mb-8">
            <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl sm:mt-6 xl:mt-12 md:mt-6 text-white sm:h-7 md:h-8 lg:h-8 xl:h-10 w-9/12 text-right relative bg-[#746C68]">
              <span className="absolute sm:-top-7 lg:-top-5 xl:-top-8 end-3">
                Beginners
              </span>
            </p>
          </div>
          <p className="sm:text-2xl md:text-2xl lg:text-3xl xl:text-4.5xl text-white text-right font-light">
            Mindfulness practice for all
          </p>
        </div>
      </div>
      <div className="h-full w-screen sm:w-1/2 bg-web-primary">
        <AuthenticationHeader heading="Submit OTP" />
        <Formik onSubmit={handleOtpVerification} initialValues={initialValues}>
          {({ errors, touched }) => (
            <Form className="flex flex-col items-center sm:block h-42 px-4 sm:pl-16.5">
              <div className="w-full pr-5 pl-5">
                {/* <InputField id="otp" label="Enter OTP">
                  {errors.otp && touched.otp ? (
                    <div className="text-red-500">{errors.otp}</div>
                  ) : null}
                </InputField> */}
                <OtpInputComponent otp={otp} setOtp={setOtp} />
                {otpError && otpError ? (
                  <div className="text-red-500">{otpError}</div>
                ) : null}
                {/* {error.length > 0 && <div className="text-red-500">{error}</div>} */}
                <button
                  type="submit"
                  disabled={toggle}
                  className="btn block mb-12 mt-12 w-full bg-web-secondary text-web-primary disabled:bg-web-containerLight disabled:text-white"
                >
                  {toggle ? <Loader /> : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VerifyOtp;
