import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { toast } from "react-toastify";
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import InputField from "../../components/InputField/InputField";
import { RxAvatar } from "react-icons/rx";
import * as Yup from "yup";
import { CropImage } from "./ImageCrop/CropImage";
import FileDropZone from "./ImageCrop/FileDropZone";

const Profile = () => {
  const [profileImage, setProfileImage] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const token = localStorage.getItem("accessToken");
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const navigation = useNavigate();

  const getUserDetails = async () => {
    const response = await fetch(apiEndPoints.getUserProfile, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setFirstName(data?.user?.firstName);
    setLastName(data?.user?.lastName);
    setEmail(data?.user?.email);
    setProfileImage(data?.user?.imageUrl);
  };
  useEffect(() => {
    setIsProfileUpdated(false);
    getUserDetails();
  }, [isProfileUpdated]);

  //   useEffect(() => {
  //     getUserDetails();
  //   }, []);

  interface InitialValues {
    firstName: string;
    lastName: string;
    imgSrc: string;
    email: string;
  }
  const initialValues: InitialValues = {
    firstName: firstName,
    lastName: lastName,
    imgSrc: profileImage,
    email: email,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("First name is required"),
    lastName: Yup.string().trim().required("Last name is required"),
  });

  const editProfileHandler = async (values: InitialValues) => {
    const formData = new FormData();
    formData.append("firstname", values.firstName);
    formData.append("lastname", values.lastName);
    formData.append("email", values.email);
    // formData.append("userImage", selectedFile);
    const uploadUrl = apiEndPoints.updateUser;

    const response = await axios.post(uploadUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;

    if (data.responseStatus === 1) {
      toast.success("Your profile was updated successfully"); // todo
      navigation("/");
    } else {
      toast.error(data.error);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles);
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        onSubmit={editProfileHandler}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form
            className="flex flex-col items-center sm:block p-5 pt-5 h-42 bg-web-primary"
            style={{ height: "100vh" }}
          >
            <div onClick={() => navigation("/")} className="w-full lg:hidden">
              <IoIosArrowBack color="black" size={30} />
            </div>
            <div className="mt-5 w-full flex justify-center">
              <div className="relative w-40 rounded-full">
                <div className="avatar">
                  <div className="w-40 rounded-full">
                    {profileImage ? (
                      <img src={profileImage} alt="avatar" />
                    ) : (
                      <RxAvatar size={160} color="black" />
                    )}
                  </div>
                </div>
                <Box
                  component="label"
                  // variant="contained"
                  className="absolute bottom-5 right-0 bg-web-primary p-2 rounded-full border"
                >
                  <MdEdit size={20} color="black" />
                  {/* <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  /> */}
                  <FileDropZone onDrop={onDrop} />
                </Box>
              </div>
            </div>
            {/* <div className="avatar">
              <div className="w-40 rounded-full">
                <img src={profileImage ? profileImage : avatarSrc} alt="profile" />
              </div>
            </div>
            <Button
              component="label"
              variant="contained"
              style={{ marginBottom: "15px" }}
            >
              Upload Image
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button> */}
            <div className="w-full pl-5 pr-5 mt-4">
              <InputField id="email" label="Email" disabledField={true}>
                {errors.email && touched.email ? (
                  <div className="text-red-500">{errors.email}</div>
                ) : null}
              </InputField>
              <InputField id="firstName" label="First Name">
                {errors.firstName && touched.firstName ? (
                  <div className="text-red-500">{errors.firstName}</div>
                ) : null}
              </InputField>
              <InputField id="lastName" label="Last Name">
                {errors.lastName && touched.lastName ? (
                  <div className="text-red-500">{errors.lastName}</div>
                ) : null}
              </InputField>
            </div>
            <div className="w-full pl-5 pr-5 mt-6">
              <button
                type="submit"
                className="btn block w-full bg-web-secondary text-web-primary disabled:bg-web-containerLight disabled:text-white"
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {selectedFile && (
        <div>
          <dialog
            id="my_modal_5"
            className="modal modal-middle sm:modal-middle"
            open={selectedFile.length > 0 ? true : false}
          >
            <div className="modal-box bg-white text-black p-4 sm:p-8">
              <div className="flex justify-end mt-0">
                <button
                  className="modal-close text-[30px]"
                  onClick={() => {
                    setSelectedFile([]);
                  }}
                >
                  {/* Add your close (cross) icon or text here */}
                  &times;
                </button>
              </div>
              {/* <p className="py-4">
                Are you sure you want to delete this audio permanently?
              </p> */}
              <CropImage
                selectedImage={selectedFile}
                setIsProfileUpdated={setIsProfileUpdated}
                setSelectedFile={setSelectedFile}
              />
              {/* <div className="modal-action flex justify-center sm:justify-end">
                <form method="dialog" className="w-full text-white">
                  <button className="btn h-3 text-white" onClick={() => {
                    setSelectedFile([])
                  }}>
                    No
                  </button>
                  <button className="btn mx-1 w-[4rem] h-3 text-white">
                    Yes
                  </button>

                </form>
              </div> */}
            </div>
          </dialog>
        </div>
      )}
    </div>
  );
};

export default Profile;
