import InputField from "../../components/InputField/InputField";
import AuthenticationHeader from "../../components/AuthenticationHeader/AuthenticationHeader";
import { Formik, Form } from "formik";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { callApi } from "../../api/apiCalls/apiCalls";
import {
  UpdatePassword,
  UpdatePasswordData,
} from "../../interfaces/interfaces";
import { updatePasswordSchema } from "../../validationSchema/validationSchema";
import birdSrc from "../../assets/images/bird.png";
import Loader from "../../components/Loader/Loader";
import { useAppDispatch } from "../../redux/hooks/hooks";
import OtpInputComponent from "./OtpInput";
import { IoIosArrowBack } from 'react-icons/io';

const UpdateForgotPassword = () => {
  const [, setError] = useState<string>("");
  const [toggle, setToggle] = useState<boolean>(false);
  const [formError, setFormError] = useState<any>({
    misMatch: "",
    opt: "",
  });
  const [otp, setOtp] = useState<any>("");
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useAppDispatch()

  const initialValues: UpdatePassword = {
    otp: "",
    newPassword: "",
    reEnterNewPassword: "",
  };
  // showErrorToaster(error);
  const handlePasswordUpdate = (values: UpdatePassword) => {
    setFormError({ otp: "", misMatch: "" });
    if (!otp) {
      setFormError({ ...formError, otp: "This field is required" })
    } else if (otp.length < 4) {
      setFormError({ ...formError, otp: "Otp must have 4 digits" })
    } else {
      const url = apiEndPoints.updateForgotPassword;
      const data: UpdatePasswordData = {
        userId: userId,
        otpNumber: otp,
        newPassword: values.newPassword,
      };
      callApi(navigate, dispatch, setError, data, url, "/", setToggle);
    }
  };

  return (
    <div className="flex h-screen">
      <div className="justify-center items-center w-1/2 hidden sm:flex bg-web-container">
        <div className="flex items-center flex-col w-4/5 bg-web-primary rounded-t-full">
          <img
            className="sm:mt-8 md:mt-10 lg:mt-12 xl:mt-12 sm:mb-5 md:mb-5 lg:mb-8 xl:mb-10 h-2/3 sm:w-20 md:w-28 lg:w-32 xl:w-36"
            src={birdSrc}
            alt="bird"
          />
          <p className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-web-secondaryDark sm:mb-4 md:mb-4 lg:mb-7 xl:mb-7">
            Reminder
          </p>
          <p className="text-center sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-web-secondary sm:mb-5 md:mb-8 lg:mb-10 xl:mb-14 font-normal">
            Be there for yourself because <br />
            you need yourself
          </p>
        </div>
      </div>
      <div className="h-full w-screen sm:w-1/2 bg-web-primary">
      <div
          style={{
            padding: '0px 10px',
            marginTop: "15px"
          }}
        >
          <div onClick={() => navigate("/")}>
            <IoIosArrowBack color="black" size={30} />
          </div>

        </div>
        <AuthenticationHeader heading=" Password Reset">
          <p className=" pl-10 pr-10 sm:text-start mb-8 font-medium text-web-secondary">
          Please set a new password for your account so you can login and get access to all your data.
          </p>
        </AuthenticationHeader>
        <Formik
          onSubmit={handlePasswordUpdate}
          initialValues={initialValues}
          validationSchema={updatePasswordSchema}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="flex flex-col items-center sm:block h-42 px-4 sm:pl-16.5">
              <div className="w-full pr-5 pl-5">
                <InputField id="newPassword" label="New Password">
                  {errors.newPassword && touched.newPassword ? (
                    <div className="text-red-500">{errors.newPassword}</div>
                  ) : null}
                </InputField>
                <InputField id="reEnterNewPassword" label="Re-Enter Password">
                  {errors.reEnterNewPassword && touched.reEnterNewPassword ? (
                    <div className="text-red-500">{errors.reEnterNewPassword}</div>
                  ) : formError.misMatch ? <div className="text-red-500">{formError.misMatch}</div> : null}
                </InputField>
                {/* {error.length > 0 && <div className="text-red-500">{error}</div>} */}
                <OtpInputComponent otp={otp} setOtp={setOtp} />
                {formError.otp && formError.otp ? (
                  <div className="text-red-500">{formError.otp}</div>
                ) : null}
                  <button
                type="submit"
                value="Submit"
                disabled={toggle}
                className="btn block w-full bg-web-secondary text-web-primary mt-16 disabled:bg-web-containerLight disabled:text-white"
              >
                {toggle ? <Loader /> : "Submit"}
              </button>
              </div>
            
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateForgotPassword;
