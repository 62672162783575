import { Formik, Form } from "formik";
import { restPasswordSchema } from "../../validationSchema/validationSchema";
import { useState } from "react";
import SettingsField from "./SettingsField";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { clearAuthorization } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import drawerLogo from "../../assets/images/daily-mentor-logo.svg";

const Privacy = () => {
  const [, setToggle] = useState<boolean>(false);
  const navigation = useNavigate();

  interface InitialValues {
    currentPassword: string;
    newPassword: string;
    email: string;
  }
  const initialValues: InitialValues = {
    currentPassword: "",
    newPassword: "",
    email: "",
  };

  const dispatch = useAppDispatch();
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const updatePasswordHandler = async (values: InitialValues) => {
    const token = localStorage.getItem("accessToken");
    const data = {
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    const response = await fetch(`${apiEndPoints.updateUserPassword}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    if (responseData.responseStatus === 1) {
      toast.success("Successfully Reset Password");
      values.currentPassword = "";
      values.newPassword = "";
    } else {
      toast.error(responseData.error);
    }
  };

  const handleDeleteUser = async () => {
    setIsDelete(false);
    const token = localStorage.getItem("accessToken");
    const response = await fetch(`${apiEndPoints.deleteUser}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    if (data?.responseStatus === 1) {
      toast.success(data?.message);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("editToggle");
      dispatch(clearAuthorization());
    } else {
      toast.error(data?.error);
    }
  };

  return (
    <>
      <div className=" bg-mobile-primary w-full p-5 pt-5 h-screen overflow-y-scroll">
        <div
          style={
            {
              // padding: '0px 10px',
            }
          }
        >
          <div
            className="lg:hidden"
            onClick={() => {
              navigation("/");
            }}
          >
            <IoIosArrowBack color="black" size={30} />
          </div>
        </div>
        <img
          src={drawerLogo}
          className="block mx-auto w-24"
          alt="logo"
          style={{
            width: "200px",
            height: "auto",
            marginBottom: "60px",
          }}
        />
        <p className="text-black mb-3">
          You can change or update your password from here.
        </p>
        <Formik
          onSubmit={updatePasswordHandler}
          initialValues={initialValues}
          validationSchema={restPasswordSchema}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col sm:block lg:p-10 bg-web-primary sm:bg-web-container mb-3">
              <SettingsField
                id="currentPassword"
                label="Current Password"
                isPassword
              >
                {errors.currentPassword && touched.currentPassword ? (
                  <div className="text-red-500">{errors.currentPassword}</div>
                ) : null}
              </SettingsField>
              <SettingsField id="newPassword" label="New Password" isPassword>
                {errors.newPassword && touched.newPassword ? (
                  <div className="text-red-500">{errors.newPassword}</div>
                ) : null}
              </SettingsField>
              <button
                onClick={() => {
                  setToggle(true);
                }}
                className="btn block mt-5 bg-web-secondary text-web-primary disabled:bg-web-containerLight disabled:text-white"
              >
                Reset Password
              </button>
            </Form>
          )}
        </Formik>

        <div className="flex lg:p-10 flex-col mt-[2.75rem] sm:block bg-web-primary sm:bg-web-container text-black">
          <h2 className="text-xl font-bold">Delete your account</h2>
          <p>This action will permanently remove your account.</p>
          <button
            className="btn block mt-5  bg-web-secondary text-web-primary disabled:bg-web-containerLight disabled:text-white"
            onClick={() => setIsDelete(true)}
          >
            Delete Account
          </button>
        </div>

        {isDelete && (
          <div>
            <dialog
              id="my_modal_5"
              className="modal modal-middle sm:modal-middle"
              open={isDelete}
            >
              <div className="modal-box bg-white text-black p-4 sm:p-8">
                <h3 className="font-bold text-lg">Alert!</h3>
                <p className="py-4">
                  Are you sure you want to delete the account?
                </p>
                <div className="modal-action flex justify-center sm:justify-end">
                  <form method="dialog">
                    <button className="btn mx-3" onClick={handleDeleteUser}>
                      Yes
                    </button>
                    <button className="btn" onClick={() => setIsDelete(false)}>
                      No
                    </button>
                  </form>
                </div>
              </div>
            </dialog>
          </div>
        )}
      </div>
    </>
  );
};

export default Privacy;
