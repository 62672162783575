import { Outlet, useNavigate } from "react-router";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { updateArray } from "../../redux/slices/healthTagsSlice";
import MenuItemMobile from "../../components/MobileHeader/MenuItemMobile";
import aboutYou from "../../assets/images/aboutIcons.svg";
import aboutUs from "../../assets/images/aboutUs.svg";
import help from "../../assets/images/help.svg";
import subscribtion from "../../assets/images/subcription.svg";
import privacy from "../../assets/images/privacy.svg";
import logout from "../../assets/images/log-out 1.svg";
import { clearAuthorization } from "../../redux/slices/authSlice";
import { useEffect, useState } from "react";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { RxAvatar } from "react-icons/rx";

const Settings = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<any>("");
  const [profileImage, setProfileImage] = useState<string>("");
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  dispatch(updateArray([]));

  const getUserDetails = async () => {
    const response = await fetch(apiEndPoints.getUserProfile, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setFirstName(data?.user?.firstName);
    if (data?.user?.lastName) {
      setLastName(data?.user?.lastName);
    }
    setProfileImage(data?.user?.imageUrl);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(clearAuthorization());
  };

  return (
    <div className="block bg-mobile-primary sm:flex gap-x-10 sm:mb-auto lg:mb-0 h-screen overflow-y-scroll sm:overflow-hidden sm:mt-6 sm:mx-8 md:mx-16">
      <div className="hidden sm:flex flex-col gap-y-3 w-[35%] min-[820px]:w-[30%] lg:w-[38%] overflow-y-scroll bg-web-container">
        <div className="bg-web-container flex flex-col jusitfy-center py-4 rounded-lg mb-2">
          {/* <CgProfile className="mx-auto mb-3" size={80} color="black" /> */}
          <div className="avatar mx-auto">
            <div className="w-20 flex items-center justify-center rounded-full">
              {profileImage ? (
                <img src={profileImage} alt="profile-image" />
              ) : (
                <RxAvatar size={80} color="black" />
              )}
            </div>
          </div>
          <p className="text-center text-black font-bold">
            {firstName} {lastName}
          </p>
          <p
            className="text-sm text-center text-black cursor-pointer"
            onClick={() => {
              navigate("/settingsWeb");
            }}
          >
            View Profile
          </p>
        </div>
        <ul className="menu px-0 text-black bg-web-container w-full rounded-lg">
          <MenuItemMobile
            image={aboutYou}
            text="About You"
            route="/settingsWeb/aboutU"
          />
          {/* <MenuItemMobile image={}  text="Account" route="/" /> */}
          {/* <MenuItemMobile
            image={personalized}
            text="Personalized Visual Inspiration"
            route="/settingsWeb/personalized"
          /> */}
          {/* <MenuItemMobile
            image={clock}
            text="Target Toggle Time"
            route="/settingsWeb/toggleAlaramSet"
          /> */}
          <MenuItemMobile
            image={privacy}
            text="Privacy"
            route="/settingsWeb/privacy"
          />
          <MenuItemMobile
            image={subscribtion}
            text="Manage Subscriptions"
            route="/settingsWeb/profile"
          />
          {/* <MenuItemMobile  text="Contact Us" route="/" /> */}
          <MenuItemMobile
            image={help}
            text="Help"
            route="/settingsWeb/profile"
          />
          <MenuItemMobile
            image={aboutUs}
            text="About Us"
            route="/settingsWeb/aboutUs"
          />
          <li>
            <div className="flex gap-2 mt-1">
              <img src={logout} alt="logout" className="w-[20px]" />
              <a
                className="flex px-1 flex-row justify-between grow"
                onClick={handleLogout}
                href="#"
              >
                <span className="grow text-[15px]">Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="w-full sm:w-[60%] md:w-[55%] xl:w-[100%]">
        {/* {
          location.pathname === "/settings/about" && <AboutU />
        }
        {
          location.pathname === "/settings/personalized" && <PersonalizedVisual />
        }
        {
          location.pathname === "/settings/toggleAlaramSet" && <ToggleSetup />
        }
        {
          location.pathname === "/settings/privacy" && <Privacy />
        } */}
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
