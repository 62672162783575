import { useAppSelector } from "../../../redux/hooks/hooks";
import { boostSelector } from "../../../redux/slices/boostSlice";
import AudioHighlightWithPlayer from "./AudioHighlightWithPlayer";

const MobileLyricsCopy = () => {
  const response = useAppSelector(boostSelector);
  return (
    <>
      {response?.boosts && (
        <AudioHighlightWithPlayer boost={response?.boosts} />
      )}
    </>
  );
};

export default MobileLyricsCopy;
