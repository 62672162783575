import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import {
  addToggleTimeScore,
  getCurrentStreak,
  isStreakActive,
  mobileMediaSelector,
  togglePlayer,
} from "../../redux/slices/mobileMediaSlice";
import {
  CURRENT_DAY,
  CURRENT_MONTH,
  CURRENT_YEAR,
} from "../constants/constants";
import animationScreenGif from "../../assets/images/animation-screen.gif";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import moment from "moment";

const ToggleSwitch = () => {
  const dispatch = useAppDispatch();
  const [isTrialPeriodExpired, setTrialPeriodExpired] = useState(false);
  const mobileMedia = useAppSelector(mobileMediaSelector);
  const toggleEnable = useAppSelector(
    (state) => state?.mobileMedia?.togglenable
  );
  const [showAnimation, setShowAnimation] = useState(false);
  const checkTrialPeriod = async () => {
    const token = localStorage.getItem("accessToken");
    const userResponse = await fetch(apiEndPoints.getUserProfile, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const userData = await userResponse.json();
    const subscriptionResponse = await fetch(
      apiEndPoints.getSubscriptionDetails,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const subData = await subscriptionResponse.json();
    if (
      moment().diff(moment(userData?.user?.createdOn), "d") > 7 &&
      (!subData.data || subData?.data?.status !== "active")
    ) {
      setTrialPeriodExpired(true);
    }
  };

  useEffect(() => {
    checkTrialPeriod();
  }, []);
  const handleToggleTime = async () => {
    setShowAnimation(true);
    dispatch(addToggleTimeScore());
    dispatch(togglePlayer());
    setTimeout(() => {
      // dispatch(getToggleTimeTodayScore());
      dispatch(getCurrentStreak());
    }, 1000);
    setTimeout(() => {
      setShowAnimation(false);
    }, 4000);
    dispatch(isStreakActive());

    setTimeout(() => {
      dispatch(isStreakActive());
    }, 5000);
  };

  return (
    <div className="block items-center justify-center relative w-[100%]">
      <img
        src={animationScreenGif}
        alt="progress bar"
        className={`fixed h-[100vh] w-[100vw] top-0 left-0 z-[60] ${
          showAnimation ? "block" : "hidden"
        }`}
      />
      {!isTrialPeriodExpired && (
        <div className="mt-[28%]">
          <button
            className={`flex w-[55px] h-[28px] lg:w-[55px] lg:h-[28px] items-center ${
              mobileMedia ? "bg-mobile-toggleSwitch" : "bg-slate-300"
            } rounded-full self-end transition-all border-none`}
            onClick={handleToggleTime}
            disabled={!toggleEnable}
          >
            <span
              className={`h-[24px] w-[24px] lg:w-[24px] lg:h-[24px] ml-1 ${
                mobileMedia ? "ml-[28px] lg:ml-[28px]" : "ml-0"
              } bg-mobile-primary rounded-full transition-all`}
            ></span>
          </button>
        </div>
      )}
      <div className="flex align-center justify-center">
        <p className="text-center self-center text-[18px] pt-[2px] lg:text-[20px] mt-[-5%]">{`${CURRENT_MONTH} ${CURRENT_DAY}, ${CURRENT_YEAR}`}</p>
      </div>
      {/* <img src={animationScreenGif} alt="progress bar" className="hidden" /> */}
    </div>
  );
};

export default ToggleSwitch;
