import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import Root from "./Root";
import VerifyOtp from "../pages/VerifyOtp/VerifyOtp";
import UserProfile from "../pages/UserProfile/UserProfile";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import UpdateForgotPassword from "../pages/UpdateForgotPassword/UpdateForgotPassword";
import { createBrowserRouter } from "react-router-dom";
import TermConditions from "../pages/Signup/TermConditions";
import SignUpDetails from "../pages/Signup/SignUpDetails";
import ShareFeature from "../components/Share/Share";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "", element: <Login /> },
      { path: "signup", element: <Signup /> },
      { path: "/signupDetails", element: <SignUpDetails /> },
      { path: "verifyOtp/:userId", element: <VerifyOtp /> },
      { path: "/profile", element: <UserProfile /> },
      { path: "/forgotPassword", element: <ForgotPassword /> },
      {
        path: "/updateForgotPassword/:userId",
        element: <UpdateForgotPassword />,
      },
      { path: "*", element: <Login /> },
      { path: "/share/:id", element: <ShareFeature /> },
      { path: "termConditions", element: <TermConditions /> },
    ],
  },
]);
