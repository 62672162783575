import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import axios from "axios";

interface MobilePlayerState {
  value: boolean;
  togglenable: boolean;
  score: any;
  toggleScore: any;
  boostPlayed: any;
  currentStreak: any;
  toggleEdit: any;
  isStreakAnimate: any;
}
const initialState: MobilePlayerState = {
  value: false,
  togglenable: false,
  score: null,
  toggleScore: null,
  boostPlayed: 0,
  currentStreak: 0,
  toggleEdit: localStorage.getItem("editToggle") === "false" ? false : true,
  isStreakAnimate: false,
};

export const addToggleTimeScore = createAsyncThunk(
  "addToggleTimeScore",
  async () => {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(apiEndPoints.addToggleTimeScore, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const getCurrentStreak = createAsyncThunk("getStreak", async () => {
  const token = localStorage.getItem("accessToken");
  const response = await axios.get(apiEndPoints.currentStreak, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const getToggleTimeTodayScore = createAsyncThunk(
  "getTodayToggleTimeScore",
  async () => {
    const token = localStorage.getItem("accessToken");
    const time = new Date();

    const response = await axios.post(
      apiEndPoints.getTodayToggleTimeScore,
      { currentTime: time.toLocaleString() },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const addToggleTimeScoreOnPlaySong = createAsyncThunk(
  "addToggleTimeScoreOnPlaySong",
  async (boostId: any) => {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(
      `${apiEndPoints.addToggleTimeScoreOnPlaySong}/${boostId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const getPlayedTimeBoost = createAsyncThunk(
  "getPlayedTimeBoost",
  async (boostId: any) => {
    const token = localStorage.getItem("accessToken");
    if (boostId) {
      const response = await axios.get(
        `${apiEndPoints.getPlayedTimeBoost}/${boostId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    }
  }
);

export const mobileMediaSlice = createSlice({
  name: "mobilePlayer",
  initialState,
  reducers: {
    togglePlayer: (state) => {
      if (state.togglenable) {
        state.value = true;
        state.togglenable = false;
        state.toggleEdit = true;
        localStorage.setItem("editToggle", "true");
      }
    },
    toggleOneTimePlay: (state) => {
      state.togglenable = false;
      state.value = true;
      state.toggleEdit = true;
      localStorage.setItem("editToggle", "true");
    },
    closePlayer: (state) => {
      state.value = false;
      state.score = null;
    },
    toggleEnable: (state) => {
      state.togglenable = true;
      state.toggleEdit = false;
      localStorage.setItem("editToggle", "false");
    },
    toggleUnable: (state) => {
      state.togglenable = false;
      state.toggleEdit = true;
      localStorage.setItem("editToggle", "true");
    },
    isStreakActive: (state) => {
      state.isStreakAnimate = !state.isStreakAnimate;
    },
    // ToggleTimeEdit: (state)
  },
  extraReducers: (builder) => {
    builder.addCase(getToggleTimeTodayScore.fulfilled, (state, action) => {
      state.score = action.payload.response?.score;
      state.toggleScore = action.payload.response?.toggleScore
        ? action.payload.response?.toggleScore
        : null;
    });
    builder.addCase(getPlayedTimeBoost.fulfilled, (state, action) => {
      state.boostPlayed = action.payload?.response?.playedX
        ? action.payload?.response?.playedX
        : 0;
    });
    builder.addCase(getCurrentStreak.fulfilled, (state, action) => {
      state.currentStreak = action.payload?.response?.currentStreak;
    });
    builder.addCase(addToggleTimeScore.fulfilled, (state, action) => {
      state.score = action.payload.response.score;
    });
  },
});

export const mobileMediaSelector = (state: RootState) =>
  state.mobileMedia.value;
export const {
  togglePlayer,
  closePlayer,
  toggleEnable,
  toggleUnable,
  toggleOneTimePlay,
  isStreakActive,
} = mobileMediaSlice.actions;
export default mobileMediaSlice.reducer;
