import { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  CURRENT_DAY,
  CURRENT_MONTH,
  CURRENT_YEAR,
} from "../../components/constants/constants";
import moment from "moment";
import logoSrc from "../../assets/images/daily-mentor-logo.svg";
import shareIcon from "../../assets/images/share_black.svg";
import deafultInspiration from "../../assets/images/deafultInspiration.svg";
import SlickSlider from "react-slick";
import audioFile from "../../assets/audio2.mp3";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import Slider from "@mui/material/Slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../CurrentPlaying/MobileLyrics/style.css";
import { screenWidthSelector } from "../../redux/slices/screenSlice";
import AudioPlayerr from "../CurrentPlaying/MobileLyrics/AudioPlayerr";
import forward from "../../assets/images/forward-10-seconds.svg";
import backward from "../../assets/images/rewind-10-seconds.svg";
import playIcon from "../../assets/images/playIcon.svg";
import pauseIcon from "../../assets/images/pauseIcon.svg";
import CustomLoaderComponent from "../CustomLoaderComponent/CustomLoaderComponent";
import ProgressBarData from "../CurrentPlaying/ProgressBar";
import {
  closePlayer,
  toggleEnable,
  toggleOneTimePlay,
  toggleUnable,
} from "../../redux/slices/mobileMediaSlice";
// import Wave2 from "./Wave";
import fillSave from "../../assets/images/filled.svg";
import unFilledSave from "../../assets/images/outlined.svg";
import { RxAvatar } from "react-icons/rx";
import { BiMenu } from "react-icons/bi";

const ShareFeature = () => {
  const [currentBoost, setCurrentBoost] = useState(null);
  const [loader, setLoader] = useState(false);
  const [, setAnimationClass] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector((state) => state?.userAuth?.userDetails);
  const [progress, setProgress] = useState(0);
  const [, setIsPlaying] = useState(false);
  const [musicPlayed, setMusicPlayed] = useState("0:00");
  const [isSave, setIsSave] = useState(false);
  const [, setUpComingTime] = useState(null);
  const [, setIsPlayingOnce] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState("0:00");
  const playerRef = useRef(new Audio(audioFile));
  const [showAnimation, setShowAnimation] = useState(true);

  setTimeout(() => {
    setShowAnimation(false);
  }, 7000);

  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken");

  const handlePlay = () => {
    if (playerRef.current) {
      setIsPlaying(true);
      setPlaying(true);
      setIsPlay(true);
      setIsPlayingOnce(true);

      const player = playerRef.current;

      if (typeof player.play === "function") {
        player.play();
      }
    }
  };

  const playedTime = useAppSelector((state) => state?.mobileMedia?.boostPlayed);

  const handleRemoveSave = async () => {
    if (currentBoost?._id) {
      const payload = {
        boostId: currentBoost?._id,
      };
      const res = await axios.post(apiEndPoints.removeBoost, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.responseStatus === 1) {
        setIsSave(false);
      }
    }
  };

  const handlePause = () => {
    if (playerRef.current) {
      setIsPlaying(false);
      setPlaying(false);

      const player = playerRef.current;

      if (typeof player.pause === "function") {
        player.pause();
      }
    }
  };

  const handleSave = async () => {
    if (!token) {
      navigate("/signup");
    }
    if (currentBoost?._id && token) {
      const payload = {
        boostId: currentBoost?._id,
      };
      const res = await axios.post(apiEndPoints.saveBoost, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.responseStatus === 1) {
        setIsSave(true);
      }
    }
  };

  const toggleScore = useAppSelector(
    (state) => state?.mobileMedia?.toggleScore
  );

  useLayoutEffect(() => {
    setTimeout(() => {
      if (toggleScore) {
        dispatch(toggleOneTimePlay());
      }
    }, 2000);
  }, [toggleScore]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    dotsClass: "slider-dots",
  };

  useEffect(() => {
    dispatch(toggleEnable());
    let isActive = false;
    const updateUpcomingTime = async () => {
      const currentTime = new Date();
      const midNightTime = new Date();

      midNightTime.setHours(23);
      midNightTime.setMinutes(59);
      midNightTime.setSeconds(59);

      const remainingTimeMillis =
        midNightTime.getTime() - currentTime.getTime();

      // Convert milliseconds to hours, minutes, and seconds
      const remainingHours = Math.floor(remainingTimeMillis / (1000 * 60 * 60));
      const remainingMinutes = Math.floor(
        (remainingTimeMillis % (1000 * 60 * 60)) / (1000 * 60)
      );
      const remainingSeconds = Math.floor(
        (remainingTimeMillis % (1000 * 60)) / 1000
      );

      setUpComingTime({
        hours: String(remainingHours).padStart(2, "0"),
        minutes: String(remainingMinutes).padStart(2, "0"),
        seconds: String(remainingSeconds).padStart(2, "0"),
      });

      const newTime = moment(currentTime).format("hh:mm a");

      if (newTime === "12:00 am" && !isActive) {
        //  getToggleTimeTodayScore()
        dispatch(toggleUnable());
        isActive = true;
        dispatch(closePlayer());
        dispatch(toggleEnable());
      }
    };
    setInterval(updateUpcomingTime, 1000);
  }, []);

  const [isNumberPopUp, setIsNumberPopUp] = useState(false);

  useEffect(() => {
    if (isPlay) {
      setAnimationClass("animate__animated animate__bounceIn");
      const timeout = setTimeout(() => {
        setAnimationClass("");
      }, 3000);
      const time = setTimeout(() => {
        setIsNumberPopUp(true);
        const times = setTimeout(() => {
          setIsNumberPopUp(false);
        }, 1000);
        return () => clearTimeout(times);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isPlay]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          text: `${process.env.REACT_APP_LIVE}/share/${currentBoost?._id}`,
        });
      } catch (error) {
        console.error("Error sharing audio:", error);
      }
    } else {
      console.log("Web Share API is not supported on this browser.");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleForwards = () => {
    const player = playerRef.current;
    if (player) {
      player.currentTime = player.currentTime + 10;
    }
  };

  const handleRewinds = () => {
    const player = playerRef.current;
    if (player) {
      player.currentTime = player.currentTime - 10;
    }
  };

  const handleSwape = () => {
    if (currentIndex === 0) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(0);
    }
  };

  const screenWidth = useAppSelector(screenWidthSelector);

  const { pathname } = useLocation();

  const arr = pathname?.split("/");

  const currentId = arr[arr?.length - 1];

  useEffect(() => {
    setLoader(true);
    const getBoost = async () => {
      const response = await axios.get(
        `${apiEndPoints.getShareBoostById}/${currentId}`
      );

      if (response.data.responseStatus === 1) {
        setCurrentBoost(response.data.response);
      } else {
        console.log("Something went wrong");
      }
      setLoader(false);
    };
    getBoost();
  }, [pathname]);

  useEffect(() => {
    if (currentBoost) {
      let audioEle = document.getElementById(
        `shared-audio-boost-${currentBoost._id}`
      );
      if (audioEle) {
        const player = playerRef.current;
        player?.addEventListener("timeupdate", () => {
          const durationInSeconds = playerRef.current.currentTime;
          const minutes = Math.floor(durationInSeconds / 60);
          const seconds = Math.floor(durationInSeconds % 60);
          setMusicPlayed(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
        });
      }
    }
  }, [playerRef.current, currentBoost]);

  return (
    <>
      {loader ? (
        <CustomLoaderComponent loader={loader} />
      ) : (
        <div className="flex flex-col bg-mobile-primary min-h-dvh max-h-dvh text-black w-full lg:overflow-y-hidden">
          {screenWidth < 1025 && (
            <div className="flex justify-between px-2 z-40 items-center mt-5">
              <div className="">
                <label htmlFor="my-drawer-4" className="drawer-button">
                  <span className="border w-[50px] h-[50px] flex items-center justify-center rounded-full bg-black cursor-pointer">
                    <BiMenu
                      color="white"
                      className="px-0"
                      size={"30px"}
                      onClick={() => navigate("/signup")}
                    />
                  </span>
                </label>
              </div>
              <div className="self-center">
                <img className="w-[154px]" src={logoSrc} />
              </div>
              <div>
                <div
                  className="w-[56px] h-[56px] rounded-full self-center"
                  onClick={() => navigate("/signup")}
                >
                  {userDetails?.imageUrl ? (
                    <img
                      style={{
                        borderRadius: "100%",
                        width: "56px",
                        height: "56px",
                      }}
                      src={userDetails?.imageUrl}
                      alt="avatar"
                    />
                  ) : (
                    <RxAvatar
                      size={55}
                      color="black"
                      className="cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="flex align-center justify-center">
            <p className="text-center self-center text-[18px] pt-[2px] lg:text-[20px]">{`${CURRENT_MONTH} ${CURRENT_DAY}, ${CURRENT_YEAR}`}</p>
          </div>
          <div className="grow flex flex-col relative items-center justify-center mt-[10px]">
            <ProgressBarData isPlay={showAnimation} />
            {currentIndex === 0 ? (
              <div
                onDragEnd={handleSwape}
                onDragStart={handleSwape}
                style={{ padding: "17px 30px" }}
                className="hightlight-text-container grow item-stretch mx-[2%] rounded-t-[20px] flex flex-col justify-center leading-10 bg-black text-white h-56"
              >
                {currentBoost && (
                  <AudioPlayerr
                    playerRef={playerRef}
                    playing={playing}
                    setPlaying={setPlaying}
                    setDuration={setDuration}
                    setProgress={setProgress}
                    setIsPlayingOnce={setIsPlayingOnce}
                    setIsPlay={setIsPlay}
                    isShare={false}
                    audioFile={currentBoost?.audioUrl}
                    ssmlText={currentBoost?.text}
                    speechMarks={currentBoost?.speechMarks[0]}
                    id={`shared-audio-boost-${currentBoost._id}`}
                  />
                )}
              </div>
            ) : (
              <div
                className="grow border item-stretch w-[100%] mx-0 text-center overflow-y-hidden text-2xl leading-10 bg-black text-white mb-1 h-56"
                onDragEnd={handleSwape}
                onDragStart={handleSwape}
              >
                <SlickSlider
                  {...settings}
                  className="w-full h-[95%]"
                  arrows={false}
                  autoplay
                  autoplaySpeed={10000}
                >
                  {currentBoost && currentBoost.imageUrl.length > 0 ? (
                    currentBoost.imageUrl.map((item, index) => (
                      <div key={index} className="slick-center h-full">
                        <div className="flex items-center justify-center h-full">
                          <img
                            className="object-contain h-full max-w-full my-auto"
                            src={item}
                            alt=""
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="slick-slide h-full slick-center">
                      <div className="h-full flex items-center justify-center">
                        <img
                          src={deafultInspiration}
                          alt="Default inspiration"
                          className="max-w-full h-auto object-contain"
                        />
                      </div>
                    </div>
                  )}
                </SlickSlider>
              </div>
            )}
            <div className="px-[2%] flex w-full grow-[0.5]">
              <div className="bg-[#D8D6D0] w-full flex justify-center flex-col">
                <div className="text-center flex flex-col">
                  <div className="w-[80%] mx-auto pt-[3%]">
                    <Slider
                      step={1}
                      style={{ paddingBottom: "0px", width: "100%" }}
                      color="primary"
                      value={progress}
                      min={0}
                      max={100}
                      // onChange={handleChangeSeekBar}
                    />
                  </div>

                  <div className="flex justify-between w-[80%] mx-auto text-black">
                    <p className="text-xs">{musicPlayed}</p>
                    <p className="text-xs"> {duration}</p>
                  </div>
                </div>
                <div className="flex w-[96%] justify-between mx-auto items-center relative">
                  <div>
                    {isSave ? (
                      <div onClick={handleRemoveSave}>
                        <img
                          src={fillSave}
                          alt="fillSaveIcon"
                          className="h-[33.79px] w-[33.79px]"
                        />
                      </div>
                    ) : (
                      <div onClick={handleSave}>
                        <img
                          src={unFilledSave}
                          alt="unsaveIcon"
                          className="h-[33.79px] w-[33.79px]"
                        />
                      </div>
                    )}
                  </div>
                  <img
                    className="h-[41px] w-[41px]"
                    onClick={handleRewinds}
                    src={backward}
                    alt="prev-10sec"
                  />
                  {/* <div className="flex gap-2 items-end"> */}
                  <div className="relative">
                    <div className="flex items-center justify-center w-[75px] h-[75px] rounded-full bg-black flex-shrink-0">
                      {!playing && (
                        <img
                          src={playIcon}
                          alt="play icon"
                          onClick={handlePlay}
                          className="pl-[5px] w-[40px] h-[40px]"
                        />
                      )}
                      {playing && (
                        // <HiPause color="black" size={30} onClick={handlePause} />
                        <img
                          src={pauseIcon}
                          alt="pause icon"
                          onClick={handlePause}
                          className="] w-[40px] h-[40px]"
                        />
                      )}
                    </div>
                    {/* <div
                      className={`absolute right-[-22px] top-0 flex flex-col justify-center items-center ${
                        isNumberPopUp && "pulse"
                      }`}
                    >
                      <div className="">
                        <p className="text-[1rem] relative">{playedTime}x</p>
                      </div>
                    </div> */}
                  </div>
                  <img
                    className="h-[41px] w-[41px]"
                    onClick={handleForwards}
                    src={forward}
                    alt="next-10sec"
                  />
                  <div onClick={handleShare}>
                    {/* <GoShare color="black" size={"35"} /> */}
                    <img
                      src={shareIcon}
                      alt="shareIcon"
                      className="w-[41px] h-[41px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[2%] pb-[3%]">
            <div className="flex justify-center p-[3%] py-[16px] rounded-b-[19px] bg-[#D8D6D0]">
              <Link
                to="/signup"
                className="flex items-center justify-center self-center bg-[#D8D6D0] w-[92%] pt-3 pb-[4%] rounded-b-[19px]"
              >
                <p className="bg-black p-[12px] text-white font-bold text-[16px] rounded-full w-[92%] text-center">
                  Create a profile!
                </p>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareFeature;
