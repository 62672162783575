import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import Slider from "@mui/material/Slider";
import AudioPlayer from "../../components/CurrentPlaying/MobileLyrics/AudioPlayerr";
import playIcon from "../../assets/images/playIcon.svg";
import pauseIcon from "../../assets/images/pauseIcon.svg";
import shareIcon from "../../assets/images/share.svg";
import fillSave from "../../assets/images/filled-white.svg";
import unFilledSave from "../../assets/images/outlined-white.svg";
import forward from "../../assets/images/forward-10-seconds-white.svg";
import backward from "../../assets/images/rewind-10-seconds-white.svg";
// import animation from "../../assets/images/star_animation.gif";

const SmallHighlightWordComponent = ({
  index,
  boost,
  setCurrentIndex,
  currentIndex,
  handleRemoveSave,
}) => {
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState("0:00");
  const [animationClass, setAnimationClass] = useState("");
  const token = localStorage.getItem("accessToken");
  const [playedTime, setPlayedTime] = useState(0);
  const [isNumberPopUp, setIsNumberPopUp] = useState(false);

  const [musicPlayed, setMusicPlayed] = useState("0:00");
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef(new Audio());

  const getWeekLeft = (createdOn) => {
    const today = new Date(createdOn);
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
    const weekNumber = Math.ceil((today - startOfYear) / millisecondsInWeek);
    return weekNumber;
  };

  const weekNumber = getWeekLeft(boost?.createdOn);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.pause();
      setIsPlaying(false);
      setPlaying(false);
      player.currentTime = 0;
      if (currentIndex === index) {
        player.play();
        setIsPlaying(true);
        setPlaying(true);
      }
    }
  }, [currentIndex, index]);

  const getPlayedTimeBoostHere = async () => {
    if (boost) {
      const response = await axios.get(
        `${apiEndPoints.getPlayedTimeBoost}/${boost?._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.responseStatus === 1) {
        setPlayedTime(response.data.response.playedX);
      } else {
        setPlayedTime(0);
      }
    }
  };

  useEffect(() => {
    getPlayedTimeBoostHere();
  }, [boost]);

  const handlePlay = async () => {
    // setCurrentIndex(index);
    const player = playerRef.current;
    if (player === document.getElementById(`saved-audio-boost-${boost._id}`)) {
      setIsPlaying(true);
      setPlaying(true);
      if (typeof player.play === "function") {
        player.play();
      }

      setCurrentIndex(index);
      if (currentIndex !== index) {
        await axios.get(`${apiEndPoints.addCountPlayBoost}/${boost?._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTimeout(() => {
          getPlayedTimeBoostHere();
        }, 1000);
        setAnimationClass("animate__animated animate__bounceIn");
        setTimeout(() => {
          setAnimationClass("");
        }, 1000);
        setTimeout(() => {
          setIsNumberPopUp(true);
          // getAllsavedBoost();
          setTimeout(() => {
            setIsNumberPopUp(false);
          }, 1000);
        }, 1000);
      }
    } else {
      player.pause();
      setIsPlaying(false);
      setPlaying(false);
      player.currentTime = 0;
    }

    if (!player) {
      return;
    }
  };

  const handlePause = () => {
    if (playerRef.current) {
      setIsPlaying(false);
      setPlaying(false);
      const player = playerRef.current;
      if (typeof player.pause === "function") {
        player.pause();
      }
    }
  };

  const handleSave = async () => {
    if (boost?._id) {
      const payload = {
        boostId: boost?._id,
      };
      const res = await axios.post(apiEndPoints.saveBoost, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  const handleForwards = () => {
    const player = playerRef.current;
    if (player) {
      player.currentTime = player.currentTime + 10;
    }
  };

  const handleRewinds = () => {
    const player = playerRef.current;
    if (player) {
      player.currentTime = player.currentTime - 10;
    }
  };

  const handleShare = async (boostID) => {
    if (navigator.share) {
      try {
        await navigator.share({
          text: `${process.env.REACT_APP_LIVE}/share/${boostID}`,
        });
      } catch (error) {
        console.error("Error sharing audio:", error);
      }
    } else {
      console.log("Web Share API is not supported on this browser.");
    }
  };

  useEffect(() => {
    let audioEle = document.getElementById(`saved-audio-boost-${boost._id}`);
    if (audioEle) {
      const player = playerRef.current;
      player?.addEventListener("timeupdate", () => {
        const durationInSeconds = player.currentTime;
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        setMusicPlayed(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      });
    }
  }, [playerRef.current]);

  return (
    <div
      key={boost._id}
      className="flex flex-col flex-shrink-0 w-full bg-black justify-center items-center relative rounded-[19.57px] min-h-[175px]"
    >
      <div className="py-[2%] px-[3%] w-full">
        <p className="text-[15px] font-bold text-center text-white">
          Week {weekNumber} out of 52 | {52 - weekNumber}{" "}
          {52 - weekNumber === 0 ? "week" : "weeks"} left
        </p>
      </div>
      <div className="w-[90%] flex items-center gap-[5px] flex-shrink-0">
        <div className="hightlight-text-container py-[14px] grow item-stretch mx-[2%] rounded-t-[20px] flex flex-col justify-center leading-10 bg-black text-white h-[10rem]">
          {boost && (
            <AudioPlayer
              playerRef={playerRef}
              playing={isPlaying}
              setPlaying={setIsPlaying}
              setDuration={setAudioDuration}
              setProgress={setProgress}
              setIsPlay={setIsPlaying}
              setIsPlayingOnce={() => {}}
              isShare={false}
              audioFile={boost?.audioUrl}
              ssmlText={boost?.text}
              speechMarks={boost?.speechMarks[0]}
              isSavedPage={true}
              id={`saved-audio-boost-${boost._id}`}
            />
          )}
        </div>
      </div>
      <div className="w-[80%] mx-auto">
        <Slider
          step={1}
          style={{ paddingBottom: "0px", width: "100%" }}
          color="primary"
          value={progress}
          min={0}
          max={100}
          // onChange={handleChangeSeekBar}
          sx={{
            color: "white",
          }}
        />
      </div>
      <div className="flex items-center justify-between mt-1 w-[80%] mx-auto text-white">
        <p className="text-xs">{musicPlayed}</p>
        <p className="text-[12px]">{audioDuration ? audioDuration : "0:00"}</p>
      </div>

      <div className="flex w-[92%] justify-between mx-auto items-center mb-5 relative">
        <div>
          {boost?.isSaved ? (
            <div onClick={() => handleRemoveSave(boost._id)}>
              <img
                src={fillSave}
                alt="fillSaveIcon"
                className="h-[33.79px] w-[33.79px]"
              />
            </div>
          ) : (
            <div onClick={handleSave}>
              <img
                src={unFilledSave}
                alt="unsaveIcon"
                className="h-[33.79px] w-[33.79px]"
              />
            </div>
          )}
        </div>
        <img
          className="h-[36px] w-[36px]"
          onClick={handleRewinds}
          src={backward}
        />
        {/* <div className="flex gap-2 items-end"> */}
        <div className="relative">
          <div className="flex items-center justify-center flex-shrink-0">
            {!isPlaying && (
              <img
                src={playIcon}
                alt="play icon"
                onClick={handlePlay}
                className="pl-[5px] w-[40px] h-[40px]"
              />
            )}
            {isPlaying && (
              // <HiPause color="black" size={30} onClick={handlePause} />
              <img
                src={pauseIcon}
                alt="pause icon"
                onClick={handlePause}
                className="w-[40px] h-[40px]"
              />
            )}
          </div>
          <div
            className={`absolute right-[-26px] top-[-8px] flex flex-col justify-center items-center ${
              isNumberPopUp && "pulse"
            }`}
          >
            <div>
              <p className="text-[0.75] relative text-white">{playedTime}x</p>
            </div>
          </div>
        </div>
        <img
          className="h-[36px] w-[36px]"
          onClick={handleForwards}
          src={forward}
        />
        <div onClick={() => handleShare(boost._id)}>
          {/* <GoShare color="black" size={"35"} /> */}
          <img src={shareIcon} alt="shareIcon" className="w-[30px] h-[30px]" />
        </div>
      </div>
    </div>
  );
};

export default SmallHighlightWordComponent;
