import { PropsWithChildren } from "react";
import logoSrc from "../../assets/images/daily-mentor-logo.svg";

interface Props {
  heading: string;
}

const AuthenticationHeader = (props: PropsWithChildren<Props>) => {
  return (
    <>
      <div className="flex justify-center sm:block  mt-9  sm:text-start sm:pl-16.5 pt-10 sm:pt-19 font-cormorant text-web-secondary text-2xl mb-8">
        <img className="w-[200px]" src={logoSrc} alt="logo" />
      </div>
      <h2 className="text-center sm:text-start text-web-secondary text-2xl sm:pl-16.5 font-bold mb-8">
      </h2>
      {props.children}
    </>
  );
};

export default AuthenticationHeader;
