import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface DrawerState {
    value: boolean;
}
const initialState: DrawerState = {
    value: false,
}

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.value = true;
    },
    closeDrawer:(state) => {
        state.value = false;
    }
  },
});

// export const boostSelector = (state: RootState) => state.boosts.boosts;
export const drawerSelector = (state: RootState) => state.drawer.value;
export const {openDrawer, closeDrawer} = drawerSlice.actions;
export default drawerSlice.reducer;
