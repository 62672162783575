import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
// import { indexSelector } from "../../redux/slices/indexSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ToggleSwitch from "../../components/MobileHeader/ToggleSwitch";
import { screenWidthSelector } from "../../redux/slices/screenSlice";
import { updateArray } from "../../redux/slices/healthTagsSlice";
import { InstallAndroids } from "../../components/CurrentPlaying/MobileLyrics/MobilePromt";
import MobileLyricsCopy from "../../components/CurrentPlaying/MobileLyrics/MobileLyricsCopy";

const DynamicPlayer = () => {
  const dispatch = useAppDispatch();
  const screenWidth = useAppSelector(screenWidthSelector);

  dispatch(updateArray([]));

  return (
    <>
      {
        <div className="flex flex-col bg-mobile-primary min-h-dvh max-h-dvh text-black w-full overflow-y-scroll lg:overflow-y-hidden">
          {screenWidth < 1025 && <MobileHeader />}
          <div className="flex justify-center px-3 lg:px-[70px]">
            <ToggleSwitch />
            <InstallAndroids />
          </div>
          {/* <MobileLyrics /> */}
          <MobileLyricsCopy />
          {/* <div className="flex justify-center p-[3%]">{screenWidth < 1025 && <MobileNavbar />}</div> */}
        </div>
      }
    </>
  );
};

export default DynamicPlayer;
