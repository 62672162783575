import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AudioPlayer = ({
  playerRef,
  setPlaying,
  setDuration,
  setProgress,
  setIsPlayingOnce,
  setIsPlay,
  isShare,
  audioFile,
  ssmlText,
  speechMarks,
  isSavedPage,
  id,
}) => {
  const location = useLocation();
  const getSpeechMarkAtTime = (speechMarks, time) => {
    let match = speechMarks[0];
    let found = false;
    let i = 1;

    while (i < speechMarks.length && !found) {
      if (speechMarks[i].time <= time) {
        match = speechMarks[i];
      } else {
        found = true;
      }
      i++;
    }
    return match;
  };

  const removeSsmlTags = (ssmlText) => {
    const cleanText = new DOMParser()
      .parseFromString(ssmlText, "text/html")
      .body.textContent.replaceAll("\n", "")
      .replace(/\s+/g, " ")
      .trim();

    return cleanText;
  };

  // generating the json array from the speechmark text
  const lines = speechMarks.split("\n");

  let speechmarkJson = [];
  if (!lines[lines.length - 1]) {
    lines.pop();
  }
  for (let line of lines) {
    const obj = JSON.parse(line);
    speechmarkJson.push(obj);
  }

  // highlighting the words based on the selected speechmark
  const highlightText = (currentSpeechMark, reset = false) => {
    if (playerRef.current.paused && !reset && location.pathname === "/audioSave") return;

    let cleanedTextEle = document.getElementById(`${id}-cleanedTextEle`);

    let ssmlTextStart = ssmlText.substring(0, currentSpeechMark.end);
    let ssmlTextEnd = ssmlText.substring(currentSpeechMark.end);

    let cleanSsmlTextStart = new DOMParser()
      .parseFromString(ssmlTextStart, "text/html")
      .body.textContent.replaceAll("\n", " ")
      .replace(/\<.*$/, "")
      .replace(/\s+/g, " ")
      .trim();
    let cleanSsmlTextEnd = new DOMParser()
      .parseFromString(ssmlTextEnd, "text/html")
      .body.textContent.replaceAll("\n", " ")
      .replace(/^.*\>/, "")
      .replace(/\s+/g, " ")
      .trim();

    let isSpaceNeeded =
      cleanSsmlTextEnd.charAt(0).toLowerCase() ==
      cleanSsmlTextEnd.charAt(0).toUpperCase()
        ? ""
        : " ";

    cleanedTextEle.innerHTML = `<span name="highlight" class="with-highlight">${cleanSsmlTextStart}</span><span id="${id}-highlight-boundary" style="scroll-snap-align: start;"></span>${isSpaceNeeded}${cleanSsmlTextEnd}`;

    document
      .getElementById(`${id}-highlight-boundary`)
      .scrollIntoView({ block: "center" });
  };

  useEffect(() => {
    let audioEle = document.getElementById(id);
    const durationInSeconds = audioEle.duration;
    if (durationInSeconds) {
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = Math.floor(durationInSeconds % 60);
      setDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
    }

    if (audioEle) {
      // callback for getting current time from the audio player
      audioEle?.addEventListener("timeupdate", () => {
        const currentTime = audioEle?.currentTime;

        const progressPercentage = Math.round(
          (currentTime / durationInSeconds) * 100
        );
        setProgress(progressPercentage);
        const currentSpeechMark = getSpeechMarkAtTime(
          speechmarkJson,
          Number((currentTime * 1000).toFixed(0)) // convert to miliseconds
        );
        return highlightText(currentSpeechMark);
      });

      audioEle?.addEventListener("ended", () => {
        if (!isShare) {
          setPlaying(false);
          setIsPlayingOnce(false);
        }
        audioEle.currentTime = 0;
        setIsPlay(false);
        const currentSpeechMark = getSpeechMarkAtTime(
          speechmarkJson,
          0 // convert to miliseconds
        );
        return highlightText(currentSpeechMark, true);
      });
    }
  }, [playerRef, speechMarks, highlightText]);

  return (
    <div style={{ overflowX: "auto", maxWidth: "100%", overflow: "hidden" }}>
      <div>
        <p
          style={{
            fontSize: `${isSavedPage ? "1.5rem" : "1.75rem"}`,
            color: "#575757",
            lineHeight: `${isSavedPage ? "2rem" : "2.363rem"}`,
            scrollSnapType: "y mandatory",
          }}
          id={`${id}-cleanedTextEle`}
        >
          {removeSsmlTags(ssmlText)}
        </p>
      </div>
      <div>
        <audio id={id} ref={playerRef} controls style={{ display: "none" }}>
          <source src={audioFile} type="audio/mp3" />
        </audio>
      </div>
    </div>
  );
};

export default AudioPlayer;
