import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import axios from "axios";

export interface BoostsObject {
  _id: string;
  text: string;
  audioUrl: string;
  imageUrl: Array<string>;
  isUserBoost: boolean;
  boostCount: number;
}

export interface CustomState {
  responseStatus: number;
  boosts: BoostsObject;
}

export interface BoostsState {
  boosts: BoostsObject;
}

export interface InitialState {
  boosts: null;
}

const initialState: CustomState | InitialState = {
  boosts: null,
};

export const fetchBoosts = createAsyncThunk("boosts/fetch", async () => {
  const token = localStorage.getItem("accessToken");
  const response = await axios.get(apiEndPoints.getAllBoosts, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const boostsSlice = createSlice({
  name: "boosts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBoosts.fulfilled, (state, action) => {
      //action.payload is whatever data was returned from fetchBoosts.
      const data = action.payload;
      state.boosts = data;
    });
  },
});

export const boostSelector = (state: RootState) => {
  return state.boosts.boosts;
};
// export const indexSelector = (state: RootState) => state.boosts.currentIndex;
export default boostsSlice.reducer;
