import {
  CallbackFunction,
  DispatchCallbackFunction,
  ForgotPasswordData,
  LoginData,
  SignupData,
  SignupOtpData,
  UpdatePasswordData,
  Void,
} from "../../interfaces/interfaces";
import { authorizeUser } from "../../redux/slices/authSlice";
import { toast } from 'react-toastify';

export const callApi = async (
  navigate: CallbackFunction,
  dispatch: DispatchCallbackFunction,
  setError: CallbackFunction,
  data: SignupOtpData | UpdatePasswordData,
  url: string,
  route: string,
  setToggle: Void
) => {
  try {
    setToggle(true);
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.responseStatus === 1) {
      toast.success("Submission successful!");
      if (route === "/about") {
        dispatch(authorizeUser());
        localStorage.setItem("accessToken", res.accessToken);
      }
      navigate(route);
    } else {
      setToggle(false);
      // setError(res.error);
      toast.error(res.error);
    }
  } catch (err: any) {
    setToggle(false);
    toast.error(err)
    // setError("Something went wrong!");
  } finally {
    setToggle(false);
  }
};

export const signupApiCall = async (
  navigate: CallbackFunction,
  setError: CallbackFunction,
  data: SignupData | ForgotPasswordData,
  url: string,
  route: string,
  setToggle: Void
) => {
  try {
    setToggle(true);
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.responseStatus === 1) {
      toast.success("OTP was emailed successfully!");
      navigate(route + `/${res.userId}`);
    } else {
      setToggle(false);
      // setError(res.error);
      toast.error(res.error);
    }
  } catch (err) {
    setToggle(false);
    // setError("Something went wrong!");
    toast.error("Something went wrong!")
  } finally {
    setToggle(false);
  }
};

export const loginApiCall = async (
  dispatch: DispatchCallbackFunction,
  navigate: CallbackFunction,
  setError: CallbackFunction,
  data: LoginData,
  url: string,
  route: string,
  setToggle: Void
) => {
  try {
    setToggle(true);
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.responseStatus === 1) {
      if (res?.userDetails?.isActive) {
        localStorage.setItem("accessToken", res.userDetails.accessToken);
        dispatch(authorizeUser());
        toast.success("Successfully logged in!")
        navigate(route);
      } else {
        toast.error("User does not exist")
      }
    } else {
      setToggle(false);
      // setError(res.error);
      toast.error(res.error);
    }
  } catch (err) {
    setToggle(false);
    // setError("Something went wrong!");
    toast.error("Something went wrong!");
  } finally {
    setToggle(false);
  }
};
