import authReducer from "../slices/authSlice";
import { configureStore } from "@reduxjs/toolkit";
import { boostsSlice } from "../slices/boostSlice";
import indexReducer from "../slices/indexSlice";
import screenWidthReducer from "../slices/screenSlice";
import drawerReducer from "../slices/drawerSlice";
import mobileMediaReducer from "../slices/mobileMediaSlice";
import healthTagReducer from "../slices/healthTagsSlice";
import toggleTimeSlice from "../slices/toggleTimeSlice";

const store = configureStore({
  reducer: {
    userAuth: authReducer,
    boosts: boostsSlice.reducer,
    index: indexReducer,
    screen: screenWidthReducer,
    drawer: drawerReducer,
    mobileMedia: mobileMediaReducer,
    healthTag: healthTagReducer,
    toggleSetTime: toggleTimeSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
