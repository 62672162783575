import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

interface toggleTimeState {
  selectedDays: any;
}
const initialState: toggleTimeState = {
  selectedDays: [],
}

export const addToggleTimeData = createAsyncThunk("addToggle", async (toggleTime: any) => {
  const token = localStorage.getItem("accessToken")
  const response = await axios.post(apiEndPoints.addToggleTime, toggleTime, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data
});

export const editToggleTime = createAsyncThunk("editToggleTime", async (toggleTimes: any) => {
  const token = localStorage.getItem("accessToken")
  const response = await axios.put(`${apiEndPoints.editToggleTime}`, toggleTimes, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data
});

export const getAllToggleTime = createAsyncThunk("getToggleTime", async () => {
  const token = localStorage.getItem("accessToken");
  const response = await axios.get(apiEndPoints.getAllToggleTime, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data
})

export const toggleTimeSlice = createSlice({
  name: "toggleTime",
  initialState,
  reducers: {
    toggleTimeSet: (state, action) => {
      state.selectedDays = [...state.selectedDays, { days: action.payload.selectedDays, time: action.payload.selectedTime, isActive: true }];
    },
    toggleTimeEdit: (state, action) => {
      const { index, selectedDays, selectedTime } = action.payload;
      state.selectedDays[index] = { days: selectedDays, time: selectedTime, isActive: true };
    },
    isActiveAlarm: (state, action) => {
      const { index, isActive } = action.payload;
      state.selectedDays[index] = { days: state.selectedDays[index].days, time: state.selectedDays[index].time, isActive: isActive }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addToggleTimeData.pending, (state) => {

    });
    builder.addCase(addToggleTimeData.fulfilled, (state, action) => {
      toast.success("Your time is successfully saved")
    });

    builder.addCase(addToggleTimeData.rejected, (state, action) => {
      toast.error("Something went wrong")
    });

    builder.addCase(getAllToggleTime.fulfilled, (state, action) => {
      const response = action.payload.response;
      state.selectedDays = response.sort((a: any, b: any) => {
        const timeA = new Date(a.time).toLocaleTimeString('en-US', { hour12: false });
        const timeB = new Date(b.time).toLocaleTimeString('en-US', { hour12: false });

        return moment(timeA, "HH:mm:ss").diff(moment(timeB, "HH:mm:ss"));
      })

    });

    builder.addCase(getAllToggleTime.rejected, (state, action) => {
      toast.error("Something went wrong")
    });

  }
});

// export const boostSelector = (state: RootState) => state.boosts.boosts;
export const toggleTimeSelector = (state: RootState) => state.toggleSetTime;
export const { toggleTimeSet, toggleTimeEdit, isActiveAlarm } = toggleTimeSlice.actions;
export default toggleTimeSlice.reducer;
