import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const TermsOfUse = () => {
  const navigation = useNavigate();

  return (
    <div className="container mx-auto p-3 text-black bg-mobile-primary">
      <div
        onClick={() => {
          navigation("/signup");
        }}
      >
        <IoIosArrowBack color="black" size={30} />
      </div>
      {/* Agreement To Terms */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Agreement To Terms</h2>
        <p className="mb-4">
          The terms “we,” “us,” and “our” refer to CRFCO LLC (dba The
          Dailysparq). The term the “Site” refers to dailysparq.com. The terms
          “user,” “you,” and “your” refer to site visitors, customers, and any
          other users of the site. These Terms of Use constitute a legally
          binding agreement made between you, whether personally or on behalf of
          an entity (“you”) and The Dailysparq, concerning your access to and
          use of the https://dailysparq.com/ website as well as any other media
          form, media channel, mobile website or mobile application related,
          linked, or otherwise connected thereto (collectively, the “Site”). You
          agree that by accessing the Site, you have read, understood, and
          agreed to be bound by all of these Terms of Use. Supplemental terms
          and conditions or documents that may be posted on the Site from time
          to time are hereby expressly incorporated herein by reference. We
          reserve the right, in our sole discretion, to make changes or
          modifications to these Terms of Use at any time and for any reason. We
          will alert you about any changes by updating the “Last updated” date
          of these Terms of Use, and you waive any right to receive specific
          notice of each such change. It is your responsibility to periodically
          review these Terms of Use to stay informed of updates. You will be
          subject to, and will be deemed to have been made aware of and to have
          accepted, the changes in any revised Terms of Use by your continued
          use of the Site after the date such revised Terms of Use are posted.
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.
        </p>
        {/* ... (Continue the content with appropriate Tailwind classes) */}
      </section>

      {/* Intellectual Property Rights */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">
          Intellectual Property Rights
        </h2>
        <p className="mb-4">
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of the United States, international copyright laws, and international
          conventions. The Content and the Marks are provided on the Site “AS
          IS” for your information and personal use only. Except as expressly
          provided in these Terms of Use, no part of the Site and no Content or
          Marks may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial
          purpose whatsoever, without our express prior written permission.
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </p>
        {/* ... (Continue the content with appropriate Tailwind classes) */}
      </section>

      {/* CONSIDERATION */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">CONSIDERATION</h2>
        <p className="mb-4">
          You agree that these Terms of Use are supported by reasonable and
          valuable consideration, the receipt and adequacy of which you hereby
          acknowledge, including, without limitation, your access to and use of
          the Website and data, materials and information available at or
          through the Website.
        </p>
        {/* ... (Continue the content with appropriate Tailwind classes) */}
      </section>

      {/* User Representations */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">User Representations</h2>
        <p className="mb-4">
          By using the Site, you represent and warrant that: (1) you have the
          legal capacity and you agree to comply with these Terms of Use; (2)
          you are not a minor in the jurisdiction in which you reside; (3) you
          will not access the Site through automated or non-human means, whether
          through a bot, script or otherwise; (4) you will not use the Site for
          any illegal or unauthorized purpose; and (5) your use of the Site will
          not violate any applicable law or regulation. If you provide any
          information that is untrue, inaccurate, not current, or incomplete, we
          have the right to suspend or terminate your account and refuse any and
          all current or future use of the Site (or any portion thereof).
        </p>
        {/* ... (Continue the content with appropriate Tailwind classes) */}
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">
          Our Content; Limitation of Liability
        </h2>
        <p className="mb-4">
          The materials on The Dailysparq’s website are provided on an 'as is'
          basis. The Dailysparq makes no warranties, express or implied, and
          hereby disclaims and negates all other warranties including, without
          limitation, implied warranties or conditions or non-infringement of
          intellectual property or other violation of rights. Any figures,
          numbers or other testimonies found in the site should not be construed
          as promises that you will obtain similar results. While we strive to
          match you with the right personal motivational messages and audios,
          each approach is different, and not every motivational message and
          audio is a perfect fit. We do not warrant any particular health,
          intellectual, or financial outcomes. Further, while we strive to
          provide a realistic interpretation of your personal potential but you
          are responsible for your own actions and decisions, and outcomes may
          vary based on personal choices and circumstances. IN NO EVENT OR UNDER
          ANY CIRCUMSTANCE SHALL WE BE HELD LIABLE TO YOU (OR TO ANY THIRD PARTY
          CLAIMING UNDER OR THROUGH YOU) FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          OR CONSEQUENTIAL DAMAGES ARISING FROM USE OF OUR SERVICES OR INABILITY
          TO USE OUR SERVICES. IN ALL CASES, WE WILL NOT BE LIABLE FOR ANY LOSS
          OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE. IN ANY EVENT THE LIMIT
          OF OUR LIABILITY FOR ANY LOSS, DAMAGE, CLAIM, OR EXPENSE IS $100.
        </p>
        {/* ... (Continue the content with appropriate Tailwind classes) */}
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2"> Purchases and Payment</h2>
        <p className="mb-4">
          We accept the following forms of payment: Visa, Mastercard, American
          Express and Discover. You agree to provide current, complete, and
          accurate purchase and account information for all purchases made via
          the Site. You further agree to promptly update account and payment
          information, including email address, payment method, and payment card
          expiration date, so that we can complete your transactions and contact
          you as needed. Sales tax will be added to the price of purchases as
          deemed required by us. We may change prices at any time. All payments
          shall be in U.S. dollars. If your order is subject to recurring
          charges, then you consent to our charging your payment method on a
          recurring basis without requiring your prior approval for each
          recurring charge, until such time as you cancel the applicable order.
          We reserve the right to correct any errors or mistakes in pricing,
          even if we have already requested or received payment. We reserve the
          right to refuse any order placed through the Site.
        </p>
        {/* ... (Continue the content with appropriate Tailwind classes) */}
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Refund Policy</h2>
        <p className="mb-4">
          Please review our Return Policy posted on the Site prior to making any
          purchases.
        </p>
        {/* ... (Continue the content with appropriate Tailwind classes) */}
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Prohibited Activities</h2>
        <p className="mb-4">
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>

        <p className="mb-4">As a user of the Site, you agree not to:</p>

        <ol className="list-decimal pl-6 mb-4">
          <li>
            Systematically retrieve data or other content from the Site to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Make any unauthorized use of the Site, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li>
            Use a buying agent or purchasing agent to make purchases on the
            Site.
          </li>
          <li>
            Use the Site to advertise or offer to sell goods and services.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use
            of the Site and/or the Content contained therein.
          </li>
          <li>Engage in unauthorized framing of or linking to the Site.</li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>Sell or otherwise transfer your profile.</li>
          <li>
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Use the Site as part of any effort to compete with us or otherwise
            use the Site and/or the Content for any revenue-generating endeavor
            or commercial enterprise.
          </li>
          <li>
            Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Site.
          </li>
          <li>
            Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Copy or adapt the Site’s software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Site or modifies, impairs, disrupts, alters, or interferes with the
            use, features, functions, operation, or maintenance of the Site.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
          </li>
          <li>
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li>
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
        </ol>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">The Dailysparq Membership</h2>
        <p className="mb-4">
          The Dailysparq provides a monthly membership program that grants you
          daily motivational messages, audios, and visuals with the aid of AI on
          The Dailysparq website.
        </p>
        <p className="mb-4">
          If you choose to create an Account, you agree to provide and maintain
          true, accurate, current, and complete information about yourself as
          prompted by The Dailysparq registration form. Registration data and
          certain other information about you are governed by our Privacy
          Policy.
        </p>
        <p className="mb-4">
          To become a The Dailysparq Member, simply sign up on The Dailysparq
          website or through a The Dailysparq exclusive offer, and you will be
          automatically enrolled in The Dailysparq Membership Program (including
          the monthly, automatic purchase feature).
        </p>

        <p className="mb-4">
          If you would like to cancel your The Dailysparq Membership at any
          time, you can:
        </p>
        <ol className="list-decimal pl-6 mb-4">
          <li>
            Email{" "}
            <a href="mailto:support@dailysparq.com">support@dailysparq.com</a>
          </li>
          <li>
            Fill out the{" "}
            <a href="https://dailysparq.com/cancel-membership-form">
              Cancel Membership Form here
            </a>
          </li>
        </ol>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Membership Perks</h2>
        <p className="mb-4">
          As a member, you will have access to daily motivational messages,
          audios, and visuals with the aid of AI. The Dailysparq is always
          striving to create the best experience for members so additional
          benefits and perks may be added.
        </p>

        <h2 className="text-2xl font-bold mb-2">Purchase Cancellations</h2>
        <p className="mb-4">
          All memberships placed through any The Dailysparq Services are subject
          to The Dailysparq’s acceptance. This means that The Dailysparq may
          refuse to accept or may cancel any membership, whether or not the
          membership has been confirmed, for any or no reason, and without
          liability to you or anyone else.
        </p>
        <p className="mb-4">
          If your credit card has already been charged for an order that is
          later cancelled, The Dailysparq will issue you a refund.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">
          User Generated Contributions
        </h2>
        <p className="mb-4">
          The Site offers users the opportunity to submit or post content to
          their profile. You can create, submit, post, display, transmit,
          perform, publish, or broadcast various types of content and materials,
          including but not limited to text, writings, photographs, graphics,
          comments, or personal information (collectively, “Contributions”).
        </p>

        {/* Contributions Representation and Warranties */}
        <ol className="list-disc pl-6 mb-4">
          <li>
            The creation, transmission, public display, or performance, and the
            accessing, or copying of your Contributions do not infringe the
            proprietary rights of any third party, including copyright, patent,
            trademark, trade secret, or moral rights.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and authorize us,
            the Site, and other users to use your Contributions.
          </li>
          <li>
            You have the written consent, release, and/or permission of each
            identifiable individual person in your Contributions to use their
            name or likeness.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise
            objectionable.
          </li>
          <li>
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            Your Contributions do not advocate the violent overthrow of any
            government or incite, encourage, or threaten physical harm against
            another.
          </li>
          <li>
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            Your Contributions do not solicit personal information from anyone
            under the age of 18 or exploit people under the age of 18 in a
            sexual or violent manner.
          </li>
          <li>
            Your Contributions do not violate any applicable law concerning
            child pornography or intended to protect the health or well-being of
            minors.
          </li>
          <li>
            Your Contributions do not include any offensive comments connected
            to race, national origin, gender, sexual preference, or physical
            handicap.
          </li>
          <li>
            Your Contributions do not violate or link to material that violates
            any provision of these Terms of Use or any applicable law or
            regulation.
          </li>
        </ol>

        {/* Violation Warning */}
        <p className="mb-4">
          Any use of the Site or the Marketplace Offerings in violation of the
          foregoing violates these Terms of Use and may result in, among other
          things, termination or suspension of your rights to use the Site and
          the Marketplace Offerings.
        </p>
      </section>

      {/* Contribution License */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Contribution License</h2>
        <p className="mb-4">
          You and the Site agree that we may access, store, process, and use any
          information and personal data that you provide following the terms of
          the Privacy Policy and your choices (including settings).
        </p>
        <p className="mb-4">
          By submitting suggestions or other feedback regarding the Site, you
          agree that we can use and share such feedback for any purpose without
          compensation to you. We do not assert any ownership over your
          Contributions. You retain full ownership of all your Contributions and
          any intellectual property rights or other proprietary rights
          associated with your Contributions. We are not liable for any
          statements or representations in your Contributions provided by you on
          the Site. You are solely responsible for your Contributions to the
          Site, and you expressly agree to exonerate us from any and all
          responsibility and to refrain from any legal action against us
          regarding your Contributions.
        </p>
      </section>

      {/* Guidelines For Reviews */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Guidelines For Reviews</h2>
        <p className="mb-4">
          We may provide you areas on the Site to leave reviews or ratings. When
          posting a review, you must comply with the following criteria:
        </p>
        <ol className="list-decimal pl-6 mb-4">
          <li>
            You should have firsthand experience with the person/entity being
            reviewed.
          </li>
          <li>
            Your reviews should not contain offensive profanity, or abusive,
            racist, offensive, or hate language.
          </li>
          <li>
            Your reviews should not contain discriminatory references based on
            religion, race, gender, national origin, age, marital status, sexual
            orientation, or disability.
          </li>
          <li>
            Your reviews should not contain references to illegal activity.
          </li>
          <li>
            You should not be affiliated with competitors if posting negative
            reviews.
          </li>
          <li>
            You should not make any conclusions as to the legality of conduct.
          </li>
          <li>You may not post any false or misleading statements.</li>
          <li>
            You may not organize a campaign encouraging others to post reviews,
            whether positive or negative.
          </li>
        </ol>
        <p className="mb-4">
          We may accept, reject, or remove reviews in our sole discretion. We
          have absolutely no obligation to screen reviews or to delete reviews,
          even if anyone considers reviews objectionable or inaccurate. Reviews
          are not endorsed by us and do not necessarily represent our opinions
          or the views of any of our affiliates or partners. We do not assume
          liability for any review or for any claims, liabilities, or losses
          resulting from any review. By posting a review, you hereby grant to us
          a perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
          assignable, and sublicensable right and license to reproduce, modify,
          translate, transmit by any means, display, perform, and/or distribute
          all content relating to reviews.
        </p>
      </section>

      {/* Submissions */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Submissions</h2>
        <p className="mb-4">
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          (“Submissions”) provided by you to us are non-confidential and shall
          become our sole property.
        </p>
        <p className="mb-4">
          We shall own exclusive rights, including all intellectual property
          rights, and shall be entitled to the unrestricted use and
          dissemination of these Submissions for any lawful purpose, commercial
          or otherwise, without acknowledgment or compensation to you. You
          hereby waive all moral rights to any such Submissions, and you hereby
          warrant that any such Submissions are original with you or that you
          have the right to submit such Submissions. You agree there shall be no
          recourse against us for any alleged or actual infringement or
          misappropriation of any proprietary right in your Submissions.
        </p>
      </section>
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Site Management</h2>
        <ol className="list-disc pl-6">
          <li>Monitor the Site for violations of these Terms of Use;</li>
          <li>
            Take appropriate legal action against anyone who violates the law or
            these Terms of Use;
          </li>
          <li>
            Refuse, restrict access to, limit availability of, or disable any of
            your Contributions;
          </li>
          <li>
            Remove from the Site or disable files and content that are excessive
            in size or burdensome to our systems;
          </li>
          <li>
            Manage the Site to protect our rights and property and facilitate
            proper functioning.
          </li>
        </ol>
      </div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">PRIVACY AND YOUR ACCOUNT</h2>
        <p>
          We care about data privacy and security. We may sell products for
          children, but sell them to adults who can purchase with a credit card
          or other permitted payment method. If you are under 18, you may use
          the Website only with the involvement of a parent or guardian. We
          reserve the right to refuse service, terminate accounts, remove or
          edit content, or cancel orders in our sole discretion.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Term and Termination</h2>
        <p>
          These Terms of Use shall remain in full force and effect while you use
          the Site.
          <br />
          WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE
          THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
          DENY ACCESS TO AND USE OF THE SITE AND THE MARKETPLACE OFFERINGS
          (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
          REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
          ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
          USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE
          OR PARTICIPATION IN THE SITE AND THE MARKETPLACE OFFERINGS OR DELETE
          ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
          WARNING, IN OUR SOLE DISCRETION.
          <br />
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">
          Modifications and Interruptions
        </h2>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We will not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the Site.
          <br />
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site during any downtime or
          discontinuance of the Site. Nothing in these Terms of Use will be
          construed to obligate us to maintain and support the Site or to supply
          any corrections, updates, or releases in connection therewith.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Governing Law</h2>
        <p>
          These Terms of Use and your use of the Site and the Marketplace
          Offerings are governed by and construed in accordance with the laws of
          the State of New Mexico applicable to agreements made and to be
          entirely performed within the State of New Mexico, without regard to
          its conflict of law principles.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) use of the Site;
          (2) breach of these Terms of Use; (3) any breach of your
          representations and warranties set forth in these Terms of Use; (4)
          your violation of the rights of a third party, including but not
          limited to intellectual property rights; or (5) any overt harmful act
          toward any other user of the Site with whom you connected via the
          Site. Notwithstanding the foregoing, we reserve the right, at your
          expense, to assume the exclusive defense and control of any matter for
          which you are required to indemnify us, and you agree to cooperate, at
          your expense, with our defense of such claims. We will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.
        </p>
      </div>

      <div className="mb-4">
        <p className="font-bold text-lg mb-2">Informal Negotiations</p>
        <p className="mb-2">
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Terms of Use (each a “Dispute”
          and collectively, the “Disputes”), the Parties (you or us) agree to
          first attempt to negotiate any Dispute informally for at least thirty
          (30) days before initiating arbitration. Such informal negotiations
          commence upon written notice from one Party to the other Party.
        </p>
      </div>
      <div className="mb-4">
        <p className="font-bold text-lg mb-2">Binding Arbitration</p>
        <p className="mb-2">
          If the Parties are unable to resolve a Dispute through informal
          negotiations, the Dispute (except those Disputes expressly excluded
          below) will be final and exclusively resolved by binding arbitration.
          YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT
          TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be
          commenced and conducted under the Commercial Arbitration Rules of the
          American Arbitration Association (“AAA”) and, where appropriate, the
          AAA’s Supplementary Procedures for Consumer Related Disputes (“AAA
          Consumer Rules”), both of which are available at the AAA website
          www.adr.org. Your arbitration fees and your share of arbitrator
          compensation shall be governed by the AAA Consumer Rules and, where
          appropriate, limited by the AAA Consumer Rules. The arbitration may be
          conducted in person, through the submission of documents, by phone, or
          online. The arbitrator will make a decision in writing, but need not
          provide a statement of reasons unless requested by either Party. The
          arbitrator must follow applicable law, and any award may be challenged
          if the arbitrator fails to do so. Except where otherwise required by
          the applicable AAA rules or applicable law, the arbitration will take
          place in United States, New Mexico. Except as otherwise provided
          herein, the Parties may litigate in court to compel arbitration, stay
          proceedings pending arbitration, or to confirm, modify, vacate, or
          enter judgment on the award entered by the arbitrator. If for any
          reason, a Dispute proceeds in court rather than arbitration, the
          Dispute shall be commenced or prosecuted in the state and federal
          courts located in United States, Florida, and the Parties hereby
          consent to, and waive all defenses of lack of personal jurisdiction,
          and forum non convenient with respect to venue and jurisdiction in
          such state and federal courts. Application of the United Nations
          Convention on Contracts for the International Sale of Goods and the
          Uniform Computer Information Transaction Act (UCITA) are excluded from
          these Terms of Use. In no event shall any Dispute brought by either
          Party related in any way to the Site be commenced more than one (1)
          years after the cause of action arose. If this provision is found to
          be illegal or unenforceable, then neither Party will elect to
          arbitrate any Dispute falling within that portion of this provision
          found to be illegal or unenforceable and such Dispute shall be decided
          by a court of competent jurisdiction within the courts listed for
          jurisdiction above, and the Parties agree to submit to the personal
          jurisdiction of that court.
        </p>
      </div>

      <div className="mb-4">
        <p className="font-bold text-lg mb-2">Restrictions</p>
        <p className="mb-2">
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
        </p>
      </div>

      <div className="mb-4">
        <p className="font-bold text-lg mb-2">
          Exceptions to Informal Negotiations and Arbitration
        </p>
        <p className="mb-2">
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations binding arbitration:
          (a) any Disputes seeking to enforce or protect, or concerning the
          validity of, any of the intellectual property rights of a Party; (b)
          any Dispute related to, or arising from, allegations of theft, piracy,
          invasion of privacy, or unauthorized use; and (c) any claim for
          injunctive relief. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </p>
      </div>

      <div className="mb-4">
        <p className="font-bold text-lg mb-2">SYSTEM REQUIREMENTS</p>
        <p className="mb-2">
          Use of certain areas of the Website requires Internet access, audio
          manager software, or other software allowing the downloading and
          storing of audio and audio-visual files in MP3 or other digital format
          (the “Software“), and, for certain downloadable content, a compatible
          player device (the “Device“). The Site may, at any time and from time
          to time, in its sole discretion, modify, revise, or otherwise change
          the system requirements for the Site and the format of any
          downloadable content, in whole or in part, without notice or liability
          to you.
        </p>
      </div>

      <div className="mb-4">
        <p className="font-bold text-lg mb-2">Corrections</p>
        <p className="mb-2">
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Site at any time, without prior notice.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="font-bold text-lg mb-2">Disclaimer</h2>
        <ol>
          <li>
            <p>
              THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
              THAT YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK.
            </p>
          </li>
          <li>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
              WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
              YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT.
            </p>
          </li>
          <li>
            <p>
              WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
              COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES
              LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY OR
              RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
              CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
              ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
              THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
              SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
              INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
              TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN
              HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
              SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
              CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
              INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
              TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE.
            </p>
          </li>
          <li>
            <p>
              WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
              FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
              THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
              MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING,
              AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
              MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
              PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
              PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
              SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
              APPROPRIATE.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-4">
        <h2 className="font-bold text-lg mb-2">Limitations of Liability</h2>
        <ol>
          <li>
            <p>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
          </li>
          <li>
            <p>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
              LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
              FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
              PAID, IF ANY, BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR
              TO ANY CAUSE OF ACTION ARISING.
            </p>
          </li>
          <li>
            <p>
              CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
              OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
              ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
              HAVE ADDITIONAL RIGHTS.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-4">
        <p>
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as well as data
          relating to your use of the Site.
        </p>
        <p>
          Although we perform regular routine backups of data, you are solely
          responsible for all data that you transmit or that relates to any
          activity you have undertaken using the Site. You agree that we shall
          have no liability to you for any loss or corruption of any such data,
          and you hereby waive any right of action against us arising from any
          such loss or corruption of such data.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="font-bold text-lg mb-2">User Data</h2>
        <ol>
          <li>
            <p>
              We will maintain certain data that you transmit to the Site for
              the purpose of managing the performance of the Site, as well as
              data relating to your use of the Site.
            </p>
          </li>
          <li>
            <p>
              Although we perform regular routine backups of data, you are
              solely responsible for all data that you transmit or that relates
              to any activity you have undertaken using the Site.
            </p>
          </li>
          <li>
            <p>
              You agree that we shall have no liability to you for any loss or
              corruption of any such data, and you hereby waive any right of
              action against us arising from any such loss or corruption of such
              data.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-4">
        <h2 className="font-bold text-lg mb-2">
          Electronic Communications, Transactions and Signatures
        </h2>
        <ol>
          <li>
            <p>
              Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications.
            </p>
          </li>
          <li>
            <p>
              You consent to receive electronic communications, and you agree
              that all agreements, notices, disclosures, and other
              communications we provide to you electronically, via email and on
              the Site, satisfy any legal requirement that such communication be
              in writing.
            </p>
          </li>
          <li>
            <p>
              YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
              ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
              POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US
              OR VIA THE SITE.
            </p>
          </li>
          <li>
            <p>
              You hereby waive any rights or requirements under any statutes,
              regulations, rules, ordinances, or other laws in any jurisdiction
              which require an original signature or delivery or retention of
              non-electronic records, or to payments or the granting of credits
              by any means other than electronic means.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-4">
        <h2 className="font-bold text-lg mb-2">
          California Users and Residents
        </h2>
        <p>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="font-bold text-lg mb-2">Miscellaneous</h2>
        <ol>
          <li>
            <p>
              These Terms of Use and any policies or operating rules posted by
              us on the Site or in respect to the Site constitute the entire
              agreement and understanding between you and us.
            </p>
          </li>
          <li>
            <p>
              Our failure to exercise or enforce any right or provision of these
              Terms of Use shall not operate as a waiver of such right or
              provision.
            </p>
          </li>
          <li>
            <p>
              These Terms of Use operate to the fullest extent permissible by
              law.
            </p>
          </li>
          <li>
            <p>
              We may assign any or all of our rights and obligations to others
              at any time.
            </p>
          </li>
          <li>
            <p>
              We shall not be responsible or liable for any loss, damage, delay,
              or failure to act caused by any cause beyond our reasonable
              control.
            </p>
          </li>
          <li>
            <p>
              If any provision or part of a provision of these Terms of Use is
              determined to be unlawful, void, or unenforceable, that provision
              or part of the provision is deemed severable from these Terms of
              Use and does not affect the validity and enforceability of any
              remaining provisions.
            </p>
          </li>
          <li>
            <p>
              There is no joint venture, partnership, employment, or agency
              relationship created between you and us as a result of these Terms
              of Use or use of the Site.
            </p>
          </li>
          <li>
            <p>
              You agree that these Terms of Use will not be construed against us
              by virtue of having drafted them.
            </p>
          </li>
          <li>
            <p>
              You hereby waive any and all defenses you may have based on the
              electronic form of these Terms of Use and the lack of signing by
              the parties hereto to execute these Terms of Use.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-4">
        <h2 className="font-bold text-lg mb-2">Contact Us</h2>
        <p>
          In order to resolve a complaint regarding the Site or to receive
          further information regarding the use of the Site, please contact us
          at:
        </p>
        <address>
          Life Hack Library
          <br />
          320 Gold Ave
          <br />
          Albuquerque, NM 87102
          <br />
          United States
          <br />
          Phone: (833) 865-6831
          <br />
          Email: support@dailysparq.com
        </address>
      </div>

      <div className="mb-4">
        <strong>Last updated October 03, 2023</strong>
      </div>
      <div className="flex items-center justify-center">
        <button
          className="btn text-white"
          onClick={() => navigation("/signup")}
        >
          Agree
        </button>
      </div>
    </div>
  );
};

export default TermsOfUse;
