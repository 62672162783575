import React, { useEffect, useState } from "react";
import drawerLogo from "../../assets/images/daily-mentor-logo.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import personalized from "../../assets/images/persolizedIns.svg";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import axios from "axios";
import { FaShuffle } from "react-icons/fa6";
import { HiDotsVertical } from "react-icons/hi";

const VisuallyHiddenInput = styled("input")(
  {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  },
  ({ multiple }: { multiple?: boolean }) => ({
    ...(multiple ? { multiple: "multiple" } : {}),
  })
);

const PersonalizedVisual = () => {
  const [visualInspirationImage, setVisualInspirationImage] =
    useState<string>("");
  const [visualInspirations, setVisualInspirations] = useState<string[]>([]);
  const navigation = useNavigate();
  const [isRandom, setIsRandom] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [isSelectedOpen, setIsSelectedOpen] = useState<any>(false);
  const [isSelected, setIsSelected] = useState<any>(false);
  const [isMultiSelected, setIsMultiSelected] = useState<any>(false)

  const token = localStorage.getItem("accessToken");

  const getAllVisualInspiration = async () => {
    const url = apiEndPoints.getUserVisualInspiration;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setVisualInspirations(data?.response);
  };

  useEffect(() => {
    const getRandomPersonilized = async () => {
      if (token) {
        const response = await axios.get(apiEndPoints.getRandomPersonlized, {
          headers: {
            Authorization: token,
          },
        });

        if (response.data.responseStatus === 1) {
          setIsRandom(response.data.response.isRandom);
        } else {
          setIsRandom(false);
        }
      }
    };

    getRandomPersonilized();
  }, [isRandom, token]);

  useEffect(() => {
    getAllVisualInspiration();
  }, [visualInspirationImage]);

  const handleImageChange = async (event: any) => {
    const files = event.target?.files;

    if (files && files?.length > 0) {
      const filesArray = Array.from(files);
      filesArray?.map(async (file: any) => {
        const reader = new FileReader();
        const formData = new FormData();

        const newFileName = `${new Date()}+${file?.name}`;

        formData.append("imageUrl", file, newFileName);

        const uploadUrl = apiEndPoints.addUserVisualInspiration;

        const response = await axios.post(uploadUrl, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        reader.onloadend = () => {
          setVisualInspirationImage(reader.result as string);
        };

        reader.readAsDataURL(file);
      });
    }
  };


  const handleDeleteImage = async () => {
    await Promise.all(selectedImages?.map(async (id, index) => {
      const response = await axios.delete(
        `${apiEndPoints.deleteVisualInspiration}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.responseStatus === 1 && index === selectedImages.length - 1) {
        setVisualInspirationImage(response.data.response);
      }
    }));
    setIsMultiSelected(false)
    setIsSelectedOpen(false)
    setIsSelected(false)
    setSelectedImages([]);
  };

  const handleToggleSelect = (url: string) => {
    const index = selectedImages.indexOf(url);
    if (index === -1) {
      setSelectedImages([...selectedImages, url]);
    } else {
      const newSelectedImages = [...selectedImages];
      newSelectedImages.splice(index, 1);
      setSelectedImages(newSelectedImages);
    }
  };

  const handleSingleSelect = () => {
    setIsSelected(!isSelected);
    setIsSelectedOpen(false)
    setIsMultiSelected(false)
    setSelectedImages([]);
  };

  const handleMultiSelect = async () => {
    setIsSelected(false);
    setIsSelectedOpen(false)
    setIsMultiSelected(!isMultiSelected);
    if (!isMultiSelected) {
      const allIds = visualInspirations.map((item: any) => item?._id);
      setSelectedImages(allIds);
    } else {
      setSelectedImages([])
    }

  }

  const handleShuffle = async (array: any, isRandom: Boolean) => {
    const response = await axios.post(
      apiEndPoints.addRandomPersonlized,
      { isRandom: !isRandom },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.data.responseStatus === 1) {
      setIsRandom(response.data.response);
    }

    const url = apiEndPoints.getUserVisualInspiration;
    const images = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await images.json();
    setVisualInspirations(data?.response);
  };

  const headerText = "Personalized Visual Inspiration";
  const descriptionText =
    "Please upload visuals that speak to your core. This will be helpful in curating personalized daily motivation.";

  return (
    <>
      <div className=" bg-mobile-primary w-full p-5 pt-5 text-black overflow-y-scroll h-[100vh]">
        <div className="lg:hidden" onClick={() => navigation("/")}>
          <IoIosArrowBack color="black" size={30} />
        </div>
        <img
          src={drawerLogo}
          className="block mx-auto w-24"
          style={{ width: "198px", height: "50px", marginBottom: "50px" }}
        />
        <h1 className="text-black text-lg font-bold mb-3">{headerText}</h1>
        <p className="text-black">{descriptionText}</p>
        <div className="overflow-y-scroll bg-mobile-primary w-full sm:w-[60%] md:w-[55%] xl:w-[100%]">
          <div className="flex justify-center mt-6">
            <Button id="capturePhotoButton" component="label">
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                multiple={true}
                onChange={handleImageChange}
              />
              <img
                src={personalized}
                alt="Upload"
                className="w-[221px] h-[181px]"
              />
            </Button>
          </div>

          <div className="flex w-full justify-between items-center mt-2">
            <div className="relative">
              <HiDotsVertical size={30} onClick={() => setIsSelectedOpen(!isSelectedOpen)} />
              {
                isSelectedOpen && <div className=" bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute left-5 bottom-5">
                  <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                    <li onClick={handleSingleSelect}>
                      <p className={`block px-4 py-2 ${isSelected ? "bg-gray-500 text-white" : ""}`}>Select</p>
                    </li>
                    <li onClick={handleMultiSelect}>
                      <p className={`block px-4 py-2 ${isMultiSelected ? "bg-gray-500 text-white" : ""}`}>Select All</p>
                    </li>
                  </ul>
                </div>
              }
            </div>
            <p>
              Tap to shuffle your daily Personalized Visual Inspiration:
            </p>
            {isRandom ? (
              <p
                className="relative"
                onClick={() => handleShuffle(visualInspirations, isRandom)}
              >
                <FaShuffle size={25} />
                <p className="w-[6px] h-[7px] rounded-full bg-green-700 absolute bottom-0 right-[43%]"></p>
              </p>
            ) : (
              <p onClick={() => handleShuffle(visualInspirations, isRandom)}>
                {" "}
                <FaShuffle size={25} />
              </p>
            )}
          </div>

          {
            selectedImages.length > 0 && <div className="w-full mt-3 mb-3">
              <button
                onClick={handleDeleteImage}
                className="btn block w-full text-white bg-web-secondary text-web-primar"
              >
                Delete ({selectedImages.length})
              </button>
            </div>
          }


          <div className="grid grid-cols-3 lg:grid-cols-6 mt-4 lg:gap-4 gap-[6px]">
            {visualInspirations?.map((src: any, index) => {
              return (
                <div key={index} className="relative">
                  <img
                    src={src.imageUrl}
                    className="w-[113px] h-[111px] rounded-[3px] lg:w-full"
                  />
                  {/* <button
                    className="absolute top-1 right-1 bg-black p-1 rounded-full"
                    onClick={() => handleDeleteImage()}
                  >
                    <RiDeleteBin6Line size={15} color="#fff" />
                  </button> */}
                  {
                    (isSelected || isMultiSelected) && <input
                      className="absolute top-1 right-1 bg-black p-1 rounded-full"
                      type="checkbox"
                      checked={selectedImages.includes(src?._id)}
                      onChange={() => handleToggleSelect(src?._id)}
                    />
                  }
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalizedVisual;
