import { Link, useLocation } from "react-router-dom";
// import fillSave from "../../assets/images/filled.svg";
// import unFillSave from "../../assets/images/Save Unfill.svg";
import unfillHome from "../../assets/images/homeIcon.svg";
import fillHome from "../../assets/images/homeIcon2.svg";
import unfillSave from "../../assets/images/saveIcon.svg";
import fillSave from "../../assets/images/saveIcon2.svg";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks/hooks";

const MobileNavbar = () => {
  const location = useLocation();
  const [, setAnimationClass] = useState("");
  const [, setStreak] = useState(0);
  const currentStreaks = useAppSelector(
    (state) => state?.mobileMedia?.currentStreak
  );

  useEffect(() => {
    const time = setTimeout(() => {
      setAnimationClass("animate__animated animate__bounceIn");
      const timeout = setTimeout(() => {
        setAnimationClass("");
      }, 1500);
      setStreak(currentStreaks);
      return () => clearTimeout(timeout);
    }, 2000);

    return () => clearTimeout(time);
  }, [currentStreaks]);

  return (
    <div className="border rounded-full border-t-black bg-black flex justify-between items-center self-center w-[270px] p-[8px]">
      <div>
        <Link to="/">
          {location.pathname === "/" ? (
            <div className="flex flex-col items-center justify-center rounded-full w-[60.27px] h-[60.27px] bg-white inner-shadow">
              {/* <div className="text-black w-2 h-2 rounded-full bg-black " /> */}
              {/* <HiHome color="black" size={35} /> */}
              <img
                src={unfillHome}
                alt="un-fill home"
                width={20.25}
                height={20.25}
              />
              <p className="text-black text-[8px] font-bold mt-[3px]">HOME</p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center rounded-full w-[60.27px] h-[60.27px] bg-black">
              {/* <div className="text-black w-2 h-2 rounded-full bg-black " /> */}
              <img
                src={fillHome}
                alt="fill home"
                width={20.25}
                height={20.25}
                className="opacity-50"
              />
              <p className="text-[#A1A1A1] text-[8px] font-bold mt-[3px]">
                HOME
              </p>
            </div>
          )}
        </Link>
        {/* <p className="text-center text-xs text-black">Home</p> */}
      </div>
      <div>
        <Link to="/audioSave">
          {location.pathname === "/audioSave" ? (
            <div className="flex flex-col items-center justify-center rounded-full w-[60.27px] h-[60.27px] bg-white inner-shadow">
              {/* <p className="text-black w-2 h-2 rounded-full bg-black"></p> */}
              <img src={unfillSave} alt="fillsaveIcon" />
              <p className="text-black text-[8px] font-bold mt-[3.5px]">
                SAVED
              </p>
              {/* <FaBookmark size={27} /> */}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center rounded-full w-[60.27px] h-[60.27px] bg-black">
              <img src={fillSave} alt="saveIcon" className="opacity-50" />
              <p className="text-[#A1A1A1] text-[8px] font-bold mt-[3.5px]">
                SAVED
              </p>
              {/* <FaRegBookmark size={27} /> */}
            </div>
          )}
        </Link>
        {/* <div className="text-center text-xs text-black">Save</div> */}
      </div>
      {/* <div className="text-center">

        <Link to="/mindscribe" className="btn border-none flex items-end  btn-ghost">
          {
            location.pathname === "/mindscribe" ?
              <div className="flex flex-col items-center">
                <p className="text-black w-2 h-2 rounded-full bg-black "></p>
                <span className="block">
                
                  <img src={fillPencil} alt="" className="h-[35px] w-[35px]" />
                </span>
              </div>
              :
       
              <img src={unFillPencil} alt="" className="h-[35px] w-[35px]" />
          }
        </Link>
        <div className="text-center text-xs text-black">MindScribe</div>
      </div> */}
      <div className="text-center text-black">
        <Link to="/streaks">
          {location.pathname === "/streaks" ? (
            <div className="flex flex-col items-center justify-center rounded-full bg-white w-[60.27px] h-[60.27px] inner-shadow">
              <p className="text-black text-[24px] leading-[24px]">
                {currentStreaks}
              </p>
              <p className="text-black text-[8px]">STREAK</p>
            </div>
          ) : (
            <div className="rounded-full bg-black w-[60.27px] h-[60.27px] flex flex-col items-center justify-center">
              <p className="text-[24px] leading-[24px] text-[#A1A1A1]">
                {currentStreaks}
              </p>
              <p className="text-[#A1A1A1] text-[8px] font-bold">STREAK</p>
            </div>
          )}
        </Link>
        {/* <div className="text-center text-xs text-black">Streak</div> */}
      </div>
    </div>
  );
};

export default MobileNavbar;
