import { NavLink } from "react-router-dom";
import AvatarDropDown from "../AvatarDropDown/AvatarDropDown";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { useEffect } from "react";
import { userProfile } from "../../redux/slices/authSlice";
import { getCurrentStreak } from "../../redux/slices/mobileMediaSlice";

const classes = {
  active: "btn text-white bg-web-secondary border-none text-white text-xl",
  inactive:
    "btn hover:bg-web-secondary hover:text-white bg-web-container border-none text-black text-xl",
};

const HomeNavbar = () => {
  const token = localStorage.getItem("accessToken");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userProfile(token));
    dispatch(getCurrentStreak());
  }, []);

  return (
    <div className="hidden sm:flex items-center bg-web-container mx-8 md:mx-16 px-6 gap-3 rounded-lg p-1 z-[20]">
      <div className="flex-1">
        <a className="text-center font-cormorant font-bold text-xs md:text-xl text-black">
          THE DAILY LIFE
          <br />
          <span className="font-cormorant text-center text-xl md:text-4xl font-extrabold">
            MENTOR
          </span>
        </a>
      </div>
      <div className="flex-none">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? classes.active : classes.inactive
          }
        >
          <span className="hidden m-840:inline lg:text-[14px] xl:text-[16px]">
            Home
          </span>
        </NavLink>
      </div>
      <div className="flex-none">
        <NavLink
          to="/audioSave"
          className={({ isActive }) =>
            isActive ? classes.active : classes.inactive
          }
        >
          <span className="hidden m-840:inline lg:text-[14px] xl:text-[16px]">
            Saved
          </span>
        </NavLink>
      </div>

      {/* <div className="flex-none">
        <NavLink
          to="/mindscribe"
          className={({ isActive }) =>
            isActive ? classes.active : classes.inactive
          }
        >
          <span className="hidden m-840:inline lg:text-[14px] xl:text-[16px]">Mindscribe</span>
        </NavLink>
      </div> */}

      <div className="flex-none">
        <NavLink
          to="/streaks"
          className={({ isActive }) =>
            isActive ? classes.active : classes.inactive
          }
        >
          <span className="hidden m-840:inline lg:text-[14px] xl:text-[16px]">
            Streak
          </span>
        </NavLink>
      </div>
      <div className="flex-none">
        <NavLink
          to="/settingsWeb"
          className={({ isActive }) =>
            isActive ? classes.active : classes.inactive
          }
        >
          <span className="hidden m-840:inline lg:text-[14px] xl:text-[16px]">
            Settings
          </span>
        </NavLink>
      </div>
      <div className="rounded-full">
        <AvatarDropDown />
      </div>
    </div>
  );
};

export default HomeNavbar;
