import loaderSvg from "../../assets/images/Loader-Circle-Gif.gif";

const CustomLoaderComponent = (props) => {
  return (
    <div className="bg-[white] fixed top-0 left-0 justify-center min-h-[100vh] h-[100vh] w-full flex flex-col items-center z-40">
      <img src={loaderSvg} alt="loader" />
      {props?.showMessage ? (
        <p className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-web-secondaryDark sm:mb-4 md:mb-4 lg:mb-7 xl:mb-7">
          Please be patient, this can take a minute.
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomLoaderComponent;
