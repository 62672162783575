import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import "../../styles/timePicker.css"

interface TimePros {
  selectedTime: any;
  setSelectedTime: any;
  error: any;
  setError: any;
}

const TimeAndDatePicker: React.FC<TimePros> = ({ selectedTime, setSelectedTime, error, setError }) => {

  // const [value, setValue] = React.useState<any>("10:00 AM");

  // const onChange = (timeValue: any) => {
  //   setValue(timeValue);
  //   const defaultDate = moment(new Date()).format("YYYY-MM-DD"); // You can change this to any desired date

  //   const parsedDate = dayjs(`${defaultDate} ${timeValue}`, { format: "YYYY-MM-DD hh:mm A" });
  //   setSelectedTime(parsedDate)
  // };

  // React.useEffect(() => {
  //   setValue(moment(selectedTime && selectedTime.format()).format("hh:mm A"));
  // }, [selectedTime]);


  return (
    <>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ fontSize: 'x-large !important' }}>
          <MultiSectionDigitalClock
            value={selectedTime}
            onChange={(newValue) => setSelectedTime(newValue)}
            views={['hours', 'minutes']}
            timeSteps={{ hours: 1, minutes: 1 }}
            defaultValue={selectedTime}
            className={'main-ClockContainer MuiDigitalClock-root'}
          />
        </div>
      </LocalizationProvider> */}

      {/* 
      <div className='relative p-10'>
        <TimePicker onChange={onChange} ce value={value} use12Hours popupClassName="" inputClassName="text-2xl text-center blackborder" />
      </div> */}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DemoContainer components={['TimeField', 'TimeField']}> */}
        <TimeField
          // label="Controlled field"
          value={selectedTime}
          onChange={(newValue) => {
            setError(null)
            setSelectedTime(newValue)
          }}
          // className='text-center text-2xl'
          fullWidth ={true}
          size = "medium"
          required = {true}
          onError= {(error: any, value:any) => {
            setError(error);
          }}
        />
        {/* </DemoContainer> */}
      </LocalizationProvider>


    </>
  );
};

export default TimeAndDatePicker;
