import { useNavigate } from "react-router-dom";
import logoSrc from "../../assets/images/daily-mentor-logo.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import MobileDrawer from "./MobileDrawbar";
import { RxAvatar } from "react-icons/rx";
import { useEffect, useState } from "react";
import { userProfile } from "../../redux/slices/authSlice";
import { getCurrentStreak } from "../../redux/slices/mobileMediaSlice";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import moment from "moment";

const MobileHeader = () => {
  // const [isTrialPeriodExpired, setTrialPeriodExpired] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(true);
  const userDetails = useAppSelector((state) => state?.userAuth?.userDetails);
  const dispatch = useAppDispatch();

  const navigation = useNavigate();
  const token = localStorage.getItem("accessToken");

  const checkTrialPeriod = async () => {
    const subscriptionResponse = await fetch(
      apiEndPoints.getSubscriptionDetails,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const subData = await subscriptionResponse.json();
    if (subData && subData?.data?.status === "active") {
      setHasSubscription(true);
    } else {
      setHasSubscription(false);
    }

    // if (
    //   moment().diff(moment(userDetails?.createdOn), "d") > 7 &&
    //   (!subData.data || subData?.data?.status !== "active")
    // ) {
    //   setTrialPeriodExpired(true);
    // } else {
    //   setTrialPeriodExpired(false);
    // }
  };

  useEffect(() => {
    checkTrialPeriod();
    dispatch(userProfile(token));
    dispatch(getCurrentStreak());
  }, []);

  return (
    <div className="fixed h-[93px] w-[100%] z-30 bg-mobile-primary">
      {userDetails && !hasSubscription && (
        <div className="w-full bg-[#979797] text-white text-center text-sm">
          Your Trail Period Has{" "}
          {7 - moment().diff(moment(userDetails?.createdOn), "d") < 0
            ? 0
            : 7 - moment().diff(moment(userDetails?.createdOn), "d")}{" "}
          Days Left.{" "}
          <span
            onClick={() => navigation("/settings/subscription")}
            className="underline"
          >
            Subscribe Now
          </span>
        </div>
      )}
      <div className="flex justify-between px-3 z-40 items-center mt-[1rem] mb-[1rem]">
        <div className="">
          <MobileDrawer />
        </div>
        <div className="self-center">
          <img className="w-[154px]" src={logoSrc} alt="logo" />
        </div>
        <div>
          <div
            className="w-[56px] h-[56px] rounded-full self-center"
            onClick={() => navigation("/settings/profile")}
          >
            {userDetails?.imageUrl ? (
              <img
                style={{ borderRadius: "100%", width: "56px", height: "56px" }}
                src={userDetails?.imageUrl}
                alt="avatar"
              />
            ) : (
              <RxAvatar size={56} color="black" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
