import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface IndexState {
    value: number;
}
const initialState: IndexState = {
    value: 0,
}

export const indexSlice = createSlice({
  name: "index",
  initialState,
  reducers: {
    updateIndex: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

// export const boostSelector = (state: RootState) => state.boosts.boosts;
export const indexSelector = (state: RootState) => state.index.value;
export const {updateIndex} = indexSlice.actions;
export default indexSlice.reducer;
