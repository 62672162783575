import React, { useEffect, useState } from "react";
import AboutTags from "./InputTags";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import Loader from "../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { closeDrawer } from "../../redux/slices/drawerSlice";

interface Tag {
  label: string;
  value: string | number | symbol | null;
}

const AboutPackage = ({
  handleLoader,
}: {
  handleLoader: (value: boolean) => void;
}) => {
  const [allTags, setAllTags] = useState<any>([]);
  const [health, setHealth] = useState<string[]>([]);
  const [goals, setGoals] = useState<string[]>([]);
  const [occupation, setOccupation] = useState<string[]>([]);
  const [obstacles, setObstacles] = useState<string[]>([]); // Added Obstacles
  const [tone, setTone] = useState<string[]>([]); // Added Obstacles
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const token = localStorage.getItem("accessToken");

  const getAllTags = async () => {
    const response = await fetch(apiEndPoints.getAllTags, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseStatus === 1) {
      setAllTags(data.tags);
    } else {
      setAllTags([]);
    }
  };

  const getAllUserTags = async () => {
    const response = await fetch(apiEndPoints.getUserTags, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseStatus === 1) {
      setHealth(getTagValues(data.userTags.tags, "Health"));
      setGoals(getTagValues(data.userTags.tags, "Goals"));
      setOccupation(getTagValues(data.userTags.tags, "Occupation"));
      setObstacles(getTagValues(data.userTags.tags, "Obstacles"));
      setTone(getTagValues(data.userTags.tags, "Tone"));
    }
  };

  const getTagValues = (tags: any[], tagName: string) => {
    return tags
      .filter((item: any) => item?.tag?.name === tagName)
      .map((item: any) => item.name);
  };

  useEffect(() => {
    const getTag = async () => {
      await getAllTags();
      await getAllUserTags();
    };
    getTag();
  }, []);

  const handlePostTag = async () => {
    handleLoader(true);
    const savedTags = [
      { tag: "Health", values: health },
      { tag: "Goals", values: goals },
      { tag: "Occupation", values: occupation },
      { tag: "Obstacles", values: obstacles },
      { tag: "Tone", values: tone ? tone : ["Compassionate"] },
    ].flatMap(({ tag, values }) => {
      const tagInfo = allTags.find((item: any) => item?.name === tag);
      return values.map((value: string) => ({
        typeId: tagInfo?._id,
        name: value,
      }));
    });

    const response = await fetch(apiEndPoints.saveUserTags, {
      method: "POST",
      body: JSON.stringify(savedTags),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseStatus === 1) {
      toast.success("Successfully saved");
      handleLoader(false);
      if (location.pathname === "/about") {
        navigation("/");
      } else {
        navigation("/");
      }
    } else {
      toast.error(data.error);
    }
  };

  const renderTagSection = (
    tag: string,
    state: string[],
    setState: React.Dispatch<React.SetStateAction<string[]>>,
    placeholder: any,
    list: Tag[],
    allowedCustomField: boolean
  ) => (
    <div className="w-full p-2" key={tag}>
      <h2 className="text-lg mb-2 font-bold">{tag}</h2>
      <AboutTags
        existingTag={state.map((tag: string) => {
          return {
            label: tag,
            value: tag,
          };
        })}
        setExistingTag={setState}
        placeholder={placeholder}
        list={list}
        allowedCustomField={allowedCustomField}
      />
    </div>
  );

  return (
    <>
      {allTags?.length > 0 ? (
        <>
          {renderTagSection(
            "Health",
            health,
            setHealth,
            "Athletic, Overweight, Diabetic",
            allTags
              .find((tag: any) => tag.name === "Health")
              ?.values?.map((tag: string) => {
                return {
                  label: tag,
                  value: tag,
                };
              }),
            true
          )}
          {renderTagSection(
            "Goals",
            goals,
            setGoals,
            "Happiness, Millionaire, Marriage",
            allTags
              .find((tag: any) => tag.name === "Goals")
              ?.values?.map((tag: string) => {
                return {
                  label: tag,
                  value: tag,
                };
              }),
            true
          )}
          {renderTagSection(
            "Occupation",
            occupation,
            setOccupation,
            "Teacher, Student, Military",
            allTags
              .find((tag: any) => tag.name === "Occupation")
              ?.values?.map((tag: string) => {
                return {
                  label: tag,
                  value: tag,
                };
              }),
            true
          )}
          {renderTagSection(
            "Obstacles",
            obstacles,
            setObstacles,
            "Stress, Time, Money",
            allTags
              .find((tag: any) => tag.name === "Obstacles")
              ?.values?.map((tag: string) => {
                return {
                  label: tag,
                  value: tag,
                };
              }),
            true
          )}
          {/* {renderTagSection(
            "Tone",
            tone,
            setTone,
            "Compassionate, Direct, No-nonsense",
            allTags
              .find((tag: any) => tag.name === "Tone")
              ?.values?.map((tag: string) => {
                return {
                  label: tag,
                  value: tag,
                };
              }),
            false
          )} */}
        </>
      ) : (
        <div className="flex items-center justify-center pt-[60%] pb-[60%]">
          <Loader />
        </div>
      )}

      <div className="flex justify-center mt-7">
        <button
          className="w-[100%] bg-[#56B781] h-[45px] rounded-full mt-[15px] mb-[15%] ml-[5px] mr-[5px] text-white"
          onClick={handlePostTag}
        >
          {location?.pathname === "/about" ? "Continue" : "Save"}
        </button>
      </div>
    </>
  );
};

export default AboutPackage;
