import { useState } from 'react';
import step1 from "../../../assets/images/Step 1.svg"
import step2 from "../../../assets/images/Step 2.svg"
import step3 from "../../../assets/images/Step 3.svg"
import step4 from "../../../assets/images/Step 4.svg"
import step5 from "../../../assets/images/Step 5.svg"
import firefox1 from "../../../assets/images/Step 1 (1).svg"
import firefox2 from "../../../assets/images/Step 2 (1).svg"
import firefox3 from "../../../assets/images/Step 3 (1).svg"
import firefox4 from "../../../assets/images/Step 4 (1).svg"

export function InstallAndroids() {
    const [isSafariModal, setIsSafariModal] = useState(false);
    const [isFireFoxModal, setIsFirefoxModal] = useState(false);
    const [defaultModal, setDefaultModal] = useState(false)
    const [alreadyInstall, setAlreadyInstall] = useState(false)

    return (
        <>
            {isSafariModal && (
                <div>
                    <dialog
                        id="my_modal_5"
                        className="modal modal-middle sm:modal-middle"
                        open={isSafariModal}
                    >
                        <div className="modal-box bg-white text-black p-2 pt-0 sm:p-8">
                            <div className='flex justify-end mt-0'>
                                <button className="modal-close text-[30px]" onClick={() => setIsSafariModal(false)}>
                                    {/* Add your close (cross) icon or text here */}
                                    &times;
                                </button>
                            </div>

                            <p className='text-2xl font-bold text-center'>Guide For Installation</p>
                            <p className='px-5 mt-2'>Step 1</p>
                            <img src={step1} alt="Step1" />
                            <p className='px-5'>Step 2</p>
                            <img src={step2} alt="Step2" />
                            <p className='px-5'>Step 3</p>
                            <img src={step3} alt="Step3" />
                            <p className='px-5'>Step 4</p>
                            <img src={step4} alt="Step4" />
                            <p className='px-5'>Step 5</p>
                            <img src={step5} alt="Step5" />
                            <div className="modal-action flex justify-center sm:justify-end p-3">
                                <form method="dialog" className="w-full text-white">
                                    <button className="btn w-full text-white" onClick={() => setIsSafariModal(false)}>
                                        Close
                                    </button>

                                </form>
                            </div>
                        </div>
                    </dialog>
                </div>
            )}

            {isFireFoxModal && (
                <div>
                    <dialog
                        id="my_modal_5"
                        className="modal modal-middle sm:modal-middle"
                        open={isFireFoxModal}
                    >
                        <div className="modal-box bg-white text-black p-2 pt-0 sm:p-8">
                            <div className='flex justify-end mt-0'>
                                <button className="modal-close text-[30px]" onClick={() => setIsFirefoxModal(false)}>
                                    {/* Add your close (cross) icon or text here */}
                                    &times;
                                </button>
                            </div>

                            <p className='text-2xl font-bold text-center'>Guide For Installation</p>
                            <p className='px-5 mt-2'>Step 1</p>
                            <img src={firefox1} alt="FStep1" />
                            <p className='px-5'>Step 2</p>
                            <img src={firefox2} alt="FStep2" />
                            <p className='px-5'>Step 3</p>
                            <img src={firefox3} alt="FStep3" />
                            <p className='px-5'>Step 4</p>
                            <img src={firefox4} alt="FStep4" />
                            <div className="modal-action flex p-3 justify-center sm:justify-end">
                                <form method="dialog" className="w-full text-white">
                                    <button className="btn w-full text-white" onClick={() => setIsFirefoxModal(false)}>
                                        Close
                                    </button>

                                </form>
                            </div>
                        </div>
                    </dialog>
                </div>
            )}


            {defaultModal && (
                <div>
                    <dialog
                        id="my_modal_5"
                        className="modal modal-middle sm:modal-middle"
                        open={defaultModal}
                    >
                        <div className="modal-box bg-white text-black p-2 pt-0 sm:p-8">
                            <div className='flex justify-end mt-0'>
                                <button className="modal-close text-[30px]" onClick={() => setDefaultModal(false)}>
                                    {/* Add your close (cross) icon or text here */}
                                    &times;
                                </button>
                            </div>

                            <p className='text-2xl font-bold text-center'>Guide For Installation</p>
                            <p>
                                <p className='p-2'>
                                    Your current browser is not supported directly install. Please open your browser settings and install the app using the provided options.
                                </p>
                            </p>
                            <div className="modal-action flex p-3 justify-center sm:justify-end">
                                <form method="dialog" className="w-full text-white">
                                    <button className="btn w-full text-white" onClick={() => setDefaultModal(false)}>
                                        Close
                                    </button>

                                </form>
                            </div>
                        </div>
                    </dialog>
                </div>
            )}


            {alreadyInstall && (
                <div>
                    <dialog
                        id="my_modal_5"
                        className="modal modal-middle sm:modal-middle"
                        open={alreadyInstall}
                    >
                        <div className="modal-box bg-white text-black p-2 pt-0 sm:p-8">
                            <div className='flex justify-end mt-0'>
                                <button className="modal-close text-[30px]" onClick={() => setAlreadyInstall(false)}>
                                    {/* Add your close (cross) icon or text here */}
                                    &times;
                                </button>
                            </div>

                            <p className='text-2xl font-semibold px-2'>Congrats!</p>
                            <p>
                                <p className='p-2'>
                                    You have already installed the app.
                                </p>
                            </p>
                            <div className="modal-action flex p-3 justify-center sm:justify-end">
                                <form method="dialog" className="w-full text-white">
                                    <button className="btn w-full text-white" onClick={() => setAlreadyInstall(false)}>
                                        Close
                                    </button>

                                </form>
                            </div>
                        </div>
                    </dialog>
                </div>
            )}
        </>
    );
}
