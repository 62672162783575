export const genders = [
  {
    id: 0,
    gender: "Male",
  },
  {
    id: 1,
    gender: "Female",
  },
  {
    id: 2,
    gender: "Non-Binary",
  },
];

export const features = [
  "Daily personalized motivational audio",
  "Save your favorite most impactful audios",
  "Streak feature to keep you engaged",
  "Share your favorite most impactful audios",
  "Preference of motivational tone"
];