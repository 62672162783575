import AuthenticationHeader from "../../components/AuthenticationHeader/AuthenticationHeader";
import InputField from "../../components/InputField/InputField";
import { Formik, Form } from "formik";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ForgotPasswordData } from "../../interfaces/interfaces";
import { signupApiCall as forgotPasswordApiCall } from "../../api/apiCalls/apiCalls";
import { forgotPasswordSchema } from "../../validationSchema/validationSchema";
import birdSrc from "../../assets/images/bird.png";
import Loader from "../../components/Loader/Loader";
import { IoIosArrowBack } from 'react-icons/io';

const ForgotPassword = () => {
  const [, setError] = useState<string>("");
  const navigate = useNavigate();
  // const [toggle, toggleSwitch] = useToggle();
  const [toggle, setToggle] = useState<boolean>(false);

  const initialValues: ForgotPasswordData = {
    email: "",
  };
  // showErrorToaster(error);
  const handleForgotPassword = (values: ForgotPasswordData) => {
    const url = apiEndPoints.forgotPassword;
    forgotPasswordApiCall(
      navigate,
      setError,
      values,
      url,
      "/updateForgotPassword",
      setToggle
    );
  };

  return (
    <div className="flex h-screen">
      <div className="justify-center items-center w-1/2 hidden sm:flex bg-web-container">
      <div
          style={{
            padding: '0px 10px',
          }}
        >
          <div onClick={() => navigate("/")}>
            <IoIosArrowBack color="black" size={30} />
          </div>

        </div>
        <div className="flex items-center flex-col w-4/5 bg-web-primary rounded-t-full">
          <img
            className="sm:mt-8 md:mt-10 lg:mt-12 xl:mt-12 sm:mb-5 md:mb-5 lg:mb-8 xl:mb-10 h-2/3 sm:w-20 md:w-28 lg:w-32 xl:w-36"
            src={birdSrc}
            alt="bird"
          />
          <p className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-web-secondaryDark sm:mb-4 md:mb-4 lg:mb-7 xl:mb-7">
            Reminder
          </p>
          <p className="text-center sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-web-secondary sm:mb-5 md:mb-8 lg:mb-10 xl:mb-14 font-normal">
            Be there for yourself because <br />
            you need yourself
          </p>
        </div>
      </div>
      <div className="h-full w-screen sm:w-1/2 bg-web-primary">
      <div
          style={{
            padding: '0px 10px',
            marginTop: "15px"
          }}
        >
          <div onClick={() => navigate("/")}>
            <IoIosArrowBack color="black" size={30} />
          </div>

        </div>
        <AuthenticationHeader heading="Forgot Password">
          <p className="pr-10 pl-10 sm:text-start mb-12 sm:pl-16.5 font-medium text-web-secondary">
          Enter your email address, we’ll send you a link to reset your password
          </p>
        </AuthenticationHeader>
        <Formik
          onSubmit={handleForgotPassword}
          initialValues={initialValues}
          validationSchema={forgotPasswordSchema}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col items-center sm:block h-42 px-4 sm:pl-16.5 w-full">
              <div className="w-full pr-5 pl-5">
                <InputField id="email" label="Email Address">
                  {errors.email && touched.email ? (
                    <div className="text-red-500">{errors.email}</div>
                  ) : null}
                </InputField>
                <button
                  type="submit"
                  disabled={toggle}
                  className="btn block w-full bg-web-secondary text-web-primary mt-16 disabled:bg-web-containerLight disabled:text-white"
                >
                  {toggle ? <Loader /> : "Submit"}
                </button>
              </div>

            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
