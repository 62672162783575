import React, { useState, useEffect, useCallback } from "react";
import { ReactTags } from "react-tag-autocomplete";
import "react-tagsinput/react-tagsinput.css";

function isValid(value: string) {
  return /^[a-z]$/i.test(value);
}
interface TagInterface {
  existingTag: any;
  setExistingTag: any;
  placeholder: any;
  list: Tag[];
  allowedCustomField: boolean;
}

interface Tag {
  label: string;
  value: string | number | symbol | null;
}

const AboutTags: React.FC<TagInterface> = ({
  existingTag,
  setExistingTag,
  placeholder,
  list,
  allowedCustomField,
}) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [isPlaceholder, setIsPlaceholder] = useState<boolean>(false);

  const [options, setOptions] = useState<any>({
    activateFirstOption: false,
    allowBackspace: false,
    collapseOnSelect: false,
    isDisabled: false,
    isInvalid: false,
  });

  useEffect(() => {
    setIsPlaceholder(tags.length === 0 && existingTag.length === 0);
  }, [tags, existingTag]);

  const onAdd = useCallback(
    (newTag: Tag) => {
      setTags([...tags, newTag]);
      setExistingTag([...tags, newTag].map((ele: Tag) => ele.label));
    },
    [tags]
  );

  const onDelete = useCallback(
    (tagIndex: number) => {
      setTags(tags.filter((_, i) => i !== tagIndex));
      setExistingTag(
        tags.filter((_, i) => i !== tagIndex).map((ele: Tag) => ele.label)
      );
    },
    [tags]
  );

  useEffect(() => {
    setTags(existingTag);
    setOptions([...list]);
  }, [existingTag]);

  useEffect(() => {});

  useEffect(() => {
    setIsPlaceholder(tags.length === 0 && existingTag.length === 0);
  }, [tags, existingTag]);

  const onValidate = useCallback((value: string) => true, []);

  return (
    <>
      <div className="tag-auto-complete">
        {!isPlaceholder ? (
          <ReactTags
            allowNew={allowedCustomField}
            placeholderText="type here..."
            collapseOnSelect
            labelText="Enter new tags"
            onAdd={onAdd}
            onDelete={onDelete}
            onValidate={onValidate}
            selected={tags}
            suggestions={options}
          />
        ) : (
          <div
            className="react-tagsinput"
            onClick={() => setIsPlaceholder(false)}
          >
            <p className="react-tagsinput-tag">{placeholder}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default AboutTags;
