import React from "react";
import OtpInputLib from "react-otp-input";

interface OtpInputProps {
  otp: any;
  setOtp: any;
}

const OtpInputComponent: React.FC<OtpInputProps> = ({ otp, setOtp }) => {
  return (
    <div className="mt-8">
      <p className="text-black mb-5">
        Please enter the 4 digit code sent to your email. Please check spam
        folder.
      </p>
      <OtpInputLib
        value={otp}
        onChange={setOtp}
        numInputs={4}
        renderSeparator={<span className="text-[#F1F0EC]">-</span>}
        renderInput={(props) => <input id="otp" {...props} />}
        inputStyle={{
          width: "100%",
          height: "20px",
          border: "none",
          borderBottom: "1px solid black",
          background: "none",
          color: "black",
          outline: "none",
        }}
        containerStyle="mt-2"
      />
    </div>
  );
};

export default OtpInputComponent;
