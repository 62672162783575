import React, { useEffect, useState } from "react";
import MobileNavbar from "../../components/HomeNavbar/MobileNavbar";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import axios from "axios";
import moment from "moment";
import { useAppSelector } from "../../redux/hooks/hooks";
import { screenWidthSelector } from "../../redux/slices/screenSlice";
import HomeNavbar from "../../components/HomeNavbar/HomeNavbar";
import checkIcon from "../../assets/images/check.svg";
import calendarIcon from "../../assets/images/calendar.svg";
import CustomLoaderComponent from "../../components/CustomLoaderComponent/CustomLoaderComponent";
// import { weekDays } from '../../components/constants/constants';

const Streaks = () => {
  const [currentDate, setCurrentDate] = useState<any>("");
  const [currentStreaks, setCurrentStreaks] = useState<any>(0);
  const [currentWeek, setCurrentWeek] = useState<any>([]);
  const [longestStreak, setLongestStreak] = useState<any>(0);
  const [montlyProgress, setMontlyProgress] = useState<any>(null);
  const [isInfo, setIsInfo] = useState<boolean>(false);
  const [dateModal, setDateModal] = useState<boolean>(false);
  const token = localStorage.getItem("accessToken");
  const screenWidth = useAppSelector(screenWidthSelector);
  const [loader, setLoader] = useState(false);

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    setLoader(true);
    const getStreak = async () => {
      if (token) {
        const response = await axios.get(apiEndPoints.currentStreak, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.responseStatus === 1) {
          setCurrentStreaks(response.data.response.currentStreak);
          setCurrentWeek(response.data.response.weekScore);
          setLongestStreak({
            startDate: response.data.response.previousLongestStreakStartDate,
            endDate: response.data.response.previousLongestStreakEndDate,
            streak: response.data.response.previousLongestStreak,
          });
        }
      }
      setLoader(false);
    };
    getStreak();
  }, [token]);

  useEffect(() => {
    const getMontlyProgress = async () => {
      const payload = {
        enteredDate: new Date(currentDate),
      };
      if (currentDate && token) {
        const response = await axios.post(
          apiEndPoints.getMontlyProgress,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseStatus === 1) {
          setMontlyProgress(response.data.response);
        }
      }
    };

    getMontlyProgress();
  }, [currentDate, token]);

  useEffect(() => {
    const today = new Date();
    setCurrentDate(moment(today).format("YYYY-MM"));
  }, []);

  const currentDay = moment().day();
  return (
    <>
      {loader ? (
        <CustomLoaderComponent />
      ) : (
        <div className="flex flex-col bg-mobile-primary min-h-dvh max-h-dvh text-black w-full overflow-y-scroll lg:overflow-y-hidden">
          <div className="flex flex-col flex-grow">
            {screenWidth < 1025 && <MobileHeader />}
            {screenWidth > 1024 && <HomeNavbar />}
            <div className="mt-[25%] grow flex flex-col relative items-center justify-center">
              <div
                className="grow item-stretch mx-[2%] rounded-t-[20px] flex flex-col justify-start leading-10 text-white w-[96%]
           bg-[#D8D6D0] pb-[5%]
           "
              >
                <div className="bg-black grow rounded-[20px] flex flex-col justify-between gap-[26.3px] pb-[5%] max-h-[25rem]">
                  <h5 className="font-bold text-[1.25rem] leading-[1.5rem] text-white pt-[2rem] text-center">
                    My current streak
                  </h5>
                  <p className="text-[7rem] text-center text-white font-semibold leading-[6rem]">
                    {currentStreaks}
                  </p>
                  <div className="flex px-[2%] justify-center gap-[1.25%]">
                    {weekDays.map((day: any) => {
                      const existDay = currentWeek?.find(
                        (item: any) => item?.day === day
                      );
                      return (
                        <>
                          {existDay ? (
                            <div className="bg-white w-[43.5px] h-[43.5px] rounded-[100%] relative">
                              <div className="flex flex-col items-center justify-center">
                                {weekDays[currentDay] === day ? (
                                  <div className="w-[5px] h-[5px] absolute top-[4px] rounded-full  bg-[#4CD964] border border-black" />
                                ) : (
                                  <div className="w-[5px] h-[5px] absolute top-[4px] rounded-full bg-transparent" />
                                )}
                                <p className="text-[11px] text-black m-[0px] p-[0px] mt-[-1px]">
                                  {day.slice(0, 3)}
                                </p>
                              </div>
                              <div className="absolute w-[43.5px] bottom-[4px] flex justify-center">
                                <img
                                  src={checkIcon}
                                  alt="checkIcon"
                                  width={10.49}
                                  height={10.47}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="bg-black w-[41.34px] h-[41.34px] border border-[#616161] flex flex-col gap-[2.5px] items-center justify-center rounded-[100%] relative">
                              <div className="flex flex-col items-center justify-center">
                                <div className="w-[5px] h-[5px] rounded-full bg-transparent" />
                                <p className="text-[11px] text-[#616161] m-[0px] p-[0px] mt-[-1px]">
                                  {day.slice(0, 3)}
                                </p>
                              </div>
                              <div className="absolute w-[43.5px] bottom-[4px] flex justify-center">
                                <img
                                  src={checkIcon}
                                  alt="check-icon"
                                  width={10.49}
                                  height={10.47}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div className="flex w-full">
                    {longestStreak?.streak !== 0 && (
                      <>
                        <div className="basis-[30%] border-e-2 border-white flex justify-center">
                          <p className="text-[5rem] font-semibold text-white leading-[6rem]">
                            {longestStreak?.streak === 1
                              ? `${longestStreak?.streak}`
                              : longestStreak?.streak > 0
                              ? `${longestStreak?.streak}`
                              : "-"}
                          </p>
                        </div>
                        <div className="basis-[70%] flex items-center px-[5%]">
                          <div>
                            <h5 className="font-bold text-[1.25rem] leading-[1.5rem] text-white p-[0px]">
                              My longest streak
                            </h5>
                            <p className="text-[12px] text-[#616161]">
                              {longestStreak?.startDate} -{" "}
                              {longestStreak?.endDate}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="border rounded-[13.92px] max-h-[70px] flex px-[26px] py-[16px] items-center gap-[20px] bg-black shadow-xl mt-[10px]"
                  onClick={() => setDateModal(true)}
                >
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    width={30.7}
                    height={33.85}
                  />
                  <div>
                    <div>
                      <p className="text-[1.25rem] leading-[1.5rem]  font-bold  text-white pb-[4px]">
                        My Monthly Breakdown
                      </p>
                    </div>
                    <div>
                      <p className="text-[0.625rem] leading-[0.75rem] p-[0px] text-[#616161]">
                        Click to view week’s average scores
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[2%] pb-[3%]">
            <div
              className={`flex justify-center p-[3%] py-[16px] rounded-b-[19px] bg-[#D8D6D0]`}
            >
              {screenWidth < 1025 && <MobileNavbar />}
            </div>
          </div>

          {isInfo && (
            <div>
              <dialog
                id="my_modal_5"
                className="modal modal-middle sm:modal-middle"
                open={isInfo}
              >
                <div className="modal-box bg-white text-black p-4 sm:p-8 min-w-[100vw] min-h-[100vh] max-w-[100vw] max-h-[100vh] w-[100vw] h-[100vh]">
                  <h3 className="font-bold text-lg">
                    How is my week average calculated?
                  </h3>
                  <p className="py-4">
                    How is my week average calculated? Earn 50% by listening to
                    your Daily Motivational Audio on time, as set by your Toggle
                    Target Time. Additionally, uploading an image of your
                    journal writing to MindScribe earns you another 50%. Each
                    replay of your Daily Motivational Audio adds an extra 1% to
                    your score. At the end of the week, a total average
                    percentage is calculated, providing you with an overall
                    weekly score. However, if you miss listening to your Daily
                    Motivational Audio on time, you'll earn 25% if done after
                    your Toggle Target Time or 0% if you miss the day entirely.
                    Ensure to upload an image of your journal writing to
                    MindScribe within 23 hours and 59 minutes to avoid earning
                    0% for that day.
                  </p>
                  <h3 className="font-bold text-lg">
                    How do I earn a high daily and weekly average?
                  </h3>
                  <p>
                    Achieving a high daily and weekly average is simple. Ensure
                    you listen to your Daily Motivational Audio on time
                    according to your Toggle Target Time and upload an image of
                    your journal writing to MindScribe within 23 hours and 59
                    minutes. This combination results in a high weekly average.
                    Additionally, replaying your Daily Motivational Audio
                    multiple times within 24 hours contributes an extra 1% to
                    your daily average.
                  </p>
                  <div className="modal-action flex justify-center sm:justify-end">
                    <form method="dialog" className="w-full text-white">
                      <button
                        className="btn w-full text-white"
                        onClick={() => setIsInfo(false)}
                      >
                        Close
                      </button>
                    </form>
                  </div>
                </div>
              </dialog>
            </div>
          )}
          {dateModal && (
            <div>
              <dialog
                id="my_modal_5"
                className="modal modal-middle sm:modal-middle"
                open={dateModal}
              >
                <div className="modal-box bg-white text-black p-4 sm:p-8 min-w-[100vw]  max-w-[100vw] min-h-dvh max-h-dvh h-dvh flex flex-col justify-between rounded-none">
                  <div>
                    <h5 className="font-bold text-[1.25rem] leading-[1.5rem] text-black p-[0px] text-center">
                      Monthly Breakdown
                    </h5>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 /">
                    <div className="text-center w-full pb-[3rem]">
                      <input
                        type="month"
                        className="bg-mobile-primary p-1 border rounded-[4px] blackBorder"
                        value={currentDate}
                        defaultValue={currentDate} // Set default value
                        onChange={(e) => setCurrentDate(e.target.value)}
                        onClick={() => setDateModal(true)}
                      />
                    </div>
                    {montlyProgress &&
                      montlyProgress?.map((prog: any) => {
                        return (
                          <div>
                            <div className="flex justify-between mt-5">
                              <p>Week {prog?.weekNumber} Progress</p>
                              <p>{prog?.average}%</p>
                            </div>
                            <div>
                              <progress
                                className="progress progress-primary mt-2 h-3 border bg-mobile-primary text-black mx-auto mb-1"
                                value={prog?.average}
                                max="100"
                                style={{
                                  transition: "width 5s ease",
                                }}
                              ></progress>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="modal-action flex justify-center sm:justify-end">
                    <form method="dialog" className="w-full text-white">
                      <button
                        className="btn w-full text-white"
                        onClick={() => setDateModal(false)}
                      >
                        Close
                      </button>
                    </form>
                  </div>
                </div>
              </dialog>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Streaks;
