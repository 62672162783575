import "./styles/App.css";
import { router } from "./routes/router";
import { RouterProvider } from "react-router-dom";
import { useAppSelector } from "./redux/hooks/hooks";
import { authSelector } from "./redux/slices/authSlice";
import { protectedRouter } from "./routes/protectedRouter";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";

function App() {
  const auth: boolean = useAppSelector(authSelector);
  const token: string | undefined | null = localStorage.getItem("accessToken");

  useEffect(() => {
    // if desktop then redirect to the https://dailysparq.com/
    if (isMobile === false) {
      window.location.replace("https://go.dailysparq.com");
    }
  }, []);

  document.body.style.background = "#F1F0EC";

  return <RouterProvider router={auth || token ? protectedRouter : router} />;
}

export default App;
