import React, { useEffect, useState } from "react";
import drawerLogo from "../../assets/images/daily-mentor-logo.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { toast } from "react-toastify";
import CustomLoaderComponent from "../../components/CustomLoaderComponent/CustomLoaderComponent";

const MotivationalTone = () => {
  const [tone, setTone] = useState("");
  const [toneTagId, setToneTagId] = useState("");
  const [userTags, setUserTags] = useState([]);
  const location = useLocation();
  const navigation = useNavigate();
  const [loader, setLoader] = useState(false);

  const token = localStorage.getItem("accessToken");

  const getAllTags = async () => {
    try {
      const response = await fetch(apiEndPoints.getAllTags, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.responseStatus === 1) {
        setToneTagId(data?.tags?.find((ele: any) => ele.name === "Tone")?._id);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getAllUserTags = async () => {
    const response = await fetch(apiEndPoints.getUserTags, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseStatus === 1) {
      setUserTags(data.userTags.tags);
      const existingTag = data.userTags.tags?.find(
        (ele: any) => ele?.tag?.name === "Tone"
      );
      if (existingTag) {
        setTone(existingTag.name);
      }
    }
    setLoader(false);
  };

  // handle update motivational tone tag for the user
  const updateMotivationalTag = async (tagData: any) => {
    const response = await fetch(apiEndPoints.updateUserTag, {
      method: "POST",
      body: JSON.stringify(tagData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (data.responseStatus === 1) {
      toast.success("Successfully saved");
      // handleLoader(false);
      if (location.pathname === "/about") {
        navigation("/");
      } else {
        //   dispatch(closeDrawer());
        navigation("/");
      }
    } else {
      toast.error(data.error);
    }
  };

  useEffect(() => {
    setLoader(true);
    const getTag = async () => {
      await getAllTags();
      await getAllUserTags();
    };
    getTag();
  }, []);

  const handleSubmit = () => {
    let tagData = {
      typeId: toneTagId,
      name: tone,
    };

    updateMotivationalTag(tagData);
  };

  return (
    <>
      {loader ? (
        <CustomLoaderComponent loader={loader} />
      ) : (
        <div
          className="min-h-dvh max-h-dvh bg-mobile-primary w-full p-5 pt-5"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              className="lg:hidden"
              onClick={() => {
                if (location.pathname === "/about") {
                  // navigation("/signup")
                } else {
                  navigation("/");
                }
              }}
            >
              <IoIosArrowBack color="black" size={30} />
            </div>
            <img
              src={drawerLogo}
              className="block mx-auto w-24"
              style={{ width: "198px", height: "50px", marginBottom: "50px" }}
            />
            <p className="text-black text-center text-xl font-bold mb-3">
              Select your motivational tone
            </p>
            <p className="text-black p-1 text-[14px] text-center">
              Choose the style of daily motivation that resonates best with you.
            </p>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              className={`text`}
              style={{
                fontSize: "20px",
                color: `${tone === "Compassionate" ? "#ffffff" : "#648380"}`,
                padding: "22px 35px",
                textAlign: "left",
                background: `${
                  tone === "Compassionate" ? "#000000" : "#6483801A"
                }`,
                marginBottom: "20px",
                borderRadius: "20px",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => setTone("Compassionate")}
            >
              Compassionate
            </div>
            <div
              className={`text`}
              style={{
                fontSize: "20px",
                color: `${tone === "Direct" ? "#ffffff" : "#648380"}`,
                padding: "22px 35px",
                textAlign: "left",
                background: `${tone === "Direct" ? "#000000" : "#6483801A"}`,
                marginBottom: "20px",
                borderRadius: "20px",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => setTone("Direct")}
            >
              Direct
            </div>
            <div
              className={`text`}
              style={{
                fontSize: "20px",
                color: `${tone === "No-nonsense" ? "#ffffff" : "#648380"}`,
                padding: "22px 35px",
                textAlign: "left",
                background: `${
                  tone === "No-nonsense" ? "#000000" : "#6483801A"
                }`,
                marginBottom: "20px",
                borderRadius: "20px",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => setTone("No-nonsense")}
            >
              No-nonsense
            </div>
          </div>
          <div className="flex justify-center mt-0 w-[100%]">
            <button
              className="w-[100%] bg-[#56B781] h-[45px] rounded-full mt-[15px] mb-[15px] ml-[5px] mr-[5px] text-white"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MotivationalTone;
