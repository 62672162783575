import React from "react";
import { IoChevronForward } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { closeDrawer } from "../../redux/slices/drawerSlice";
import { closePlayer } from "../../redux/slices/mobileMediaSlice";

interface MenuItemProps {
  text: string;
  route: string;
  image?: any;
}

const MenuItemMobile: React.FC<MenuItemProps> = ({ text, route, image }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleDrawer = () => {
    dispatch(closeDrawer());
    if (text === "Target Toggle Time") {
      dispatch(closePlayer());
    }
  };
  return (
    <li>
      <div
        className="flex gap-2 mt-1 py-2"
        style={{ borderBottom: "0.5px solid #cacaca", borderRadius: "0px" }}
      >
        <img src={image} alt="" className="w-[20px]" />
        <Link
          className="flex px-1 flex-row justify-between grow"
          to={route}
          // onClick={handleDrawer}
        >
          <span
            className={`grow text-[15px] ${
              location.pathname === route ? "text-gray-500" : "text-black"
            }`}
            // onClick={handleDrawer}
          >
            {text}
          </span>
          <span>
            <IoChevronForward color="gray" />
          </span>
        </Link>
      </div>
    </li>
  );
};
export default MenuItemMobile;
