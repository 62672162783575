import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import signupSrc from "../../assets/images/signup.png";
import AuthenticationHeader from "../../components/AuthenticationHeader/AuthenticationHeader";
import { Formik, Form } from "formik";
import InputField from "../../components/InputField/InputField";
import { SignupDetails } from "../../interfaces/interfaces";
import Loader from "../../components/Loader/Loader";
import SelectInput from "../../components/SelectInput/SelectInput";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { signupApiCall } from "../../api/apiCalls/apiCalls";
import { signupInfoSchema } from "../../validationSchema/validationSchema";
import { genders } from "../../utils/constant";
import CloseIcon from "@mui/icons-material/Close";

const SignUpDetails = () => {
  const { state } = useLocation();
  const [toggle, setToggle] = useState<boolean>(false);
  const [, setError] = useState<string>("");
  const navigate = useNavigate();
  const [day, setDay] = useState<any>(null);
  const [month, setMonth] = useState<any>(null);
  const [year, setYear] = useState<any>(null);
  const [birthDayError, setBirthDayError] = useState<any>(null);

  const initialValues: SignupDetails = {
    gender: "Male",
  };

  const handleSignup = async (values: SignupDetails) => {
    if (day && month && year) {
      const url = apiEndPoints.signup;
      const data = {
        ...state,
        gender: values.gender,
        birthday: new Date(`${day}/${month}/${year}`),
      };

      signupApiCall(navigate, setError, data, url, `/verifyOtp`, setToggle);
    } else {
      setBirthDayError("Birthday is required");
    }
  };
  const formikRef = useRef<any>(null);

  return (
    <div className="flex items-stretch h-screen">
      <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
        <div className="flex flex-col sm:pr-10 md:pr-20 lg:pr-28 xl:pr-32">
          <div className="flex justify-end mb-10">
            <img
              className="sm:w-44 md:w-52 lg:w-64"
              src={signupSrc}
              alt="hand holding globe"
            />
          </div>
          <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl text-right text-white pr-2">
            Meditation for
          </p>
          <div className="flex justify-end mb-8">
            <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl sm:mt-6 xl:mt-12 md:mt-6 text-white sm:h-7 md:h-8 lg:h-8 xl:h-10 w-9/12 text-right relative bg-[#746C68]">
              <span className="absolute sm:-top-7 lg:-top-5 xl:-top-8 end-3">
                Beginners
              </span>
            </p>
          </div>
          <p className="sm:text-2xl md:text-2xl lg:text-3xl xl:text-4.5xl text-white text-right font-light">
            Mindfulness practice for all
          </p>
        </div>
      </div>
      <div
        data-scroll-lock-scrollable
        className="overflow-y-scroll h-full w-screen sm:w-1/2 bg-web-primary"
      >
        <AuthenticationHeader heading="Sign up" />
        <Formik
          innerRef={formikRef}
          onSubmit={handleSignup}
          initialValues={initialValues}
          validationSchema={signupInfoSchema}
        >
          {({ errors, touched, values }) => {
            const exist = genders.find(
              (value) => value.gender === values.gender
            );
            return (
              <Form className="flex flex-col items-center sm:block h-42 px-4 sm:pl-16.5 w-full ">
                <div className="w-full pl-5 pr-5">
                  {exist ? (
                    <SelectInput id="gender" label="Gender">
                      {errors.gender && touched.gender ? (
                        <div className="text-red-500">{errors.gender}</div>
                      ) : null}
                    </SelectInput>
                  ) : (
                    <div className="relative">
                      <InputField id="gender" label="Gender">
                        {errors.gender && touched.gender ? (
                          <div className="text-red-500">{errors.gender}</div>
                        ) : null}
                      </InputField>
                      <div
                        className="absolute h-[48px] right-[6px] top-[47px] cursor-pointer"
                        onClick={() =>
                          formikRef?.current?.setFieldValue("gender", "Male")
                        }
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  )}

                  <div className="mb-[40px] w-full text-black">
                    <p className="mt-2">Birthday</p>
                    <div className="flex justify-between mt-2">
                      {/* Select Day */}
                      <select
                        id="day"
                        name="day"
                        value={day}
                        onChange={(e) => {
                          setBirthDayError(null);
                          setDay(e.target.value);
                        }}
                        className="border border-gray-300 rounded-[10px] px-2 py-2 bg-transparent"
                      >
                        <option value="">Day</option>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map(
                          (day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          )
                        )}
                      </select>

                      {/* Select Month */}
                      <select
                        id="month"
                        name="month"
                        value={month}
                        onChange={(e) => {
                          setMonth(e.target.value);
                          setBirthDayError(null);
                        }}
                        className="border border-gray-300 rounded-[10px] px-2 py-2 bg-transparent"
                      >
                        <option value="">Month</option>
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                          (month) => (
                            <option key={month} value={month}>
                              {new Date(0, month - 1).toLocaleString("en-US", {
                                month: "long",
                              })}
                            </option>
                          )
                        )}
                      </select>

                      {/* Select Year */}
                      <select
                        id="year"
                        name="year"
                        value={year}
                        onChange={(e) => {
                          setYear(e.target.value);
                          setBirthDayError(null);
                        }}
                        className="border border-gray-300 rounded-[10px] px-2 py-2 bg-transparent"
                      >
                        <option value="">Year</option>
                        {Array.from({ length: 100 }, (_, i) => 2024 - i).map(
                          (year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {birthDayError ? (
                      <div className="text-red-500">{birthDayError}</div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    disabled={toggle}
                    className="btn block mt-2 mb-12 w-full bg-web-secondary text-web-primary disabled:bg-web-containerLight disabled:text-white"
                  >
                    {toggle ? <Loader /> : "Sign Up"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SignUpDetails;
