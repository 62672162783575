import { CgProfile } from "react-icons/cg";
import { AiFillCaretDown } from "react-icons/ai";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { clearAuthorization } from "../../redux/slices/authSlice";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { useEffect, useState } from "react";

const AvatarDropDown = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("accessToken");
  const [profileImage, setProfileImage] = useState<string>("");

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("editToggle");
    dispatch(clearAuthorization());
  };

  const getUserDetails = async () => {
    const response = await fetch(apiEndPoints.getUserProfile, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setProfileImage(data?.user?.imageUrl);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="dropdown dropdown-end">
      <div className="flex items-center pr-2">
        <label tabIndex={0} className="btn flex btn-ghost btn-circle avatar">
          <div className="flex w-10 rounded-full">
            {profileImage ? (
              <img src={profileImage} width={40} height={40} alt="profile" />
            ) : (
              <CgProfile size={40} color="black" />
            )}
          </div>
        </label>
        <AiFillCaretDown color="black"  />
      </div>

      <ul
        tabIndex={0}
        className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-white rounded-box w-52"
      >
        <li className="flex items-center justify-center">
          <p className="text-black cursor-pointer" onClick={handleLogout}>
            Logout
          </p>
        </li>
      </ul>
    </div>
  );
};

export default AvatarDropDown;
