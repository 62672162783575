import { Outlet, useNavigate } from "react-router-dom";
import HomeNavbar from "../components/HomeNavbar/HomeNavbar";
import { useAppDispatch, useAppSelector } from "../redux/hooks/hooks";
import { fetchBoosts } from "../redux/slices/boostSlice";
import { useEffect } from "react";
import {
  screenWidthSelector,
  updateScreenWidth,
} from "../redux/slices/screenSlice";
import { apiEndPoints } from "../api/endPoints/endpoints";
import axios from "axios";

const ProtectedRoot = () => {
  const dispatch = useAppDispatch();
  const screenWidth = useAppSelector(screenWidthSelector);

  const token: string | undefined | null = localStorage.getItem("accessToken");

  const navigate = useNavigate();

  const getAllUserTags = async () => {
    let config = {
      method: "get",
      url: apiEndPoints.getUserTags,
      headers: {
        "sec-ch-ua-mobile": "?0",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.userTags.tags.length === 0) {
          navigate("/about");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getTag = async () => {
      await getAllUserTags();
    };
    if (token?.length) {
      getTag();
    }
  }, [token?.length]);

  useEffect(() => {
    const handleResize = () => {
      dispatch(updateScreenWidth(window.innerWidth));
    };
    dispatch(fetchBoosts());
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative flex sm:flex-col sm:bg-web-primary h-dvh pb-[22px] z-[20]">
      {screenWidth > 1025 && <HomeNavbar />}
      <Outlet />
      {/* {screenWidth > 640 && <MediaPlayer audioUrl={audioUrl} />} */}
    </div>
  );
};

export default ProtectedRoot;
