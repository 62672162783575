import drawerLogo from "../../assets/images/daily-mentor-logo.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import signupSrc from "../../assets/images/signup.png";
import AboutPackage from "../Settings/AboutPackage";
import { useEffect, useState } from "react";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import CustomLoaderComponent from "../../components/CustomLoaderComponent/CustomLoaderComponent";

const AboutU = () => {
  const [allTags, setAllTags] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  const headerText = "About Me";
  const descriptionText =
    "Write down as many words that best describes you and your current situation. This will be helpful in personalizing daily motivational content. Write at least 5 words or short sentences per category e.g. “Millionaire” or “Financial Independence”.";
  const location = useLocation();

  const token = localStorage.getItem("accessToken");
  const getAllUserTags = async () => {
    const response = await fetch(apiEndPoints.getUserTags, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setAllTags(data.userTags.tags);
  };

  useEffect(() => {
    const getTag = async () => {
      await getAllUserTags();
    };
    getTag();
  }, []);

  const handleLoader = (value: boolean) => {
    setLoader(value);
  };

  return (
    <>
      <div className="flex items-stretch h-screen">
        {loader ? (
          <CustomLoaderComponent loader={loader} showMessage={true} />
        ) : (
          <div>
            {location.pathname === "/about" && (
              <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
                <div className="flex flex-col sm:pr-10 md:pr-20 lg:pr-28 xl:pr-32">
                  <div className="flex justify-end mb-10">
                    <img
                      className="sm:w-44 md:w-52 lg:w-64"
                      src={signupSrc}
                      alt="hand holding globe"
                    />
                  </div>
                  <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl text-right text-white pr-2">
                    Meditation for
                  </p>
                  <div className="flex justify-end mb-8">
                    <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl sm:mt-6 xl:mt-12 md:mt-6 text-white sm:h-7 md:h-8 lg:h-8 xl:h-10 w-9/12 text-right relative bg-[#746C68]">
                      <span className="absolute sm:-top-7 lg:-top-5 xl:-top-8 end-3">
                        Beginners
                      </span>
                    </p>
                  </div>
                  <p className="sm:text-2xl md:text-2xl lg:text-3xl xl:text-4.5xl text-white text-right font-light">
                    Mindfulness practice for all
                  </p>
                </div>
              </div>
            )}

            <div className="overflow-y-scroll h-full bg-mobile-primary w-full p-5 pt-5">
              <div
                style={
                  {
                    // padding: '0px 10px',
                  }
                }
              >
                {allTags?.length > 0 && (
                  <div
                    className="lg:hidden"
                    onClick={() => {
                      if (location.pathname === "/about") {
                        // navigation("/signup")
                      } else {
                        navigation("/");
                      }
                    }}
                  >
                    <IoIosArrowBack color="black" size={30} />
                  </div>
                )}
              </div>
              <img
                src={drawerLogo}
                className="block mx-auto w-24"
                alt="drawerLogo"
                style={{ width: "198px", height: "50px", marginBottom: "50px" }}
              />
              <h1 className="text-black text-center text-xl font-bold mb-3">
                {headerText}
              </h1>
              <p className="text-black p-1">{descriptionText}</p>
              <div className=" bg-mobile-primary w-full text-black">
                <AboutPackage handleLoader={handleLoader} />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div className="sm:hidden bg-mobile-primary w-full p-5 pt-5">

      </div> */}
    </>
  );
};

export default AboutU;
