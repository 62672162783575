import { Field } from "formik";
import { PropsWithChildren } from "react";

interface Props {
  label: string;
  id: string;
  toggle?: boolean;
  isPassword?: boolean;
}

const SettingsField = (props: PropsWithChildren<Props>) => {
  return (
    <>
      <label htmlFor={props.id} className="label font-bold">
        <span className="label-text text-web-secondary">{props.label}</span>
      </label>
      {
      // props.toggle &&
        (props.isPassword ? (
          <Field
            id={props.id}
            name={props.id}
            placeholder="********"
            type="password"
            className="input text-web-secondary text-sm input-bordered w-full md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl bg-web-container border-web-inputBorder"
          />
        ) : (
          <Field
            id={props.id}
            name={props.id}
            className="input text-web-secondary text-sm input-bordered w-full md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl bg-web-container border-web-inputBorder"
          />
        ))}

      {props.children}
    </>
  );
};

export default SettingsField;
