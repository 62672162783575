import * as Yup from "yup";

export const updatePasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required('This field is required'),
  reEnterNewPassword: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('newPassword'), ""], 'Passwords must match'),
});

export const signupSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
});

export const signupInfoSchema = Yup.object().shape({
  gender: Yup.string().required("This field is required"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required("This field is required"),
});

export const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'First name can only contain letters')
    .required('First name is required'),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Last name can only contain letters')
    .required('Last name is required'),
})

export const restPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("This field is required"),
  currentPassword: Yup.string().required("This field is required"),
})
