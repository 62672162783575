import { Field } from "formik";

const CheckBox = () => {
  return (
    <label className="flex items-center py-9 gap-x-3">
      <Field type="checkbox" name="toggle" className="checkbox bg-[#bfbfbf] checkbox-success border-none" />
      <span className="text-web-secondary">Remember me</span>
    </label>
  );
};

export default CheckBox;
