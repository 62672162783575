import { BiMenu } from "react-icons/bi";
import MenuItemMobile from "./MenuItemMobile";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  closeDrawer,
  drawerSelector,
  openDrawer,
} from "../../redux/slices/drawerSlice";
import aboutYou from "../../assets/images/aboutIcons.svg";
import aboutUs from "../../assets/images/aboutUs.svg";
import subscription from "../../assets/images/subscription.svg";
import logout from "../../assets/images/log-out 1.svg";
import privacy from "../../assets/images/privacy.svg";
import { RxAvatar } from "react-icons/rx";
import audioPng from "../../assets/images/speaking.png";
// import companyLogo from "../../assets/images/company-logo.svg";
import companyLogo from "../../assets/images/dailysparq-logo.png";

const MobileDrawer = () => {
  const toggleDrawer = useAppSelector(drawerSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<any>("");
  const [profileImage, setProfileImage] = useState<string>("");

  const token = localStorage.getItem("accessToken");
  const getUserDetails = async () => {
    const response = await fetch(apiEndPoints.getUserProfile, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.status === 400) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("editToggle");
      navigate("/");
      window.location.reload();
    }
    const data = await response.json();
    setFirstName(data?.user?.firstName);
    if (data?.user?.lastName) {
      setLastName(data?.user?.lastName);
    }
    setProfileImage(data?.user?.imageUrl);
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const drawer = useAppSelector(drawerSelector);

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("editToggle");
    navigate("/");
    window.location.reload();
    // dispatch(clearAuthorization());
    dispatch(closeDrawer());
  };

  return (
    <div className="drawer drawer-start">
      <input
        id="my-drawer-4"
        type="checkbox"
        className="drawer-toggle"
        checked={toggleDrawer}
      />
      <div className="drawer-content">
        {drawer ? (
          <label htmlFor="my-drawer-4" className="drawer-button">
            <span className="border w-[50px] h-[50px] flex items-center justify-center rounded-full bg-black">
              <BiMenu
                color="white"
                className="px-0"
                size={"30px"}
                onClick={() => dispatch(openDrawer())}
              />
            </span>
          </label>
        ) : (
          <label htmlFor="my-drawer-4" className="drawer-button">
            <span className="border w-[55px] h-[55px] flex items-center justify-center rounded-full bg-black">
              <BiMenu
                color="white"
                className="px-0"
                size={"33px"}
                onClick={() => dispatch(openDrawer())}
              />
            </span>
          </label>
        )}
      </div>
      <div className="drawer-side z-50 overflow-hidden">
        <ul
          className="menu p-1 min-h-full w-full bg-mobile-primary text-black text-2xl z-50"
          aria-label="close sidebar"
        >
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>

          <div className="flex ml-4 mt-3">
            <IoIosArrowBack
              color="black"
              size={30}
              onClick={() => {
                // navigate("/");
                dispatch(closeDrawer());
              }}
            />
          </div>

          <div className="flex items-center flex-col mb-5 pl-3">
            <div className="avatar">
              <div className="rounded-full w-28 h-28">
                {profileImage ? (
                  <img src={profileImage} alt="avatar" />
                ) : (
                  <RxAvatar size={112} color="black" />
                )}
              </div>
            </div>
            <div>
              <p className="text-lg font-bold">
                {firstName ? (
                  <p>
                    {firstName} {lastName && lastName}
                  </p>
                ) : (
                  "User Name"
                )}
              </p>
            </div>
            <div
              onClick={() => {
                dispatch(closeDrawer());
                navigate("/settings/profile");
              }}
              className="flex flex-row justify-between grow items-end"
            >
              <p className="text-sm">View Profile</p>
            </div>
          </div>
          <MenuItemMobile
            image={aboutYou}
            text="About Me"
            route="/settings/about"
          />
          <MenuItemMobile
            image={audioPng}
            text="Motivational Tone"
            route="/settings/motivationalTone"
          />
          {/* <MenuItemMobile image={}  text="Account" route="/" /> */}
          {/* <MenuItemMobile image={personalized} text="Personalized Visual Inspiration" route="/settings/personalized" /> */}
          <MenuItemMobile
            image={privacy}
            text="Privacy"
            route="/settings/privacy"
          />
          {/* <MenuItemMobile
            image={subscribtion}
            text="Manage Subscriptions"
            route="/"
          />
          <MenuItemMobile  text="Contact Us" route="/" />
          <MenuItemMobile image={help} text="Help" route="/" /> */}
          <MenuItemMobile
            image={subscription}
            text="Manage Subscription"
            route="/settings/subscription"
          />
          <MenuItemMobile
            image={aboutUs}
            text="About Us"
            route="/settings/AboutUs"
          />

          <li>
            <div className="flex gap-2 mt-1">
              <img src={logout} alt="" className="w-[20px]" />
              <a
                className="flex px-1 flex-row justify-between grow"
                onClick={handleLogout}
              >
                <span className="grow text-[15px]">Logout</span>
              </a>
            </div>
          </li>
          <li>
            <div className="w-full flex justify-center mt-[5%]">
              <img
                src={companyLogo}
                alt="logo"
                style={{
                  width: "200px",
                  height : "auto"
                }}
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileDrawer;
