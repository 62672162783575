import { FunctionComponent, useEffect, useState } from "react";
import drawerLogo from "../../assets/images/daily-mentor-logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { IoIosArrowBack } from "react-icons/io";
import dayjs from "dayjs";
import {
  getAllToggleTime,
} from "../../redux/slices/toggleTimeSlice";
import axios from "axios";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import { RiDeleteBin6Line } from "react-icons/ri";
import { weekDays } from "../../components/constants/constants";
import signupSrc from "../../assets/images/signup.png";
import { closeDrawer } from "../../redux/slices/drawerSlice";

const ToggleSetup: FunctionComponent = () => {
  const customDaysOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const todayIndex = new Date().getDay();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const headerText = "Target Toggle Time";
  const descriptionText =
    "Set your daily Toggle Target Times. Ideally set to no more than 1 min after your phone’s alarm time.";
  const [isActiveAlarm, ] = useState(false);
  const [isEdit, setIsEdit] = useState<any>(false);

  useEffect(() => {
    dispatch(getAllToggleTime());
  }, [dispatch]);

  const toggleButton = async (days: any, isActive: boolean) => {
    const token = localStorage.getItem("accessToken");
    const response = await axios.put(
      apiEndPoints.isActiveToggleTime,
      {
        days,
        isActive: !isActive,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.responseStatus === 1) {
      dispatch(getAllToggleTime());
    }
  };

  const selectedDays = useAppSelector(
    (state) => state?.toggleSetTime?.selectedDays
  );
  const toggleEdit = useAppSelector((state) => state?.mobileMedia?.toggleEdit);

  const handleToggleTime = () => {
    if (location.pathname === "/settings/toggleAlaramSet") {
      navigation("/settings/setToggleTime");
    } else if (location.pathname === "/settingsWeb/toggleAlaramSet") {
      navigation("/settingsWeb/setToggleTime");
    } else {
      navigation("/setToggleTime");
    }
  };

  const handleJoin = () => {
    dispatch(closeDrawer());
    navigation("/");
  };

  const handleEditAlarm = async (alram: any, id: any) => {
    if (location.pathname === "/settings/toggleAlaramSet") {
      {
        navigation("/settings/editToggleTime", { state: { ...alram, id } });
      }
    } else if (location.pathname === "/settingsWeb/toggleAlaramSet") {
      navigation("/settingsWeb/editToggleTime", { state: { ...alram, id } });
    } else {
      {
        navigation("/editToggleTime", { state: { ...alram, id } });
      }
    }
  };

  const handleDeleteAlarm = async (alarm: any) => {
    const token = localStorage.getItem("accessToken");
    const payload = {
      existingDay: alarm.days,
    };
    const response = await axios.put(apiEndPoints.deleteToggleTime, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.responseStatus === 1) {
      dispatch(getAllToggleTime());
    }
  };

  return (
    <div className="flex items-stretch">
      {location.pathname === "/toggleAlaramSet" && (
        <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
          <div className="flex flex-col sm:pr-10 md:pr-20 lg:pr-28 xl:pr-32">
            <div className="flex justify-end mb-10">
              <img
                className="sm:w-44 md:w-52 lg:w-64"
                src={signupSrc}
                alt="hand holding globe"
              />
            </div>
            <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl text-right text-white pr-2">
              Meditation for
            </p>
            <div className="flex justify-end mb-8">
              <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl sm:mt-6 xl:mt-12 md:mt-6 text-white sm:h-7 md:h-8 lg:h-8 xl:h-10 w-9/12 text-right relative bg-[#746C68]">
                <span className="absolute sm:-top-7 lg:-top-5 xl:-top-8 end-3">
                  Beginners
                </span>
              </p>
            </div>
            <p className="sm:text-2xl md:text-2xl lg:text-3xl xl:text-4.5xl text-white text-right font-light">
              Mindfulness practice for all
            </p>
          </div>
        </div>
      )}
      <div className="bg-mobile-primary w-screen p-5 pt-6 lg:h-screen lg:overflow-y-scroll sm:w-1/2 lg:w-full">
        <div
          className="lg:hidden"
          onClick={() => {
            if (location.pathname === "/settings/toggleAlaramSet") {
              navigation("/");
            } else {
              navigation("/about");
            }
          }}
        >
          <IoIosArrowBack color="black" size={30} />
        </div>

        <div className="flex flex-col">
          <div className=" bg-mobile-primary w-full">
            <img
              src={drawerLogo}
              alt="drawer-logo"
              className="block mx-auto w-[200px] mb-[40px]"
            />
            <h1 className="text-black text-center text-xl font-bold mb-3">
              {headerText}
            </h1>
            <p className="text-black p-1 text-center">{descriptionText}</p>
          </div>
          <div className="flex items-center justify-center">
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                borderRadius: "100%",
                border: "1px solid black",
                alignItems: "center",
                width: "60px",
                height: "60px",
                marginTop: "20px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
              onClick={handleToggleTime}
            >
              <p className="text-black text-2xl font-bold w-[16px]">+</p>
            </button>
          </div>
          <div className="flex flex-col items-center justify-center">
            {/* Date box */}
            {selectedDays.length > 0 ? (
              selectedDays?.map((alram: any, index: number) => {
                const days = alram.days.slice().sort((a: any, b: any) => {
                  return (
                    customDaysOrder.indexOf(a) - customDaysOrder.indexOf(b)
                  );
                });
                const today = alram.days.find(
                  (item: any) => item === weekDays[todayIndex]
                );

                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      height: "120px",
                      borderRadius: "7px",
                      border: "1px solid #4F4F4F",
                      marginTop: "30px",
                      opacity: today && !toggleEdit ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (today && !toggleEdit) {
                        setIsEdit(true);
                      }
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div
                        style={{
                          margin: "10px",
                          width: "90%",
                          pointerEvents: today && !toggleEdit ? "none" : "auto",
                        }}
                        onClick={() => handleEditAlarm(alram, index)}
                      >
                        <h1 className="text-black text-xl mb-3">
                          {dayjs(alram.time).format("h:mm A")}
                        </h1>
                      </div>
                      <div
                        className="mx-[5px]"
                        style={{
                          pointerEvents: today && !toggleEdit ? "none" : "auto",
                        }}
                      >
                        <button
                          className=""
                          onClick={() => handleDeleteAlarm(alram)}
                        >
                          <RiDeleteBin6Line size={20} color="black" />
                        </button>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "25px 10px 0px 10px",
                      }}
                    >
                      <div
                        className="flex gap-2 text-black w-[90%]"
                        onClick={() => handleEditAlarm(alram, index)}
                        style={{
                          pointerEvents: today && !toggleEdit ? "none" : "auto",
                        }}
                      >
                        {days?.map((day: string) => (
                          <p> {day.slice(0, 3)}</p>
                        ))}
                      </div>
                      <div
                        onClick={() => toggleButton(days, alram.isActive)}
                        style={{
                          pointerEvents: today && !toggleEdit ? "none" : "auto",
                        }}
                        className={`flex w-[49px] h-[22px] items-center ${
                          alram?.isActive
                            ? "bg-mobile-toggleSwitch"
                            : "bg-[gray]"
                        } rounded-full self-end transition-all duration-500 border-none`}
                      >
                        <p
                          className={`h-[16px] w-[16px] bg-white rounded-full transition-all duration-500 ${
                            alram?.isActive ? "ml-[27px]" : "ml-1"
                          }`}
                        ></p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "120px",
                    borderRadius: "7px",
                    border: "1px solid #4F4F4F",
                    marginTop: "30px",
                    margin: "10px",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <h1 className="text-xl mb-3">9:00 AM</h1>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "25px 10px 0px 10px",
                    }}
                  >
                    <p>Mon Tue</p>
                    <div
                      className={`flex w-[49px] h-[22px] items-center bg-[gray] rounded-full self-end transition-all duration-500 border-none`}
                      // onClick={toggleButton}
                    >
                      <p
                        className={`h-[16px] w-[16px] bg-white rounded-full transition-all duration-500 ${
                          isActiveAlarm ? "ml-[27px]" : "ml-1"
                        }`}
                      ></p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "120px",
                    borderRadius: "7px",
                    border: "1px solid #4F4F4F",
                    marginTop: "30px",
                    margin: "10px",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <h1 className="text-xl mb-3">9:00 AM</h1>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "25px 10px 0px 10px",
                    }}
                  >
                    <p>Wed</p>
                    <div
                      className={`flex w-[49px] h-[22px] items-center bg-[gray] rounded-full self-end transition-all duration-500 border-none`}
                      // onClick={toggleButton}
                    >
                      <p
                        className={`h-[16px] w-[16px] bg-white rounded-full transition-all duration-500 ${
                          isActiveAlarm ? "ml-[27px]" : "ml-1"
                        }`}
                      ></p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex items-center justify-center grow">
            '
            {
              <button
                className="w-full h-[49px] rounded-[7px] bg-black p-[1%] mt-[15%] mb-[2%] text-white block"
                onClick={handleJoin}
              >
                {location.pathname === "/toggleAlaramSet" ? "Join Now" : "Home"}
              </button>
            }
          </div>
        </div>

        {isEdit && (
          <div>
            <dialog
              id="my_modal_5"
              className="modal modal-middle sm:modal-middle"
              open={isEdit}
            >
              <div className="modal-box bg-white text-black p-4 sm:p-8 relative">
                <button
                  className="absolute top-2 right-2 text-black hover:text-black"
                  onClick={() => setIsEdit(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
                <p className="text-2xl mt-2">
                  You currently have an active Toggle Target Time.{" "}
                  <Link
                    to="/"
                    className="underline underline-offset-[3px] text-bold"
                  >
                    Swipe the toggle
                  </Link>{" "}
                  to edit any future Toggle Target Time.
                </p>
              </div>
            </dialog>
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleSetup;
