import React from "react";
import { Field } from "formik";
import { PropsWithChildren } from "react";
import { genders } from "../../utils/constant";

interface Props {
  label: string;
  id: string;
  placeholder?: string;
}

const SelectInput = (props: PropsWithChildren<Props>) => {
  return (
    <>
      <label htmlFor={props.id} className="label">
        <span className="label-text text-web-secondary">{props.label}</span>
      </label>
      <Field
        id={props.id}
        name={props.id}
        as="select"
        className="input text-web-secondary input-bordered w-full bg-web-primary border-web-inputBorder blackborder"
      >
        <option value="Male" selected>
           Male
        </option>
        {genders
          ?.filter((val) => val.id !== 0)
          .map((item) => {
            return (
              <option key={item?.id} value={item.gender}>
                {item?.gender}
              </option>
            );
          })}
        <option value="">Custom</option>
      </Field>
      {props.children}
    </>
  );
};

export default SelectInput;
