import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import MobileNavbar from "../../components/HomeNavbar/MobileNavbar";
import cameraIcon from "../../assets/images/Camera.svg";
import mindLine from "../../assets/images/mindScribe.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import { useAppSelector } from "../../redux/hooks/hooks";
import { screenWidthSelector } from "../../redux/slices/screenSlice";
import { HiDotsVertical } from "react-icons/hi";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Mindscribe = () => {
  const [mindscribeImage, setMindscribeImage] = useState<string>("");
  const [mindscribes, setMindscribes] = useState<string[]>([]);
  const screenWidth = useAppSelector(screenWidthSelector);
  const token = localStorage.getItem("accessToken");
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [isSelectedOpen, setIsSelectedOpen] = useState<any>(false);
  const [isSelected, setIsSelected] = useState<any>(false);
  const [isMultiSelected, setIsMultiSelected] = useState<any>(false)


  const getAllMindscribes = async () => {
    const url = apiEndPoints.getAllMindscribes;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setMindscribes([...data.userMindscribes.mindscribes]);
  };

  useEffect(() => {
    getAllMindscribes();
  }, [mindscribeImage]);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target?.files?.[0];

    if (file) {
      const reader = new FileReader();
      const formData = new FormData();

      const newFileName = `${new Date()}+${file.name}`;

      formData.append("image", file, newFileName);

      const uploadUrl = apiEndPoints.addMindscribe;

      try {
        const response = await axios.post(uploadUrl, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        reader.onloadend = () => {
          setMindscribeImage(reader.result as string);
        };

        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleDeleteImage = async () => {
    await Promise.all(selectedImages?.map(async (imageUrl, index) => {
      const response = await axios.put(
        apiEndPoints.deleteMindScribeImage,
        { imageUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.responseStatus === 1 && index === selectedImages.length - 1) {
        setMindscribeImage(response.data.response);
      }
    }));
    setIsMultiSelected(false)
    setIsSelectedOpen(false)
    setIsSelected(false)
    setSelectedImages([]);
  };

  const handleToggleSelect = (url: string) => {
    const index = selectedImages.indexOf(url);
    if (index === -1) {
      setSelectedImages([...selectedImages, url]);
    } else {
      const newSelectedImages = [...selectedImages];
      newSelectedImages.splice(index, 1);
      setSelectedImages(newSelectedImages);
    }
  };

  const handleSingleSelect = () => {
    setIsSelected(!isSelected);
    setIsSelectedOpen(false)
    setIsMultiSelected(false)
    setSelectedImages([]);
  };

  const handleMultiSelect = async () => {
    setIsSelected(false);
    setIsSelectedOpen(false)
    setIsMultiSelected(!isMultiSelected);
    if (!isMultiSelected) {
      const allUrls = mindscribes.map(image => image);
      setSelectedImages(allUrls);
    } else {
      setSelectedImages([])
    }

  }

  return (
    <div className="flex flex-col h-screen text-black bg-mobile-primary">
      <div className="flex flex-col flex-grow">
        {screenWidth < 1025 && <MobileHeader />}
        <div className="flex justify-between px-2 items-center mt-[0.95rem]">
          <div className="w-10 mb-3 relative">
            <HiDotsVertical size={30} onClick={() => setIsSelectedOpen(!isSelectedOpen)} />
            {
              isSelectedOpen && <div className=" bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute">
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                  <li onClick={handleSingleSelect}>
                    <p className={`block px-4 py-2 ${isSelected ? "bg-gray-500 text-white" : ""}`}>Select</p>
                  </li>
                  <li onClick={handleMultiSelect}>
                    <p className={`block px-4 py-2 ${isMultiSelected ? "bg-gray-500 text-white" : ""}`}>Select All</p>
                  </li>
                </ul>
              </div>
            }
          </div>

          <div className="pl-5">
            <h2 className="text-2xl text-black font-bold">MindScribe</h2>
            <img src={mindLine} alt="mindLine" style={{ width: "100%" }} />
          </div>
          <div>
            <Button
              id="capturePhotoButton"
              component="label"
              style={{
                marginBottom: "15px",
                backgroundColor: "transparent",
                paddingTop: "10px",
              }}
            >
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {/* <AiOutlineCamera size={60} /> */}
              <img
                src={cameraIcon}
                alt="cameraIcon"
                style={{ width: 45, height: 45 }}
              />
            </Button>
          </div>
        </div>

        <div className="rounded-box w-100 flex-grow px-4 mt-5  bg-mobile-primary" style={{ overflowY: "scroll" }}>
          <p className="grow mb-8 lg:px-4 px-2 text-black">
            Write down in a notebook what you’d like to accomplish today. A free
            flow of thoughts. Once you’re done, take a clear picture and upload.{" "}
          </p>
          {
            selectedImages.length > 0 && <div className="w-full mt-3 mb-3">
              <button
                onClick={handleDeleteImage}
                className="btn block w-full text-white bg-web-secondary text-web-primar"
              >
                Delete ({selectedImages.length})
              </button>
            </div>
          }
          <div className="grid xl:grid-cols-4 grid-cols-3 mt-2 px-0 lg:px-5 lg:gap-4 gap-[8px]">
            {mindscribes.map((src, index) => {
              return (
                <div
                  key={index}
                  className="relative lg:w-full lg:h-[184px] w-[110px] h-[108px] rounded-[3px]"
                >
                  <img
                    src={mindscribes[index]}
                    className="lg:w-full lg:h-[184px] w-[113px] h-[111px] rounded-[3px]"
                  />
                  {/* <button
                className="absolute top-1 right-1 bg-black p-1 rounded-full"
                onClick={() => handleDeleteImage(mindscribes[index])}
              >
                <RiDeleteBin6Line size={18} color="#fff" />
              </button> */}
                  {
                    (isSelected || isMultiSelected) && <input
                      className="absolute top-1 right-1 bg-black p-1 rounded-full"
                      type="checkbox"
                      checked={selectedImages.includes(src)}
                      onChange={() => handleToggleSelect(src)}
                    />
                  }
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {screenWidth < 1025 && <MobileNavbar />}
    </div>

  );
};

export default Mindscribe;
