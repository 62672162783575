import { useCallback, useState, FC, useEffect } from "react";
import ImageCropper from "./ImageCroper";
import AppSlider from "./AppSlider";
import { BiUpload } from "react-icons/bi";
import { apiEndPoints } from "../../../api/endPoints/endpoints";
import axios from "axios";
import { toast } from "react-toastify";

interface Props {
  selectedImage: File[];
  setSelectedFile: any;
  setIsProfileUpdated: any;
}

export const CropImage: FC<Props> = ({
  selectedImage,
  setIsProfileUpdated,
  setSelectedFile,
}) => {
  const [remoteImage] = useState("");
  const [localImage, setLocalImage] = useState("");
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState<Blob>();
  const [rotation, setRotation] = useState(0);
  const token = localStorage.getItem("accessToken");

  const isImageSelected = remoteImage || localImage ? true : false;

  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //     setRemoteImage("");
  //     setLocalImage(URL.createObjectURL(acceptedFiles[0]));
  // }, []);

  useEffect(() => {
    if (selectedImage.length > 0) {
      setLocalImage(URL.createObjectURL(selectedImage[0]));
    }
  }, [selectedImage]);

  const handleOnZoom = useCallback((zoomValue: number) => {
    setZoom(zoomValue);
  }, []);

  const handleOnRotation = useCallback((rotationValue: number) => {
    setRotation(rotationValue);
  }, []);

  const downloadImage = async () => {
    // if (!croppedImage) return;
    // const link = document.createElement("a");
    // const name = `${Date.now()}_wallpaper`;
    // link.download = name;
    // link.href = URL.createObjectURL(croppedImage);
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    if (!croppedImage) return;
    const formData = new FormData();
    formData.append("userImage", croppedImage, `${new Date()}.png`);
    const uploadUrl = apiEndPoints.updateUser;

    const response = await axios.post(uploadUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;

    if (data.responseStatus === 1) {
      setIsProfileUpdated(true);
      setSelectedFile([]);
    } else {
      setSelectedFile([]);
      toast.error(data.error);
    }
  };

  if (!isImageSelected)
    return (
      <div className="space-y-4 w-full p-4">
        {/* <FileDropZone onDrop={onDrop} /> */}
      </div>
    );

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="lg:w-96 lg:flex-shrink-0 p-4">
        {/* <FileDropZone onDrop={onDrop} /> */}

        <div className="flex-1 p-4 flex items-center justify-center">
          <ImageCropper
            zoom={zoom}
            onZoomChange={handleOnZoom}
            rotation={rotation}
            onRotationChange={setRotation}
            source={remoteImage || localImage}
            onCrop={setCroppedImage}
            width={400}
            height={400}
          />
        </div>

        <AppSlider
          min={0}
          max={360}
          defaultValue={0}
          value={rotation}
          label="Rotate"
          onChange={handleOnRotation}
        />

        <AppSlider
          min={1}
          max={3}
          value={zoom}
          label="Pinch to Zoom"
          defaultValue={1}
          onChange={handleOnZoom}
        />

        <button
          className="flex gap-1 mt-4 items-center justify-center p-2 bg-black transition rounded uppercase text-white w-full drop-shadow"
          onClick={downloadImage}
        >
          <BiUpload size={22} />
          <p>Upload</p>
        </button>
      </div>
    </div>
  );
};
