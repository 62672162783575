import InputField from "../../components/InputField/InputField";
import CheckBox from "../../components/CheckBox/CheckBox";
import AuthenticationHeader from "../../components/AuthenticationHeader/AuthenticationHeader";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { useEffect } from "react";
import { LoginData, LoginValues } from "../../interfaces/interfaces";
// import { loginApiCall } from "../../api/apiCalls/apiCalls";
import { loginSchema } from "../../validationSchema/validationSchema";
import bannerSrc from "../../assets/images/banner.png";
import Loader from "../../components/Loader/Loader";
import { loginApiCall } from "../../redux/slices/authSlice";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

const Login = () => {
  const dispatch = useAppDispatch();
  const classes = {
    active: "bg-web-secondary text-web-primary",
    inactive:
      "hover:bg-web-secondary hover:text-white bg-web-container text-black",
  };

  const currentTime = new Date();
  const timeDifferenceInMinutes = currentTime.getTimezoneOffset();

  const isLoading = useAppSelector((state) => state?.userAuth?.isLoading);
  const location = useLocation();

  useEffect(() => {
    // Disable page scroll when the component mounts
    disablePageScroll();

    // Enable page scroll when the component unmounts
    return () => enablePageScroll();
  }, []);

  const initialValues: LoginValues = {
    email: "",
    password: "",
    toggle: false,
  };
  // showErrorToaster(error);

  const handleLogin = (values: LoginValues) => {
    const data: LoginData = {
      email: values.email.toLowerCase(),
      password: values.password,
      timeDifference: timeDifferenceInMinutes,
      isAdmin: false,
    };

    dispatch(loginApiCall(data));
  };

  return (
    <div className="flex h-screen">
      <div className="justify-center items-center w-1/2 hidden sm:flex bg-web-container">
        <div className="flex items-center flex-col w-4/5 bg-web-primary rounded-t-full">
          <img
            className="sm:mt-8 md:mt-10 lg:mt-12 xl:mt-12 sm:mb-5 md:mb-5 lg:mb-8 xl:mb-10 h-2/3 sm:w-20 md:w-28 lg:w-32 xl:w-36"
            src={bannerSrc}
            alt="flower"
          />
          <p className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-web-secondaryDark sm:mb-4 md:mb-4 lg:mb-7 xl:mb-7">
            Feel the <br />
            inner peace
          </p>
          <p className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-web-secondary sm:mb-5 md:mb-8 lg:mb-10 xl:mb-14 font-normal">
            and you will find your true self
          </p>
        </div>
      </div>
      <div
        data-scroll-lock-scrollable
        className="w-screen h-full overflow-y-scroll sm:w-1/2 bg-web-primary"
      >
        <AuthenticationHeader heading="Log In" />
        <div className="ml-[35px] mr-[35px] lg:ml-[91px] lg:mr-[36px] mb-3">
          <div className="grid grid-cols-2">
            <NavLink
              to={"/"}
              className={
                location?.pathname !== "/signup"
                  ? classes.active
                  : classes.inactive
              }
            >
              <button className="h-[45px] w-full font-bold">LOG IN</button>
            </NavLink>
            <NavLink
              to={"/signup"}
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <button className="h-[45px] font-bold w-full">SIGN UP</button>
            </NavLink>
          </div>
        </div>
        <Formik
          onSubmit={handleLogin}
          initialValues={initialValues}
          validationSchema={loginSchema}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col items-center sm:block h-42 px-4 sm:pl-16.5 w-full ">
              <div className="w-full pl-5 pr-5">
                <InputField id="email" label="Email Address">
                  {errors.email && touched.email ? (
                    <div className="text-red-500">{errors.email}</div>
                  ) : null}
                </InputField>

                <InputField id="password" label="Password">
                  {errors.password && touched.password ? (
                    <div className="text-red-500">{errors.password}</div>
                  ) : null}
                  <p className="w-full mt-5 text-end block text-web-secondary font-bold">
                    <Link to="/forgotPassword">Forgot Password?</Link>
                  </p>
                </InputField>
                <CheckBox />
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn block mt-2 mb-12 w-full bg-web-secondary text-web-primary disabled:bg-web-containerLight disabled:text-white"
                >
                  {isLoading ? <Loader /> : "Log In"}
                </button>
              </div>
              {/* {error.length > 0 && <div className="text-red-500">{error}</div>} */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
