import { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks/hooks";

interface ProgressbarProps {
  isPlay: any;
}

const ProgressBarData: React.FC<ProgressbarProps> = ({ isPlay }) => {
  const [currentWeek, setCurrentWeek] = useState(0);
  const [remainingWeeks, setRemainingWeeks] = useState(0);
  const currentStreaks = useAppSelector(
    (state) => state?.mobileMedia?.currentStreak
  );

  useEffect(() => {
    const today: any = new Date();
    const startOfYear: any = new Date(today.getFullYear(), 0, 1);

    const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
    const weekNumber = Math.ceil((today - startOfYear) / millisecondsInWeek);

    setCurrentWeek(weekNumber);
    setRemainingWeeks(52 - weekNumber);
  }, []);

  const isToggleActive = useAppSelector(
    (state) => state?.mobileMedia?.isStreakAnimate
  );
  return (
    <div className="relative w-full">
      <div className="flex items-center justify-center max-h-[40px] min-h-[40px]">
        {/* {isPlay || isAnimationClassRequired ? (
          <img
            src={animationScreenGif}
            alt="progress bar"
            className="fixed h-[100vh] w-[100vw] top-0 left-0 z-99"
          />
        ) : (
          <img
            src={animationScreenGif}
            alt="progress bar"
            className="fixed h-[100vh] w-[100vw] top-0 left-0 z-99"
          />
        )} */}
        <div
          style={{
            height: "40px",
            width: "90%",
            borderRadius: "20px",
            backgroundColor: "#56B781",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {currentStreaks} day streak
        </div>
      </div>
      <div className="flex justify-center w-[90%] mx-auto my-[6px]">
        <span className="px-1 text-[18px]">Week {currentWeek} out of 52 |</span>
        <span className="text-[18px]">
          {remainingWeeks} {remainingWeeks === 1 ? "week" : "weeks"} left
        </span>
      </div>
    </div>
  );
};

export default ProgressBarData;
