import React from "react";
import drawerLogo from "../../assets/images/daily-mentor-logo.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigation = useNavigate();

  const headerText = "About Us";
  const descriptions =
    "Welcome to The Dailysparq, if you’re reading this- we believe you woke up for a reason! Whether you're a dream-chaser, a go-getter, or simply someone looking for a little extra encouragement, you're in the right place. At Dailysparq, we firmly believe that inspiration, personal growth, and success are for everyone—no matter where you're in life.";

  return (
    <>
      <div className="overflow-y-scroll h-screen bg-mobile-primary w-full p-5 pt-5 pb-20 text-black">
        <div>
          <div
            className="lg:hidden"
            onClick={() => {
              navigation("/");
            }}
          >
            <IoIosArrowBack color="black" size={30} />
          </div>
        </div>
        <img
          src={drawerLogo}
          className="block mx-auto w-24"
          alt="drawerLogo"
          style={{ width: "198px", height: "50px", marginBottom: "50px" }}
        />
        <h1 className="text-black text-xl font-bold mb-3">{headerText}</h1>
        <p className="text-black">{descriptions}</p>
        {/* <div className=" bg-mobile-primary w-full mt-2">
          <h1 className="font-bold text-2xl">Dailysparq will help you</h1>
        </div> */}

        <div className="w-full mt-3">
          <h1 className="font-bold text-xl">Our Mission</h1>
          <p className="mt-1">
            Our mission is simple: to help you unlock your full potential and
            pursue your goals, whatever they may be. We’re here to provide the
            motivation, guidance, and support you need every morning. Life can
            be tough, and we all face challenges, but Dailysparq is your daily
            motivator and reminder that you’re not alone on this journey.
          </p>
          <p className="mt-1">
            Join our community, where your dreams are treated as plans waiting
            to be realized. Whether you're just beginning to believe in yourself
            or you're already on your path to success, Dailysparq is here to
            walk beside you.
          </p>
        </div>
        <div className="mt-3">
          <h1 className="font-bold text-xl">
            Ready to kick mediocrity to the curb?
          </h1>
          <p>
            Welcome to Dailysparq—where we’re not just supporters; we’re
            partners in your journey. Together, we’ll turn your goals into
            reality, one step at a time.
          </p>
        </div>
        <div className="mt-3">
          <h1 className="font-bold text-xl">Contact Us:</h1>
          <p>
            Any questions/concerns regarding your subscription please email
            <a href="mailto:help@dailysparq.com" className="ml-1 text-blue-500">help@dailysparq.com</a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
