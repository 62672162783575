import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";

import {
  addToggleTimeScoreOnPlaySong,
  closePlayer,
  getPlayedTimeBoost,
  getToggleTimeTodayScore,
  mobileMediaSelector,
  toggleEnable,
  toggleOneTimePlay,
  toggleUnable,
} from "../../../redux/slices/mobileMediaSlice";
import Slider from "@mui/material/Slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickSlider from "react-slick";
import arrowHome from "../../../assets/images/Arrow.svg";
import shareIcon from "../../../assets/images/share_black.svg";
import fillSave from "../../../assets/images/filled.svg";
import unFilledSave from "../../../assets/images/outlined.svg";
import axios from "axios";
import { apiEndPoints } from "../../../api/endPoints/endpoints";
import moment from "moment";
import ProgressBarData from "../ProgressBar";
import deafultInspiration from "../../../assets/images/deafultInspiration.svg";
import "./style.css";
import { screenWidthSelector } from "../../../redux/slices/screenSlice";
// import animation from "../../../assets/images/star_animation.gif";
import AudioPlayerr from "./AudioPlayerr";
import audioFile from "../../../assets/audio2.mp3";
import forward from "../../../assets/images/forward-10-seconds.svg";
import backward from "../../../assets/images/rewind-10-seconds.svg";
import MobileNavbar from "../../HomeNavbar/MobileNavbar";
import playIcon from "../../../assets/images/playIcon.svg";
import pauseIcon from "../../../assets/images/pauseIcon.svg";
import { Link } from "react-router-dom";
import CustomLoaderComponent from "../../CustomLoaderComponent/CustomLoaderComponent";
import { getPstDate } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

const AudioHighlightWithPlayer = ({ boost, isSharePage }) => {
  const navigation = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useAppDispatch();
  const mobileMedia = useAppSelector(mobileMediaSelector);
  const userDetails = useAppSelector((state) => state?.userAuth?.userDetails);
  const [progress, setProgress] = useState(0);
  const [, setIsPlaying] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [upComingTime, setUpComingTime] = useState(null);
  const [isPlayingOnce, setIsPlayingOnce] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [musicPlayed, setMusicPlayed] = useState("0:00");
  const [duration, setDuration] = useState("0:00");
  const playerRef = useRef(new Audio(audioFile));
  const [loader, setLoader] = useState(false);
  const [showDisableScreen, setShowDisableScreen] = useState(false);
  const [isTrialPeriodExpired, setTrialPeriodExpired] = useState(false);

  const getToggleTimeToday = async () => {
    const token = localStorage.getItem("accessToken");
    const time = new Date();

    const response = await axios.post(
      apiEndPoints.getTodayToggleTimeScore,
      { currentTime: time.toLocaleString() },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShowDisableScreen(response?.data?.response?.toggleScore ? true : false);
    setLoader(false);
    return;
  };

  const checkTrialPeriod = async () => {
    const token = localStorage.getItem("accessToken");
    const userResponse = await fetch(apiEndPoints.getUserProfile, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const userData = await userResponse.json();
    const subscriptionResponse = await fetch(
      apiEndPoints.getSubscriptionDetails,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const subData = await subscriptionResponse.json();
    if (
      moment().diff(moment(userData?.user?.createdOn), "d") > 7 &&
      (!subData.data || subData?.data?.status !== "active")
    ) {
      setTrialPeriodExpired(true);
    }
  };

  useEffect(() => {
    setLoader(true);
    checkTrialPeriod();
    getToggleTimeToday();
  }, []);

  const handlePlay = () => {
    if (playerRef.current) {
      setIsPlaying(true);
      setPlaying(true);
      setIsPlay(true);
      // window.speechSynthesis.cancel();
      if (!isPlayingOnce) {
        dispatch(addToggleTimeScoreOnPlaySong(boost?._id));
        setTimeout(() => {
          dispatch(getToggleTimeTodayScore());
          dispatch(getPlayedTimeBoost(boost?._id));
        }, 1000);
      }

      setIsPlayingOnce(true);

      const player = playerRef.current;

      if (typeof player.play === "function") {
        player.play();
      }
    }
  };

  const handlePause = () => {
    if (playerRef.current) {
      setIsPlaying(false);
      setPlaying(false);

      const player = playerRef.current;

      if (typeof player.pause === "function") {
        player.pause();
      }
    }
  };

  const token = localStorage.getItem("accessToken");

  const handleSave = async () => {
    if (boost?._id) {
      const payload = {
        boostId: boost?._id,
      };
      const res = await axios.post(apiEndPoints.saveBoost, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.responseStatus === 1) {
        setIsSave(true);
      }
    }
  };

  const handleRemoveSave = async () => {
    if (boost?._id) {
      const payload = {
        boostId: boost?._id,
      };
      const res = await axios.post(apiEndPoints.removeBoost, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.responseStatus === 1) {
        setIsSave(false);
      }
    }
  };

  const toggleScore = useAppSelector(
    (state) => state?.mobileMedia?.toggleScore
  );

  const playedTime = useAppSelector((state) => state?.mobileMedia?.boostPlayed);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (toggleScore) {
        dispatch(toggleOneTimePlay());
      }
    }, 2000);
  }, [toggleScore, dispatch]);

  useEffect(() => {
    dispatch(getToggleTimeTodayScore());
    setTimeout(() => {
      dispatch(getPlayedTimeBoost(boost?._id));
    }, 1000);
  }, [mobileMedia, boost, dispatch]);

  useEffect(() => {
    if (boost) {
      const getUserBoostById = async () => {
        const res = await axios.get(
          `${apiEndPoints.getSaveBoostById}/${boost?._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (
          res.data.responseStatus === 1 &&
          res.data.response?.isActive === true
        ) {
          setIsSave(true);
        } else {
          setIsSave(false);
        }
      };
      getUserBoostById();
    }
  }, [boost, token]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    dotsClass: "slider-dots",
  };

  useEffect(() => {
    dispatch(toggleEnable());
    let isActive = false;
    const updateUpcomingTime = async () => {
      const currentTime = getPstDate(new Date());
      const midNightTime = getPstDate(new Date());

      midNightTime.setHours(23);
      midNightTime.setMinutes(59);
      midNightTime.setSeconds(59);

      const remainingTimeMillis =
        midNightTime.getTime() - currentTime.getTime();

      // Convert milliseconds to hours, minutes, and seconds
      const remainingHours = Math.floor(remainingTimeMillis / (1000 * 60 * 60));
      const remainingMinutes = Math.floor(
        (remainingTimeMillis % (1000 * 60 * 60)) / (1000 * 60)
      );
      const remainingSeconds = Math.floor(
        (remainingTimeMillis % (1000 * 60)) / 1000
      );

      setUpComingTime({
        hours: String(remainingHours).padStart(2, "0"),
        minutes: String(remainingMinutes).padStart(2, "0"),
        seconds: String(remainingSeconds).padStart(2, "0"),
      });

      const newTime = moment(currentTime).format("hh:mm a");

      if (newTime === "12:00 am" && !isActive) {
        //  getToggleTimeTodayScore()
        dispatch(toggleUnable());
        isActive = true;
        dispatch(closePlayer());
        dispatch(toggleEnable());
      }
    };
    setInterval(updateUpcomingTime, 1000);
  }, [dispatch]);

  const [animationClass, setAnimationClass] = useState("");
  const [isNumberPopUp, setIsNumberPopUp] = useState(false);

  useEffect(() => {
    if (isPlay) {
      setAnimationClass("animate__animated animate__bounceIn");
      const timeout = setTimeout(() => {
        setAnimationClass("");
      }, 3000);
      setTimeout(() => {
        setIsNumberPopUp(true);
        const times = setTimeout(() => {
          setIsNumberPopUp(false);
        }, 1000);
        return () => clearTimeout(times);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isPlay]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          text: `${process.env.REACT_APP_LIVE}/share/${boost?._id}`,
        });
      } catch (error) {
        console.error("Error sharing audio:", error);
      }
    } else {
      console.log("Web Share API is not supported on this browser.");
    }
  };

  const handleForwards = () => {
    const player = playerRef.current;
    if (player) {
      player.currentTime = player.currentTime + 10;
    }
  };

  const handleRewinds = () => {
    const player = playerRef.current;
    if (player) {
      player.currentTime = player.currentTime - 10;
    }
  };

  const handleSwipe = () => {
    if (currentIndex === 0) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(0);
    }
  };

  useEffect(() => {
    let audioEle = document.getElementById(
      `dynamic-main-audio-boost-${boost._id}`
    );
    if (audioEle) {
      const player = playerRef.current;
      player?.addEventListener("timeupdate", () => {
        const durationInSeconds = playerRef.current.currentTime;
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        setMusicPlayed(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      });
    }
  }, [boost._id, playerRef.current]);

  const screenWidth = useAppSelector(screenWidthSelector);
  return (
    <>
      {loader ? (
        <CustomLoaderComponent loader={loader} />
      ) : (
        <>
          <div className="grow flex flex-col relative items-center justify-center mt-[10px]">
            {showDisableScreen || mobileMedia ? (
              <>
                <ProgressBarData isPlay={animationClass} />
                <>
                  {currentIndex === 0 ? (
                    <div
                      onDragEnd={handleSwipe}
                      onDragStart={handleSwipe}
                      style={{ padding: "17px 30px" }}
                      className="hightlight-text-container grow item-stretch mx-[2%] rounded-t-[20px] flex flex-col justify-center leading-10 bg-black text-white h-44"
                    >
                      {boost && (
                        <AudioPlayerr
                          playerRef={playerRef}
                          playing={playing}
                          setPlaying={setPlaying}
                          setDuration={setDuration}
                          setProgress={setProgress}
                          setIsPlayingOnce={setIsPlayingOnce}
                          setIsPlay={setIsPlay}
                          isShare={false}
                          audioFile={boost?.audioUrl}
                          ssmlText={boost?.text}
                          speechMarks={boost?.speechMarks[0]}
                          id={`dynamic-main-audio-boost-${boost._id}`}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className="grow border item-stretch w-[100%] mx-0 text-center overflow-y-hidden text-2xl leading-10 bg-black text-white mb-1 h-44"
                      onDragEnd={handleSwipe}
                      onDragStart={handleSwipe}
                    >
                      <SlickSlider
                        {...settings}
                        className="w-full h-[95%]"
                        arrows={false}
                        autoplay
                        autoplaySpeed={10000}
                      >
                        {boost && boost.imageUrl.length > 0 ? (
                          boost.imageUrl.map((item, index) => (
                            <div key={index} className="slick-center h-full">
                              <div className="flex items-center justify-center h-full">
                                <img
                                  className="object-contain h-full max-w-full my-auto"
                                  src={item}
                                  alt="item"
                                />
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="slick-slide h-full slick-center">
                            <div className="h-full flex items-center justify-center">
                              <img
                                src={deafultInspiration}
                                alt="Default inspiration"
                                className="max-w-full h-auto object-contain"
                              />
                            </div>
                          </div>
                        )}
                      </SlickSlider>
                    </div>
                  )}
                </>
              </>
            ) : (
              <>
                {isTrialPeriodExpired && (
                  <div className="grow item-stretch w-full mx-0 text-center text-lg sm:text-2xl leading-8 sm:leading-10 text-black px-[2%] lg:px-[70px] sm:px-5 py-0 sm:py-6 bg-white">
                    <div className="sm:pb-32 h-full bg-[#ffffff] rounded-t-[40px]">
                      <div className="pt-[8rem] h-[50%] flex flex-col justify-between mt-[-10%]">
                        <div>
                          <p
                            className="text-[32px] leading-[35px] w-full lg:w-full pr-[50px] pl-[50px]"
                            style={{
                              fontFamily: `'Satoshi', sans-serif`,
                            }}
                          >
                            {userDetails?.firstName}, <br />
                            Your free trial period <br />
                            has ended. Please
                            <br />
                            click button below
                            <br />
                            to continue.
                          </p>
                        </div>
                        <div className="flex justify-center mt-4 w-[100%]">
                          <button
                            className="w-[250px] bg-[#56B781] h-[45px] rounded-full mt-[15px] mb-[15px] ml-[5px] mr-[5px] text-white"
                            onClick={() => navigation("/settings/subscription")}
                          >
                            Start Subscription
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isTrialPeriodExpired && (
                  <div className="grow item-stretch w-full mx-0 text-center text-lg sm:text-2xl leading-8 sm:leading-10 text-black px-[2%] lg:px-[70px] sm:px-5 py-0 sm:py-6 bg-white">
                    <div className="sm:pb-32 h-full bg-[#ffffff] rounded-t-[40px]">
                      {/* <div className=""> */}
                      <img
                        src={arrowHome}
                        alt="arrow-home"
                        // style={{ height: "140px", marginLeft: "-30px" }}
                        className="h-[140px] ml-[-30px] lg:h-[200px] lg:ml-[-40px]"
                      />
                      {/* </div> */}
                      <div className="h-[50%] flex flex-col justify-between mt-[-10%]">
                        <div>
                          <p
                            className="text-[32px] leading-[35px] w-full lg:w-full pr-[50px] pl-[50px]"
                            style={{
                              fontFamily: `'Satoshi', sans-serif`,
                            }}
                          >
                            {userDetails?.firstName}, <br />
                            swipe the toggle <br />
                            to start your <br />
                            superhuman day!
                          </p>
                        </div>
                        <div className="mx-4 sm:mx-8">
                          {upComingTime ? (
                            <>
                              <p className="text-black text-[22px] mt-[10%] sm:mt-2 pr-[36px] pl-[36px]">
                                Your custom audio will disappear after 24 hours.
                              </p>
                              <h3
                                className={`text-[2.4rem] font-extrabold mt-[10%] ${upComingTime?.hours === "00" &&
                                  upComingTime?.minutes === "00" &&
                                  upComingTime?.seconds !== "00"
                                  ? "text-green-500"
                                  : "text-black"
                                  }`}
                              >
                                {upComingTime?.hours}:{upComingTime?.minutes}:
                                {upComingTime?.seconds}
                              </h3>
                              <div className="absolute bottom-0 left-8 font-semibold text-[8px] w-[85%] text-center">
                                Audio resets at 12a PST | 2a CST | 3a EST
                              </div>
                            </>
                          ) : upComingTime === "time" ? (
                            <>
                              <p className="text-2xl text-center">
                                {/* Go to Menu, then to Toggle Target Time and set up your
                      Target Toggle Time */}
                                Your custom audio will disappear after 24 hours.
                              </p>
                              <h3
                                className={`text-[2.4rem] font-extrabold mt-3 ${upComingTime?.hours === "00" &&
                                  upComingTime?.minutes === "00" &&
                                  upComingTime?.seconds !== "00"
                                  ? "text-green-500"
                                  : "text-black"
                                  }`}
                              >
                                {upComingTime?.hours}:{upComingTime?.minutes}:
                                {upComingTime?.seconds}
                              </h3>
                              <div className="absolute bottom-0 left-8 font-semibold text-[8px] w-[85%] text-center">
                                Audio resets at 12a PST | 2a CST | 3a EST
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {(showDisableScreen || mobileMedia) && (
              <div className="px-[2%] flex w-full grow-[0.5]">
                <div className="bg-[#D8D6D0] w-full flex justify-center flex-col">
                  <div className="text-center flex flex-col">
                    <div className="w-[80%] mx-auto pt-[3%]">
                      <Slider
                        step={1}
                        style={{ paddingBottom: "0px", width: "100%" }}
                        color="primary"
                        value={progress}
                        min={0}
                        max={100}
                      // onChange={handleChangeSeekBar}
                      />
                    </div>

                    <div className="flex justify-between w-[80%] mx-auto text-black">
                      <p className="text-xs">{musicPlayed}</p>
                      <p className="text-xs"> {duration}</p>
                    </div>
                  </div>
                  <div className="flex w-[96%] justify-between mx-auto items-center relative">
                    <div>
                      {isSave ? (
                        <div onClick={handleRemoveSave}>
                          <img
                            src={fillSave}
                            alt="fillSaveIcon"
                            className="h-[33.79px] w-[33.79px]"
                          />
                        </div>
                      ) : (
                        <div onClick={handleSave}>
                          <img
                            src={unFilledSave}
                            alt="unsaveIcon"
                            className="h-[33.79px] w-[33.79px]"
                          />
                        </div>
                      )}
                    </div>
                    <img
                      className="h-[41px] w-[41px]"
                      onClick={handleRewinds}
                      src={backward}
                      alt="backward"
                    />
                    {/* <div className="flex gap-2 items-end"> */}
                    <div className="relative">
                      <div className="flex items-center justify-center w-[75px] h-[75px] rounded-full bg-black flex-shrink-0">
                        {!playing && (
                          <img
                            src={playIcon}
                            alt="play icon"
                            onClick={handlePlay}
                            className="pl-[5px] w-[40px] h-[40px]"
                          />
                        )}
                        {playing && (
                          // <HiPause color="black" size={30} onClick={handlePause} />
                          <img
                            src={pauseIcon}
                            alt="pause icon"
                            onClick={handlePause}
                            className="w-[40px] h-[40px]"
                          />
                        )}
                      </div>
                      <div
                        className={`absolute right-[-22px] top-0 flex flex-col justify-center items-center ${isNumberPopUp && "pulse"
                          }`}
                      >
                        <div>
                          <p className="text-[1rem] relative">{playedTime}x</p>
                        </div>
                      </div>
                    </div>
                    <img
                      className="h-[41px] w-[41px]"
                      onClick={handleForwards}
                      src={forward}
                      alt="forward"
                    />
                    <div onClick={handleShare}>
                      <img
                        src={shareIcon}
                        alt="shareIcon"
                        className="w-[41px] h-[41px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mx-[2%]">
            <div
              className={`flex justify-center py-[1.5%] pb-[8px] rounded-b-[19px] bg-[${showDisableScreen || mobileMedia ? "#D8D6D0" : "#ffffff"
                }]`}
            >
              {screenWidth < 1025 &&
                (showDisableScreen || mobileMedia) &&
                (!isSharePage ? (
                  <MobileNavbar />
                ) : (
                  <Link
                    to="/signup"
                    className={`flex items-center justify-center self-center bg-[${showDisableScreen || mobileMedia ? "#D8D6D0" : "#ffffff"
                      }] w-[92%] pt-3 pb-[4%] rounded-b-[19px]`}
                  >
                    <p className="bg-black p-[12px] text-white font-bold text-[16px] rounded-full w-[92%] text-center">
                      Create a profile!
                    </p>
                  </Link>
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AudioHighlightWithPlayer;
