import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { redirect } from "react-router-dom";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import axios from "axios"
import { toast } from "react-toastify";

export const loginApiCall = createAsyncThunk("/loginApi", async (data: any) => {
    const res = await axios.post(apiEndPoints.signin, data);
    return res;
})

export const userProfile = createAsyncThunk("/userProfile", async (token: string | null) => {
    const res = await axios.get(apiEndPoints.getUserProfile, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return res;
})

interface AuthState {
    value: boolean,
    userDetails: any,
    userProfile: any,
    isLoading : any,
}

const initialState: AuthState = {
    value: false,
    userDetails: {},
    userProfile: {},
    isLoading : false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authorizeUser: (state) => {
            state.value = true;
        },
        clearAuthorization: (state) => {
            state.value = false;
            redirect("/");
            window.location.reload();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginApiCall.pending, (state) => {
                 state.isLoading = true
            })
            .addCase(loginApiCall.fulfilled, (state, action) => {
                if (action.payload.data.responseStatus === 1) {
                    if (action.payload.data.userDetails?.isActive) {
                        localStorage.setItem("accessToken", action.payload.data.userDetails.accessToken);
                        state.value = true
                        state.userDetails = action.payload.data.userDetails;
                        redirect("/")
                        toast.success("Successfully logged in!")
                    } else {
                        toast.error("User does not exist")
                    }
                }
                state.isLoading = false
            })
            .addCase(loginApiCall.rejected, (state, action) => {
                toast.error("Email or password incorrect");
                state.isLoading = false
            });


            // User profile

            builder.addCase(userProfile.fulfilled, (state, action) => {
                   state.userDetails = action.payload.data.user
            })
    },
})

export const authSelector = (state: RootState) => state.userAuth.value;
export const { authorizeUser, clearAuthorization } = authSlice.actions;
export const selectAuth = (state: RootState) => state.userAuth.value;
export default authSlice.reducer;
