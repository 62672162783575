import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import featureCheck from "../../assets/images/featureCheck.svg";
import policyImg from "../../assets/images/policy.svg";
import { features } from "../../utils/constant";
import companyLogo from "../../assets/images/dailysparq-logo.png";
import blackRectangle from "../../assets/images/black_rectangle.png";
import stars from "../../assets/images/stars.png";
import relaxedGuy from "../../assets/images/review.png";
import halfMoonLogo from "../../assets/images/halfMoonLogo.svg";
import { apiEndPoints } from "../../api/endPoints/endpoints";
import CustomLoaderComponent from "../../components/CustomLoaderComponent/CustomLoaderComponent";

const MySubscription = () => {
  const navigation = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isSubscriptionActive, setSubscriptionActive] = useState("false");

  const getSubscriptionDetails = async () => {
    const token = localStorage.getItem("accessToken");
    setLoading(true);
    const response = await fetch(apiEndPoints.getSubscriptionDetails, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      setLoading(false);
      const data = await response.json();
      setSubscriptionActive(data?.data?.status);
    }
  };

  const createCheckoutSession = async () => {
    const token = localStorage.getItem("accessToken");
    setLoading(true);
    const response = await fetch(apiEndPoints.createCheckoutSession, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      setLoading(false);
      const data = await response.json();
      window.location.replace(data?.data?.redirectURI);
    }
  };

  const createManageSubscriptionSession = async () => {
    const token = localStorage.getItem("accessToken");
    setLoading(true);
    const response = await fetch(apiEndPoints.manageSubscription, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      setLoading(false);
      const data = await response.json();
      window.location.replace(data?.data?.redirectURI);
    }
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  return (
    <div className="overflow-y-scroll h-screen">
      {isLoading && <CustomLoaderComponent />}
      <div className="bg-mobile-primary w-full p-5 pt-5 text-black">
        <div className="flex justify-between">
          <div
            className="lg:hidden"
            onClick={() => {
              navigation("/");
            }}
          >
            <IoIosArrowBack color="black" size={30} />
          </div>
          <h1 className="text-black text-xl text-center font-bold mb-3 w-full">
            Manage Subscription
          </h1>
        </div>
        <div className="mt-14 pb-8 flex flex-col gap-4 bg-white rounded-2xl shadow-md justify-start items-center pt-[2rem]">
          {!isSubscriptionActive && (
            <>
              <p className="font-bold text-3xl mx-auto w-full text-center leading-8">
                $4.97/month
              </p>
              <p className="font-semibold text-2xl mx-auto w-full text-center text-[#56B781] leading-[16px] mb-4">
                What's Included
              </p>
            </>
          )}
          {isSubscriptionActive && (
            <>
              <p className="text-green-700 bg-green-50 ring-green-200 ring-1 font-semibold rounded-full mr-8 py-1 text-sm ml-auto flex justify-end items-center px-8">
                Active
              </p>
              <p className="font-semibold text-xl mx-auto w-full text-center text-black leading-[16px] mb-4 block">
                Dailysparq Premium Account
              </p>
            </>
          )}
          {features.map((feature) => {
            return (
              <div
                className="mx-auto w-full pl-10 flex gap-2 justify-start items-center"
                key={feature}
              >
                <img
                  src={featureCheck}
                  className="w-[24px] h-[24px]"
                  alt="check"
                />
                <p className="text-sm">{feature}</p>
              </div>
            );
          })}
          {!isSubscriptionActive && (
            <div className="flex justify-center mt-0 w-[100%]">
              <button
                className="w-[80%] bg-[#56B781] h-[45px] rounded-full mt-[15px] mb-[15px] ml-[5px] mr-[5px] text-white"
                onClick={createCheckoutSession}
              >
                Start Subscription
              </button>
            </div>
          )}
          {isSubscriptionActive && (
            <div className="flex justify-center mt-0 w-[100%]">
              <button
                className="w-[80%] bg-[#56B781] h-[45px] rounded-full mt-[15px] mb-[15px] ml-[5px] mr-[5px] text-white"
                onClick={createManageSubscriptionSession}
              >
                View/Manage Subscription
              </button>
            </div>
          )}
        </div>
        <div
          className="mt-4 px-4 py-4 flex bg-white rounded-2xl shadow-md justify-between"
          onClick={() =>
            window.open(
              "https://go.dailysparq.com/cancellation-policy",
              "_blank"
            )
          }
        >
          <div className="flex gap-2">
            <img src={policyImg} className="w-[24px] h-[24px]" alt="policy" />
            <p className="text-md">Cancellation Policy</p>
          </div>
          <div className="lg:hidden" onClick={() => {}}>
            <IoIosArrowForward color="black" size={30} />
          </div>
        </div>
        <div
          className="mt-4 px-4 py-4 flex bg-white rounded-2xl shadow-md justify-between"
          onClick={() =>
            window.open(
              "https://go.dailysparq.com/cancellation-policy",
              "_blank"
            )
          }
        >
          <div className="flex gap-2">
            <img src={policyImg} className="w-[24px] h-[24px]" alt="policy" />
            <p className="text-md">Refund Policy</p>
          </div>
          <div className="lg:hidden" onClick={() => {}}>
            <IoIosArrowForward color="black" size={30} />
          </div>
        </div>
        {/* <div className="p-4 mt-4 mb-4 w-full flex flex-col gap-8 justify-center bg-white shadow-md rounded-2xl">
          {[0, 1, 2].map((i) => {
            return (
              <div
                key={i}
                className="w-full flex flex-col justify-center bg-white"
              >
                <div className="">
                  <div className="flex gap-2">
                    <div
                      className="w-[120px] h-[80px] text-white text-[45px] flex justify-center items-center"
                      style={{
                        backgroundImage: `url(${blackRectangle})`,
                        objectFit: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      MJ
                    </div>
                    <div className="w-full flex flex-col justify-between">
                      <img src={stars} alt="rating" className="w-[6rem]"></img>
                      <div className="w-full flex justify-start">
                        <div className="mr-4 text-xl">Michael Jones</div>
                        <div className="bg-black text-white px-1 pt-[2px] text-[0.825rem]">
                          Verified
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="mt-2">
                      Join thousands of satisfied users who rate us 5 stars. We
                      appreciate the love and difference we’re making in the
                      lives our of members!
                    </p>
                    <div className="flex gap-2">
                      <div className="ml-0.5 w-[0.625rem] bg-gray-300"></div>
                      <div>
                        <img
                          src={halfMoonLogo}
                          alt="logo-sm"
                          className="w-[60px]"
                        />
                        <p>Dailysparq Team:</p>
                        <p>
                          Join thousands of satisfied users who rate us 5 stars.
                          We appreciate the love and difference we’re making in
                          the lives our of members!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="text-xl text-center mt-4 mb-2 font-bold">
            Listen To Dailysparq In Bed
          </div>
          <img src={relaxedGuy} alt="relaxed-guy" />
        </div>
        {!isSubscriptionActive && (
          <div className="flex justify-center mt-0 w-[100%]">
            <button
              className="w-[80%] bg-[#56B781] h-[45px] rounded-full mt-[15px] mb-[15px] ml-[5px] mr-[5px] text-white"
              onClick={createCheckoutSession}
            >
              Start Subscription
            </button>
          </div>
        )} */}
      </div>
      <div className="mt-4 mb-4 w-full flex justify-center">
        <img
          src={companyLogo}
          alt="logo"
          style={{
            width: "200px",
            height: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default MySubscription;
