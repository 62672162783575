const URL = process.env.REACT_APP_API_ENDPOINT;

export const apiEndPoints = {
  signup: `${URL}signup`,
  signin: `${URL}signin`,
  resendOtpCode: `${URL}resendOtpCode`,
  forgotPassword: `${URL}forgotPassword`,
  updateForgotPassword: `${URL}updateForgotPassword`,
  SignOut: `${URL}SignOut`,
  getAllUsers: `${URL}getAllUsers`,
  verifyOtp: `${URL}verifyOtp`,
  getAllBoosts: `${URL}getAllBoosts`,
  addMindscribe: `${URL}addUserMindscribe`,
  getAllMindscribes: `${URL}getUserMindscribes`,
  getUserProfile: `${URL}getUserDetails`,
  updateUser: `${URL}UpdateUser`,
  updateUserPassword: `${URL}UpdateUserPassword`,
  deleteUser: `${URL}deleteUser`,
  getAllTags: `${URL}getAllTags`,
  updateUserTag: `${URL}updateUserTag`,
  saveUserTags: `${URL}saveUserTags`,
  getUserTags: `${URL}getUserTags`,
  addToggleTime: `${URL}addToggleTime`,
  saveBoost: `${URL}saveUserBoost`,
  removeBoost: `${URL}removeUserBoost`,
  getSaveBoostById: `${URL}getBoostById`,
  deleteMindScribeImage: `${URL}deleteMindScribImage`,
  getAllToggleTime: `${URL}getAllToggleTime`,
  getUpComingTime: `${URL}getToggleTime`,
  getNextDayToggleTime: `${URL}getToggleTimeNextDay`,
  addToggleTimeScore: `${URL}addToggleTimeScore`,
  getTodayToggleTimeScore: `${URL}getTodayToggleTimeScore`,
  addToggleTimeScoreOnPlaySong: `${URL}addToggleTimeScoreOnPlaySong`,
  editToggleTime: `${URL}editToggleTime`,
  getPreviosDayToggleTimeScore: `${URL}getPreviosDayToggleTimeScore`,
  getPlayedTimeBoost: `${URL}getPlayedTimeBoost`,
  getUserVisualInspiration: `${URL}getUserVisualInspiration`,
  addUserVisualInspiration: `${URL}addUserVisualInspiration`,
  deleteVisualInspiration: `${URL}deleteVisualInspiration`,
  getAllUserSavedBoost: `${URL}getAllUserSavedBoost`,
  addCountPlayBoost: `${URL}addCountPlayBoost`,
  currentStreak: `${URL}currentStreak`,
  getMontlyProgress: `${URL}getMontlyProgress`,
  isActiveToggleTime: `${URL}isActiveToggleTime`,
  deleteToggleTime: `${URL}deleteToggleTime`,
  addRandomPersonlized: `${URL}addRandomPersonlized`,
  getRandomPersonlized: `${URL}getRandomPersonlized`,
  checkEmail: `${URL}checkEmail`,
  getShareBoostById: `${URL}getShareBoostById`,
  getTrahedBoost: `${URL}getTrahedBoost`,
  getSubscriptionDetails: `${URL}payment/get-subscription-details`,
  manageSubscription: `${URL}payment/manage-subscription`,
  createCheckoutSession: `${URL}payment/create-checkout-session`,
};
