import { createBrowserRouter } from "react-router-dom";
import ProtectedRoot from "./ProctectedRoot";
import DynamicPlayer from "../pages/Home/DynamicPlayer";
import Settings from "../pages/Settings/Settings";
import Privacy from "../pages/Settings/Privacy";
import Mindscribe from "../pages/Mindscribe/Mindscribe";
import PersonalizedVisual from "../pages/Settings/PersonalizedVisual";
import AboutU from "../pages/Signup/AboutU";
import ToggleSetup from "../pages/Signup/ToggleSetup";
import ToggleTime from "../pages/Signup/ToggleTime";
import EditTimeToggle from "../pages/Signup/EditTimeToggle";
import Streaks from "../pages/Streaks/Streaks";
import AboutUs from "../pages/Settings/AboutUs";
import ShareFeature from "../components/Share/Share";
import Profile from "../pages/Settings/Profile";
import MotivationalTone from "../pages/MotivationalTone/MotivationalTone";
import SaveAudio from "../pages/SaveAudio/SaveAudio";
import MySubscription from "../pages/Settings/Subscription";

export const protectedRouter = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoot />,
    children: [
      { path: "", element: <DynamicPlayer /> },
      {
        path: "settingsWeb",
        element: <Settings />,
        children: [
          { path: "aboutU", element: <AboutU /> },
          { path: "", element: <Profile /> },
          { path: "toggleAlaramSet", element: <ToggleSetup /> },
          { path: "setToggleTime", element: <ToggleTime /> },
          { path: "editToggleTime", element: <EditTimeToggle /> },
          { path: "privacy", element: <Privacy /> },
          { path: "personalized", element: <PersonalizedVisual /> },
          { path: "aboutUs", element: <AboutUs /> },
          { path: "motivationalTone", element: <MotivationalTone /> },
        ],
      },
      { path: "mindscribe", element: <Mindscribe /> },
      { path: "*", element: <DynamicPlayer /> },
    ],
  },
  { path: "/streaks", element: <Streaks /> },
  { path: "/audioSave", element: <SaveAudio /> },
  { path: "/about", element: <AboutU /> },
  { path: "/toggleAlaramSet", element: <ToggleSetup /> },
  { path: "/setToggleTime", element: <ToggleTime /> },
  { path: "/editToggleTime", element: <EditTimeToggle /> },
  { path: "/settings/about", element: <AboutU /> },
  { path: "/settings/profile", element: <Profile /> },
  { path: "/settings/toggleAlaramSet", element: <ToggleSetup /> },
  { path: "/settings/setToggleTime", element: <ToggleTime /> },
  { path: "/settings/editToggleTime", element: <EditTimeToggle /> },
  { path: "/settings/privacy", element: <Privacy /> },
  { path: "/settings/motivationalTone", element: <MotivationalTone /> },
  { path: "/settings/personalized", element: <PersonalizedVisual /> },
  { path: "/settings/AboutUs", element: <AboutUs /> },
  { path: "/settings/subscription", element: <MySubscription /> },
  { path: "/share/:id", element: <ShareFeature /> },
]);
