import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import {
  addToggleTimeData,
  getAllToggleTime,
} from "../../redux/slices/toggleTimeSlice";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimeAndDatePicker from "./TimePicker";
import dayjs from "dayjs";
import signupSrc from "../../assets/images/signup.png";

const ToggleTime = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [timeError, setTimeError] = useState<any>(null);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [selectedTime, setselectedTime] = useState<any>(dayjs(new Date()));
  const [error, setError] = useState<any>({
    time: null,
    day: null,
  });

  const storedDays = useAppSelector(
    (state) => state?.toggleSetTime?.selectedDays
  );

  useEffect(() => {
    dispatch(getAllToggleTime());
  }, [dispatch]);

  const toggleDaySelection = (day: any) => {
    setError({ ...error, day: null });
    const temExist = selectedDays.includes(day);

    if (temExist) {
      const index = selectedDays.indexOf(day);
      selectedDays.splice(index, 1);
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleToggleTime = () => {
    if (!selectedTime) {
      setError({ ...error, time: "Time is required*" });
    } else if (selectedDays.length <= 0) {
      setError({ ...error, day: "Please select a day*" });
    } else if (!timeError) {
      // dispatch(toggleTimeSet({ selectedDays, selectedTime }))
      const payload = {
        toggleTime: [
          {
            days: selectedDays,
            time: selectedTime,
            isActive: true,
          },
        ],
      };
      dispatch(addToggleTimeData(payload));
      dispatch(getAllToggleTime());

      if (location.pathname === "/settings/setToggleTime") {
        navigation("/settings/toggleAlaramSet");
      } else if (location.pathname === "/settingsWeb/setToggleTime") {
        navigation("/settingsWeb/toggleAlaramSet");
      } else {
        navigation("/toggleAlaramSet");
      }
    }
  };

  return (
    <div className="flex items-stretch h-screen">
      {location.pathname === "/setToggleTime" && (
        <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
          <div className="flex flex-col sm:pr-10 md:pr-20 lg:pr-28 xl:pr-32">
            <div className="flex justify-end mb-10">
              <img
                className="sm:w-44 md:w-52 lg:w-64"
                src={signupSrc}
                alt="hand holding globe"
              />
            </div>
            <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl text-right text-white pr-2">
              Meditation for
            </p>
            <div className="flex justify-end mb-8">
              <p className="sm:text-4.5xl md:text-4.5xl lg:text-5xl xl:text-6.5xl sm:mt-6 xl:mt-12 md:mt-6 text-white sm:h-7 md:h-8 lg:h-8 xl:h-10 w-9/12 text-right relative bg-[#746C68]">
                <span className="absolute sm:-top-7 lg:-top-5 xl:-top-8 end-3">
                  Beginners
                </span>
              </p>
            </div>
            <p className="sm:text-2xl md:text-2xl lg:text-3xl xl:text-4.5xl text-white text-right font-light">
              Mindfulness practice for all
            </p>
          </div>
        </div>
      )}
      <div
        className="sm:hidden lg:block bg-mobile-primary w-full overflow-y-scroll"
        style={{
          height: "100vh",
        }}
      >
        {/* Header */}
        <div className="flex items-center w-full h-[69px] bg-[#000000] py-0 px-[6px] gap-[12px]">
          <div
            onClick={() => {
              if (location.pathname === "/settings/setToggleTime") {
                navigation("/settings/toggleAlaramSet");
              } else if (location.pathname === "/settingsWeb/setToggleTime") {
                navigation("/settingsWeb/toggleAlaramSet");
              } else {
                navigation("/toggleAlaramSet");
              }
            }}
          >
            <IoIosArrowBack color="white" size={30} />
          </div>
          <div>
            <p className="text-white  whitespace-nowrap lg:text-xl text-lg">
              Set your daily Target Toggle Times
            </p>
          </div>
        </div>

        {/* Time Selector */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "30px 20px",
            color: "black",
          }}
        >
          <div>
            <TimeAndDatePicker
              selectedTime={selectedTime}
              setSelectedTime={setselectedTime}
              error={timeError}
              setError={setTimeError}
            />
            {error.time && <p style={{ color: "red" }}>{error.time}</p>}
            {/* {timeError && <p style={{ color: "red" }}>{timeError}</p>} */}
          </div>
        </div>
        <div className="flex items-center justify-center px-4 pb-5">
          <button
            className="w-full h-[49px] rounded-[7px] bg-black p-[1%] block text-white text-lg"
            onClick={handleToggleTime}
          >
            Save
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "20px 20px",
            background: "#D9D9D9",
          }}
        >
          <div>
            <p className="text-black text-center text-xl">
              Set the daily repeat intervals of your target time
            </p>
          </div>
        </div>
        <div style={{ color: "black", padding: "20px 20px" }}>
          {error?.day && <p style={{ color: "red" }}>{error.day}</p>}
          {daysOfWeek?.map((day: any) => {
            const dayExists = storedDays.some((item: any) =>
              item.days.includes(day)
            );
            const temExist = selectedDays.includes(day);
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <p className="text-2xl">{day}</p>
                <button
                  className={`flex w-[55px] h-[26px] items-center ${
                    dayExists
                      ? "bg-[#848282]"
                      : temExist
                      ? "bg-[black]"
                      : "bg-[#d9d9d9]"
                  } rounded-full self-end transition-all duration-500 border-none`}
                  onClick={() => toggleDaySelection(day)}
                  disabled={dayExists ? true : false}
                >
                  <p
                    className={`h-[22px] w-[22px] ${
                      dayExists || temExist ? "bg-mobile-primary" : "bg-black"
                    } rounded-full transition-all duration-500 ${
                      dayExists || temExist ? "ml-[27px]" : "ml-1"
                    }`}
                  ></p>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ToggleTime;
